import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  addBookmark,
  deleteBookmark,
  getBookmarks,
  updateBookmark,
} from '@/gen/inventory/v1/company_service-CompanyInventoryService_connectquery'

export const useGetBookmarks = (companyId: string) => {
  const queryState = useQuery({
    ...getBookmarks.useQuery({ companyId }),
  })

  return {
    ...queryState,
    data: queryState.data?.bookmarks || [],
  }
}

export const useAddBookmark = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation(addBookmark.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getBookmarks.getQueryKey({ companyId }))
    },
  })
}

export const useUpdateBookmark = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation(updateBookmark.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getBookmarks.getQueryKey({ companyId }))
    },
  })
}

export const useDeleteBookmark = (companyId: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteBookmark.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getBookmarks.getQueryKey({ companyId }))
    },
  })
}
