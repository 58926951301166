import { VariantProps, cva } from 'class-variance-authority'

import { CRITICAL_EXPIRY_DAYS, WARNING_EXPIRY_DAYS } from '@/const/artifact-expiry'

import { MILLISECONDS_IN_A_DAY, formatDate } from '@/lib/date'

import { Tooltip } from '@/components/ui/tooltip'

const artifactExpirationDateVariants = cva(
  'flex items-center rounded font-semibold px-1 text-xs uppercase tracking-wide',
  {
    variants: {
      variant: {
        expired: 'bg-red-50 text-red-500',
        criticalExpiry: 'bg-orange-50 text-orange-400',
        warningExpiry: 'bg-yellow-50 text-yellow-500',
        inactive: 'bg-gray-50 text-gray-400',
      },
    },
  },
)

type ArtifactExpirationDateProps = {
  expirationDate: Date
} & VariantProps<typeof artifactExpirationDateVariants>

export const ArtifactExpirationDate = ({
  expirationDate,
  variant,
}: ArtifactExpirationDateProps) => {
  const currentDate = new Date()

  if (expirationDate > new Date(currentDate.setDate(currentDate.getDate() + WARNING_EXPIRY_DAYS))) {
    return null
  }

  const isExpired = expirationDate < new Date()
  const daysToExpire = Math.floor(
    (expirationDate.getTime() - new Date().getTime()) / MILLISECONDS_IN_A_DAY,
  )
  const label = isExpired ? 'Expired' : 'Expires'

  return (
    <Tooltip
      trigger={
        <div
          className={artifactExpirationDateVariants({
            variant:
              variant ||
              (isExpired
                ? 'expired'
                : daysToExpire < CRITICAL_EXPIRY_DAYS
                  ? 'criticalExpiry'
                  : 'warningExpiry'),
          })}
        >
          <span className='mr-1'>{label}</span>
          <br />
          <span>{formatDate(expirationDate)}</span>
        </div>
      }
    >
      <div className='px-4 py-1 text-center'>
        <span>This artifact {label} on</span>
        <br />
        <span>
          {formatDate(expirationDate)} {!isExpired && `(${daysToExpire} days)`}
        </span>
      </div>
    </Tooltip>
  )
}
