import { MenuItem } from '@/components/select/type'
import { SelectItem, SelectLabel } from '@/components/ui/select'

type ItemProps<T extends number | string> = {
  item: MenuItem<T>
}

export const Item = <T extends number | string>({ item }: ItemProps<T>) => {
  switch (item.type) {
    case 'menuSelectItem':
      return (
        <SelectItem
          className='whitespace-nowrap'
          isSuggested={item.isSuggested}
          value={item.value.toString()}
          description={item.description}
          disabled={item.disabled}
          isPotentialSelection={item.isPotentialSelection}
        >
          {item.label}
        </SelectItem>
      )
    case 'menuLabel':
      return <SelectLabel>{item.label}</SelectLabel>
    default:
      return null
  }
}
