import { useGetUserById } from '@/api/get-user-by-id.hook'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Tooltip } from '@/components/ui/tooltip'

type UserAvatarProps = {
  userId: string
  showEmail?: boolean
}

export const UserAvatar = ({ userId, showEmail }: UserAvatarProps) => {
  const { data } = useGetUserById(userId)
  const userInitials = data?.name
    ?.split(' ')
    .map((n) => n[0])
    .join('')
  return (
    <div className='flex items-center gap-2'>
      <Avatar className='size-6 bg-gray-200 text-xs'>
        <AvatarFallback>{<Tooltip trigger={userInitials}>{data?.name}</Tooltip>}</AvatarFallback>
      </Avatar>
      {showEmail && data?.email}
    </div>
  )
}
