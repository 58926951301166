import { ReactNode } from 'react'

import { UrlParam } from '@/lib/url-param.hook'

export type TabItem = {
  labelIcon?: ReactNode
  component?: ReactNode
  itemDisabled?: boolean
  indented?: boolean
} & (
  | {
      label: string
      index?: string // optional if label is string
    }
  | {
      label: Exclude<ReactNode, string>
      index: string // required if label is not string
    }
)

export type TabsProps<CustomTab = null> = {
  labelAsTitle?: boolean
  titleSuffix?: ReactNode
  urlParamName?: UrlParam
  items: CustomTab extends null ? TabItem[] : (TabItem | CustomTab)[]
} & React.HTMLAttributes<HTMLDivElement>

export const getTabItemIndex = (item: TabItem): string =>
  item.index || (typeof item.label === 'string' ? item.label : String(item.label))
