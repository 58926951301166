import { Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { useReportErrorsCallback } from '@/lib/error-reporting'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'
import { useCalculateInherentRisk } from '@/lib/use-calculate-inherent-risk'

import { AssessmentFlowStep } from '@/pages/assessment/assessment-flow-step-enum'
import { useSubmitIRQ } from '@/pages/assessment/irq/use-submit-irq'

import { CompanyRiskBadge, SelectCompanyRisk } from '@/components/badge/company-risk'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

export const useSelectInherentRiskModal = (companyId: string) => {
  const { updateParam } = useUrlParams()
  const [comment, setComment] = useState('')
  const suggestedRiskLevel = useCalculateInherentRisk(companyId)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(suggestedRiskLevel)
  const reportErrors = useReportErrorsCallback()
  const { submitIRQ, isLoading } = useSubmitIRQ(companyId)

  useEffect(() => setSelectedRiskLevel(suggestedRiskLevel), [suggestedRiskLevel, isOpen])

  const showModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const handleSubmitIRQ = async (newRiskLevel: RiskLevel, comment: string) => {
    try {
      await submitIRQ(newRiskLevel, suggestedRiskLevel, comment)
      updateParam(UrlParam.STEP, AssessmentFlowStep.EVIDENCE)
      closeModal()
    } catch (error) {
      reportErrors(error as Error)
    }
  }

  const renderModal = () => (
    <Modal
      width={400}
      open={isOpen}
      onCancel={closeModal}
      footer={
        <div className='flex items-end justify-between'>
          <Button variant={'ghost'} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={selectedRiskLevel === RiskLevel.UNSPECIFIED || isLoading}
            onClick={() => handleSubmitIRQ(selectedRiskLevel, comment)}
          >
            {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
            Save & Close
          </Button>
        </div>
      }
    >
      <h3 className='font-semibold'>Assign an Inherent Risk</h3>
      <div className='mt-7 text-center'>Lema suggests this third-party’s inherent risk is</div>
      <div className='mb-8 mt-4 flex justify-center'>
        <CompanyRiskBadge
          className='gap-2 px-7 py-0.5 text-xl'
          variant='highlighted'
          riskLevel={suggestedRiskLevel}
        />
      </div>
      <Separator />
      <h4 className='my-5 font-bold'>Assign an inherent risk to this third-party:</h4>
      <SelectCompanyRisk
        comment={comment}
        setComment={setComment}
        setSelectedRiskLevel={setSelectedRiskLevel}
        selectedRiskLevel={selectedRiskLevel}
      />
    </Modal>
  )

  return { showModal, renderModal }
}
