import { Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { ReactNode, useState } from 'react'

import { useGetCompany, useUpdateCompany } from '@/api/company.hook'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { companyStatusLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { useReportErrorsCallback } from '@/lib/error-reporting'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { ExportCompany } from '@/pages/company-drawer/export-company'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

type ChangeAssessmentStatusModalProps = {
  companyId: string
  status: CompanyStatus
  title: string
  placeholder: string
  confirmButtonText: string
  showExport?: boolean
  description?: ReactNode
}

export const useChangeAssessmentStatusModal = ({
  companyId,
  status,
  title,
  placeholder,
  confirmButtonText,
  description,
  showExport = false,
}: ChangeAssessmentStatusModalProps) => {
  const { removeParam } = useUrlParams()
  const { data: company } = useGetCompany(companyId)
  const reportErrors = useReportErrorsCallback()
  const { mutateAsync, isLoading } = useUpdateCompany(companyId)
  const trackStatusUpdate = useTrackCallback('third-party.status.update')

  const [isOpen, setIsOpen] = useState(false)
  const [comment, setComment] = useState('')

  const showModal = (comment?: string) => {
    setComment(comment || '')
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleAssessment = async () => {
    try {
      trackStatusUpdate({
        companyId,
        status: companyStatusLabel[status],
        previousStatus: companyStatusLabel[company?.company?.status ?? CompanyStatus.UNSPECIFIED],
        comment,
        company: company?.company?.profile?.name,
      })
      await mutateAsync({
        id: companyId,
        comment: comment || undefined,
        status: status,
      })
      removeParam(UrlParam.STEP)
      closeModal()
    } catch (error) {
      reportErrors(error as Error)
    }
  }

  const renderModal = () => (
    <Modal
      width={600}
      open={isOpen}
      onCancel={closeModal}
      footer={
        <div className='mt-6 flex items-end justify-between'>
          <Button variant='ghost' onClick={closeModal}>
            Cancel
          </Button>
          <div className='flex items-center gap-2'>
            {showExport && <ExportCompany className='py-0' companyId={companyId} />}
            <Button onClick={handleAssessment} disabled={isLoading}>
              {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
              {confirmButtonText}
            </Button>
          </div>
        </div>
      }
    >
      <h3 className='font-semibold'>{title}</h3>
      {description}
      <div className='mt-4 font-semibold'>Comment (Optional)</div>
      <p className='mb-2'>
        This third-party’s status will change to ‘{companyStatusLabel[status]}’. If you add a
        comment below, it will appear in the activity log.
      </p>
      <Input
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        placeholder={placeholder}
      />
    </Modal>
  )

  return { showModal, renderModal }
}
