import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { AssessmentFlowStep } from '@/pages/assessment/assessment-flow-step-enum'
import { useIsIrqCompleted } from '@/pages/assessment/irq/use-is-irq-completed'
import { useHasManualUploadedArtifact } from '@/pages/assessment/use-has-manual-uploaded-artifact'
import { useIsAllControlValidated } from '@/pages/assessment/use-is-all-control-validated'
import { useIsAllFindingsWithoutControlsClosed } from '@/pages/assessment/use-is-all-findings-without-controls-closed'

export type AssessmentStep = 'start' | AssessmentFlowStep | 'end'

export const useUpcomingStep = (
  companyId: string,
  companyStatus?: CompanyStatus,
): AssessmentStep | null => {
  const isIrqCompleted = useIsIrqCompleted(companyId)
  const isAllFindingsWithoutControlsClosed = useIsAllFindingsWithoutControlsClosed(companyId)
  const isAllControlsValidated = useIsAllControlValidated(companyId)
  const { hasManualUploadedArtifact } = useHasManualUploadedArtifact(companyId)

  if (
    companyStatus === CompanyStatus.ASSESSMENT_REQUIRED ||
    companyStatus === CompanyStatus.RE_ASSESSMENT_REQUIRED
  ) {
    return 'start'
  }

  if (companyStatus !== CompanyStatus.IN_ASSESSMENT) {
    return null
  }

  if (!isIrqCompleted) {
    return AssessmentFlowStep.IRQ
  }
  if (!hasManualUploadedArtifact) {
    return AssessmentFlowStep.EVIDENCE
  }
  if (!isAllControlsValidated || !isAllFindingsWithoutControlsClosed) {
    return AssessmentFlowStep.CONTROLS
  }

  return 'end'
}
