import { useAddBookmark } from '@/api/bookmark.hook'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { BookmarkModal } from '@/components/bookmark/bookmark-modal.hook'
import { useToast } from '@/components/ui/use-toast'

interface AddBookmarkModalProps {
  companyId: string
  visible: boolean
  onClose: () => void
}

interface BookmarkFormValues {
  url: string
  name: string
}

export const AddBookmarkModal = ({ companyId, visible, onClose }: AddBookmarkModalProps) => {
  const addBookmarkMutation = useAddBookmark(companyId)
  const { toast } = useToast()
  const reportErrors = useReportErrorsCallback()

  const handleSubmit = async (values: BookmarkFormValues) => {
    const url = values.url.trim()
    const name = values.name.trim()

    const bookmarkData = {
      name,
      url,
      companyId,
    }

    try {
      await addBookmarkMutation.mutateAsync(bookmarkData)

      toast({
        status: 'success',
        title: 'Bookmark added successfully',
      })
      onClose()
    } catch (error) {
      reportErrors(error as Error)
      toast({
        status: 'error',
        title: 'Failed to add bookmark',
      })
    }
  }

  const initialValues = {
    name: '',
    url: '',
  }

  return (
    <BookmarkModal
      visible={visible}
      onCancel={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      title='Create Bookmark'
      submitButtonText='Create'
    />
  )
}
