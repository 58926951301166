import { Select } from 'antd'
import { SparklesIcon, TriangleAlertIcon, XIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'

import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import {
  IRQChangeType,
  InherentRiskCategoryEnum,
  InherentRiskItem,
  SetIRQItemRequest,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelToColor } from '@/lib/color'

import { Suggested } from '@/components/badge/suggested'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

type IrqCategorySelectProps = {
  categoryEnum: InherentRiskCategoryEnum
  options: InherentRiskItem[]
  selectedOptions?: InherentRiskItem[]
  suggestedOptions?: InherentRiskItem[]
  multipleSelect?: boolean
  companyId: string
}

export const IrqCategorySelect = ({
  options,
  categoryEnum,
  selectedOptions,
  suggestedOptions,
  companyId,
  multipleSelect = false,
}: IrqCategorySelectProps) => {
  const { mutateAsync } = useOptimisticSetIRQItem(companyId)

  const updateIRQ = useCallback(
    (request: Partial<SetIRQItemRequest>) => {
      mutateAsync({ ...request, companyId })
    },
    [mutateAsync, companyId],
  )

  const suggestedAndNotSelectedOptions = useMemo(
    () =>
      suggestedOptions?.filter(
        (suggested) => !selectedOptions?.some((selected) => selected.id === suggested.id),
      ),
    [suggestedOptions, selectedOptions],
  )

  const itemMap: Record<string, InherentRiskItem> = useMemo(
    () => Object.fromEntries(options.map((item) => [item.id, item])),
    [options],
  )

  return (
    <div className='max-w-xl'>
      <Select
        filterOption={(input, option) => {
          if (!option) return false
          return option.displayName.toLowerCase().includes(input.toLowerCase())
        }}
        size='large'
        fieldNames={{ label: 'displayName', value: 'id' }}
        onClear={() =>
          selectedOptions?.forEach(({ id }) =>
            updateIRQ({
              category: categoryEnum,
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
              riskCategoryId: id,
            }),
          )
        }
        optionRender={({ value }) => {
          if (!value || !itemMap[value]) return <></>

          const isSuggested = suggestedOptions?.some((suggested) => suggested.id === value)

          return (
            <div className='flex items-center gap-4'>
              <SeverityToDot severity={itemMap[value].severity} />
              {itemMap[value].displayName}
              {isSuggested && <Suggested />}
            </div>
          )
        }}
        mode={multipleSelect ? 'multiple' : undefined}
        allowClear
        className='w-full'
        placeholder={
          <div className='w-0 overflow-visible'>
            {multipleSelect ? 'Select one or more options' : 'Select one option'}
          </div>
        }
        options={options}
        onDeselect={(riskCategoryId) =>
          updateIRQ({
            category: categoryEnum,
            changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
            riskCategoryId,
          })
        }
        onSelect={(riskCategoryId) => {
          if (!multipleSelect) {
            const isSelected = selectedOptions?.some(({ id }) => id === riskCategoryId)

            if (isSelected) {
              // If already selected, just remove it
              // Antd doesn't support onDeselect on single select
              updateIRQ({
                category: categoryEnum,
                changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                riskCategoryId,
              })
            } else {
              const updates = [
                // Remove existing selections
                ...(selectedOptions?.map(({ id }) => ({
                  category: categoryEnum,
                  changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                  riskCategoryId: id,
                })) || []),
                // Add new selection
                {
                  category: categoryEnum,
                  changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
                  riskCategoryId,
                },
              ]

              updates.forEach((update) => updateIRQ(update))
            }
          } else {
            updateIRQ({
              category: categoryEnum,
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
              riskCategoryId,
            })
          }
        }}
        value={selectedOptions?.map((option) => option.id)}
        tagRender={({ value }) => {
          if (!value || !itemMap[value]) return <></>
          return (
            <InherentRiskItemTag
              category={categoryEnum}
              companyId={companyId}
              item={itemMap[value]}
            />
          )
        }}
      />
      {suggestedAndNotSelectedOptions && suggestedAndNotSelectedOptions.length > 0 && (
        <div className='my-1 rounded border border-yellow-100 p-2'>
          <span className='my-1 flex items-center gap-0.5 text-sm'>
            <TriangleAlertIcon size={12} className='mr-0.5 text-yellow-500' /> Suggested{' '}
            <SparklesIcon size={12} className='mr-0.5' /> items not selected:
          </span>
          {suggestedAndNotSelectedOptions.map((suggested) => (
            <div
              onClick={() => {
                if (!multipleSelect) {
                  selectedOptions?.forEach(({ id }) =>
                    updateIRQ({
                      category: categoryEnum,
                      changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                      riskCategoryId: id,
                    }),
                  )
                }
                updateIRQ({
                  category: categoryEnum,
                  changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
                  riskCategoryId: suggested.id,
                })
              }}
              className='flex cursor-pointer items-center'
              key={suggested.id}
            >
              <InherentRiskItemTag
                hideRemove
                category={categoryEnum}
                companyId={companyId}
                item={suggested}
              />
              <Button
                size='sm'
                className='px-0.5 text-sm font-light text-gray-400 hover:text-gray-300 hover:no-underline'
                variant={'link'}
              >
                <span className='text-nowrap'>Click to add</span>
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type InherentRiskItemTagProps = {
  item: InherentRiskItem
  companyId: string
  category?: InherentRiskCategoryEnum
  hideRemove?: boolean
}

export const InherentRiskItemTag = ({
  item,
  companyId,
  category,
  hideRemove,
}: InherentRiskItemTagProps) => {
  const { mutateAsync } = useOptimisticSetIRQItem(companyId)

  const updateIRQ = useCallback(
    (request: Partial<SetIRQItemRequest>) => {
      mutateAsync({ ...request, companyId })
    },
    [mutateAsync, companyId],
  )

  return (
    <TextWithIcon
      className='m-1 h-6 rounded-full border px-2 text-md duration-150 ease-in-out hover:bg-gray-50'
      iconPosition='end'
      icon={
        !hideRemove && (
          <XIcon
            size={16}
            className='cursor-pointer rounded p-0.5 duration-200 ease-in-out hover:bg-gray-200'
            onClick={() =>
              updateIRQ({
                category,
                changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                riskCategoryId: item.id,
              })
            }
          />
        )
      }
      text={
        <div className='flex items-center gap-2'>
          <SeverityToDot severity={item.severity} />
          {item.displayName}
        </div>
      }
    />
  )
}

type SeverityToDotProps = {
  severity: RiskLevel
}

const SeverityToDot = ({ severity }: SeverityToDotProps) => (
  <div
    className='inline-block size-1.5 rounded-full'
    style={{
      backgroundColor: riskLevelToColor[severity],
    }}
  />
)
