import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'

import { Solution } from '@/gen/inventory/v1/company_service_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { Select } from '@/components/select/select'
import { MenuItem } from '@/components/select/type'
import { TextWithIcon } from '@/components/text-with-icon'

type SolutionSelectProps = {
  solutions: PlainMessage<Solution>[]
  selectedSolution?: string
  setSelectedSolution: (solution?: string) => void
}

export const SolutionSelect = ({
  solutions,
  selectedSolution,
  setSelectedSolution,
}: SolutionSelectProps) => {
  const { isEnabled: tpcompanySolutionSelectEnabled } = useFeatureFlagEnabled(
    'tpcompany-solution-select',
  )

  const trackViewSolution = useTrackCallback('third-party.solution.view')

  if (!tpcompanySolutionSelectEnabled) {
    return null
  }

  const solutionsBySourceIterable = _.values(_.groupBy(solutions, 'source.name'))
  const allSolutionsItem = `All Solutions (${solutions.length})`
  return (
    <Select
      onValueChange={(value) => {
        setSelectedSolution(value === allSolutionsItem ? undefined : value)
        if (value !== allSolutionsItem) {
          const solution = solutions.find(({ id }) => id === value)
          trackViewSolution({ solutionName: solution?.name, solutionId: solution?.id })
        }
      }}
      value={selectedSolution || allSolutionsItem}
      title='Filter this Page by a Solution'
      menuItems={[
        mapSolutionMenuItem({
          name: allSolutionsItem,
          id: allSolutionsItem,
        }),
        ...solutionsBySourceIterable.flatMap((solutions): MenuItem<string>[] => [
          {
            type: 'menuLabel',
            label: (
              <div className='flex flex-nowrap'>
                Source:
                <TextWithIcon
                  className='font-normal'
                  text={solutions[0].source?.name}
                  icon={
                    <img
                      className='size-4'
                      src={solutions[0].source?.imgUrl}
                      alt={solutions[0].source?.name}
                    />
                  }
                />
              </div>
            ),
          },
          ...solutions.map(mapSolutionMenuItem),
        ]),
      ]}
    />
  )
}

const mapSolutionMenuItem = ({ name, id }: Pick<Solution, 'name' | 'id'>) => ({
  type: 'menuSelectItem' as const,
  label: <div className='w-56 truncate text-left'>{name}</div>,
  value: id,
})
