import { Drawer } from 'antd'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { CompanyDrawer } from '@/pages/company-drawer/company-drawer'
import { LARGE_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_LOW } from '@/pages/drawers/z-index'

export const ThirdPartyDrawer = () => {
  const {
    urlParams: { thirdPartyId },
    replaceParams,
  } = useUrlParams()
  return (
    <Drawer
      zIndex={Z_INDEX_LOW}
      width={LARGE_DRAWER_WIDTH}
      closeIcon={false}
      onClose={() =>
        replaceParams({
          remove: [
            UrlParam.THIRD_PARTY_ID,
            UrlParam.STEP,
            UrlParam.COMPANY_DRAWER_TAB,
            UrlParam.CONTROL_VIEW,
          ],
        })
      }
      open={!!thirdPartyId}
    >
      {thirdPartyId && <CompanyDrawer companyId={thirdPartyId} />}
    </Drawer>
  )
}
