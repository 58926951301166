import type { ColumnType, TableViews } from './table.type'
import { Table as AntDTable, TableProps as AntDTableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { useContext, useEffect, useMemo } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterBar } from '@/components/table/filter/bar'
import { CheckboxFilterContext } from '@/components/table/filter/checkbox-filter-hook'
import { FilterProvider, FilterProviderContext } from '@/components/table/filter/context'
import { sortIcon } from '@/components/table/sort-icon'
import { generateAntColumn, generateFilter } from '@/components/table/table-generator'
import { ViewBar } from '@/components/table/view/bar'
import { useTableViews } from '@/components/table/view/use-table-views.hook'

import './antd-table.less'

export type TableProps<T> = {
  columns: ColumnType<T>[]
  showFilterBar?: boolean
  showBorder?: boolean
  stickyFilterBar?: boolean
  cellAlignTextTop?: boolean
  narrowFilterBar?: true
  checkboxFilterTitle?: string
  tableViews?: TableViews<T>
  tableName?: string
} & Omit<AntDTableProps<T>, 'columns'> &
  CheckboxFilterContext

const InternalTable = <T extends AnyObject>({
  dataSource = [],
  columns,
  narrowFilterBar,
  showFilterBar = true,
  showBorder = true,
  stickyFilterBar = false,
  cellAlignTextTop = false,
  pagination = false,
  checkboxFilterState,
  checkboxFilterTitle,
  tableViews,
  ...props
}: TableProps<T>) => {
  const { filteredState, searchState, setSelected, clearFilters } =
    useContext(FilterProviderContext)

  const hasSearch = useMemo(() => columns.some((col) => col.search), [columns])

  const hasCheckboxFilter = checkboxFilterState !== undefined

  const antColumns = useMemo(
    () => columns.map((col) => generateAntColumn(col, filteredState, searchState, sortIcon)),
    [columns, filteredState, searchState],
  )

  const filters = useMemo(
    () =>
      columns
        .filter((col) => col.filter) // only include columns that have filter enabled
        .map((col) => generateFilter(col, dataSource)),
    [columns, dataSource],
  )

  const dataSourceWithKey = useMemo(
    () =>
      dataSource.map((row, index) => ({
        key: row.id ? `${row.id}` : `${index}`,
        ...row,
      })),
    [dataSource],
  )

  useEffect(() => {
    filters.forEach((filter) => {
      if (filter.defaultSelected) {
        filter.defaultSelected.forEach((value) => {
          setSelected(filter.key, value, true, filter.optionRender)
        })
      }
    })
    return clearFilters
  }, [])

  const { viewFilterFunc, views } = useTableViews(tableViews, dataSourceWithKey)

  return (
    <div className='block'>
      <div className='mb-4'>
        {tableViews && views && (
          <ViewBar selectedViewUrlKey={tableViews.selectedViewUrlKey} views={views} />
        )}
      </div>

      {showFilterBar && (
        <div className={cn('mb-2', { 'sticky top-0 z-50': stickyFilterBar })}>
          <FilterBar
            narrow={narrowFilterBar}
            showSearch={hasSearch}
            filters={filters}
            // Currently we support only one checkbox filter
            checkboxFilterLabel={checkboxFilterTitle}
            showCheckboxFilter={hasCheckboxFilter}
          />
        </div>
      )}

      <div
        className={cn(cellAlignTextTop && 'cells-text-top', 'flex w-full flex-col gap-2', {
          'on-row-active': !!props.onRow || !!props.expandable?.expandRowByClick,
        })}
      >
        <AntDTable
          {...props}
          dataSource={
            !viewFilterFunc ? dataSourceWithKey : dataSourceWithKey.filter(viewFilterFunc)
          }
          columns={antColumns}
          pagination={pagination}
          className={cn(showBorder && 'rounded-sm border border-solid border-gray-200')}
        />
      </div>
    </div>
  )
}

export const Table = <T extends AnyObject>(props: TableProps<T>) => (
  <FilterProvider
    checkboxFilterState={props.checkboxFilterState}
    setCheckboxFilterState={props.setCheckboxFilterState}
  >
    <InternalTable {...props} />
  </FilterProvider>
)
