import { scaleLinear } from 'd3-scale'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { colors } from '@/const/colors'

import { QuestionStatusString } from '@/lib/liveblocks.config'

const { purple, red, orange, yellow, gray } = colors

export const purpleScale = scaleLinear(
  [0 / 3, 1 / 3, 2 / 3, 3 / 3],
  [purple[200], purple[400], purple[600], purple[800]],
)

export const riskLevelToColor: Record<RiskLevel, string> = {
  [RiskLevel.CRITICAL]: red[500],
  [RiskLevel.HIGH]: orange[400],
  [RiskLevel.MEDIUM]: yellow[300],
  [RiskLevel.LOW]: gray[300],
  [RiskLevel.UNSPECIFIED]: '',
}

export const riskLevelToBgClassName: Record<RiskLevel, string> = {
  [RiskLevel.CRITICAL]: 'bg-red-50',
  [RiskLevel.HIGH]: 'bg-orange-50',
  [RiskLevel.MEDIUM]: 'bg-yellow-50',
  [RiskLevel.LOW]: 'bg-gray-50',
  [RiskLevel.UNSPECIFIED]: '',
}

export const riskLevelClassNameColor: Record<RiskLevel, string> = {
  [RiskLevel.CRITICAL]: 'text-red-500 border-red-200',
  [RiskLevel.HIGH]: 'text-orange-400 border-orange-200',
  [RiskLevel.MEDIUM]: 'text-yellow-300 border-yellow-200',
  [RiskLevel.LOW]: 'text-gray-400 border-gray-200',
  [RiskLevel.UNSPECIFIED]: '',
}

export const questionStatusToClassNameColor: Record<QuestionStatusString, string> = {
  accepted: 'bg-green-500',
  needsReview: 'bg-gray-700',
  rejected: 'bg-red-500',
  postponed: 'bg-yellow-500',
  unanswered: 'bg-gray-400',
}
