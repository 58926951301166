import { PlainMessage } from '@bufbuild/protobuf'
import { LucideIcon, PlugIcon, WandIcon } from 'lucide-react'

import { InherentRiskItem, InherentRiskSource } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { inherentRiskSourceLabel } from '@/const/label'

import { riskLevelToColor } from '@/lib/color'

import { RiskLevelIcon } from '@/components/icons/risk-severity'

type InherentRiskItemCellProps = {
  inherentRiskItem?: PlainMessage<InherentRiskItem>
}

const sourceToIcon: Record<InherentRiskSource, LucideIcon | null> = {
  [InherentRiskSource.INTEGRATION]: PlugIcon,
  [InherentRiskSource.MICROSOFT_ENTRA_ID]: PlugIcon,
  [InherentRiskSource.GOOGLE_WORKSPACE]: PlugIcon,
  [InherentRiskSource.ZIP]: PlugIcon,
  [InherentRiskSource.WIZ]: PlugIcon,
  [InherentRiskSource.WEBHOOK]: PlugIcon,
  [InherentRiskSource.NETSKOPE]: PlugIcon,
  [InherentRiskSource.OKTA]: PlugIcon,
  [InherentRiskSource.PROJECTED]: WandIcon,
  [InherentRiskSource.IRQ]: null,
  [InherentRiskSource.UNSPECIFIED]: null,
}

export const InherentRiskItemCell = ({ inherentRiskItem }: InherentRiskItemCellProps) => {
  const riskColor = riskLevelToColor[inherentRiskItem?.severity || RiskLevel.UNSPECIFIED]
  const source = inherentRiskItem?.source || InherentRiskSource.UNSPECIFIED
  const SourceIcon = sourceToIcon[inherentRiskItem?.source || InherentRiskSource.UNSPECIFIED]
  const severity = inherentRiskItem?.severity || RiskLevel.UNSPECIFIED
  return (
    inherentRiskItem && (
      <div
        style={{ borderColor: riskColor }}
        className='my-2 flex h-12 w-full max-w-44 items-center rounded border text-center font-medium xl:min-w-28'
      >
        <div
          style={{ backgroundColor: riskColor }}
          className='flex h-full min-w-5.5 items-center justify-center text-white'
        >
          <RiskLevelIcon variant='inherit' level={severity} />
        </div>
        {source !== InherentRiskSource.UNSPECIFIED && source !== InherentRiskSource.IRQ && (
          <div
            style={{ color: riskColor }}
            className='absolute -mt-12 ml-7 inline-block p-0.5 text-xs'
          >
            <div style={{ color: riskColor }} className='flex h-4 items-center gap-1.5 bg-white'>
              {SourceIcon && <SourceIcon className='inline-block h-4 w-3' />}
              <span>{inherentRiskSourceLabel[source]}</span>
            </div>
          </div>
        )}
        <div className='mx-auto min-w-32 truncate px-1 capitalize'>
          {inherentRiskItem.displayName}
        </div>
      </div>
    )
  )
}
