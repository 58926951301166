import { PropsWithChildren } from 'react'

import { formatDate } from '@/lib/date'

type DateHeaderProps = {
  date: Date
} & PropsWithChildren

export const DateHeader = ({ date, children }: DateHeaderProps) => {
  return (
    <div>
      <div className='mb-2 text-gray-400'>{formatDate(date, 'longDate')}</div>
      {children}
    </div>
  )
}
