import { useMemo } from 'react'

import { useListControls } from '@/api/control'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'

export const useIsAllControlValidated = (companyId: string) => {
  const { data: controlsData } = useListControls(companyId)
  return useMemo(() => {
    if (!controlsData) return false
    return controlsData.every(
      ({ status, isEnabled }) => !(isEnabled && status !== ControlStatus.VALIDATED),
    )
  }, [controlsData])
}
