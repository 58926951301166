import { useEffect } from 'react'

import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

type FilterBarProps = {
  views: {
    count: number
    name: string
  }[]
  selectedViewUrlKey: UrlParam
  onViewChange?: (view: string) => void
}

export const ViewBar = ({ views, selectedViewUrlKey, onViewChange }: FilterBarProps) => {
  const { urlParams, updateParam } = useUrlParams()

  const [firstView, ...remainingViews] = views

  useEffect(() => {
    if (!urlParams[selectedViewUrlKey]) {
      updateParam(selectedViewUrlKey, firstView.name)
    }
  }, [urlParams, selectedViewUrlKey, updateParam, firstView])

  const selectedView = urlParams[selectedViewUrlKey]

  const handleViewChange = (view: string) => {
    updateParam(selectedViewUrlKey, view)
    onViewChange?.(view)
  }

  return (
    <div className='flex items-center gap-2'>
      <div className='rounded bg-gray-50 px-6 py-3 font-bold tracking-wide text-gray-400'>VIEW</div>

      <div className='cursor-pointer rounded border'>
        <ViewTab
          isSelected={selectedView === firstView.name}
          total={firstView.count}
          view='All'
          onClick={() => handleViewChange(firstView.name)}
        />
      </div>
      <div className='flex rounded border'>
        {remainingViews.map(({ name, count }) => (
          <ViewTab
            key={name}
            view={name}
            total={count}
            isSelected={selectedView === name}
            onClick={() => handleViewChange(name)}
          />
        ))}
      </div>
    </div>
  )
}

type ViewTabProps = {
  view: string
  total: number
  isSelected: boolean
  onClick: () => void
}

const ViewTab = ({ onClick, isSelected, total, view }: ViewTabProps) => (
  <div
    key={view}
    className={cn(
      'flex cursor-pointer items-center gap-3 text-nowrap border-r px-6 py-3 transition-all first:rounded-l last:rounded-r last:border-r-0 hover:bg-gray-100',
      {
        'bg-gray-700 text-white hover:bg-gray-700': isSelected,
      },
    )}
    onClick={onClick}
  >
    <span>{view}</span>
    <span
      className={cn('text-xs font-extrabold tracking-wide text-gray-400', {
        'text-white': isSelected,
      })}
    >
      {total}
    </span>
  </div>
)
