import { PlainMessage } from '@bufbuild/protobuf'

import { CompanySummary } from '@/gen/inventory/v1/company_summary_pb'

import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { CompanyRiskIcon } from '@/components/icons/company-risk'

type CompanySummaryBadgeProps = {
  companySummary: PlainMessage<CompanySummary>
  disableAction?: boolean
}

export const CompanySummaryBadge = ({
  companySummary: { risk, profileName, profileShortDescription, companyId },
  disableAction = false,
}: CompanySummaryBadgeProps) => {
  const { replaceParams } = useUrlParams()
  return (
    <div
      onClick={() =>
        !disableAction &&
        replaceParams({
          remove: [UrlParam.FINDING_ID, UrlParam.CONTROL_ID],
          update: [[UrlParam.THIRD_PARTY_ID, companyId]],
        })
      }
      className={cn('flex flex-col', {
        'cursor-pointer': !disableAction,
      })}
    >
      <div className='flex items-center gap-2'>
        <CompanyRiskIcon riskLevel={risk} />
        <h2 className='text-lg font-bold text-gray-600'>{profileName}</h2>
      </div>
      <span className='line-clamp-1 text-xs text-gray-500'>{profileShortDescription}</span>
    </div>
  )
}
