import { ArrowRightIcon } from 'lucide-react'

import { Comment } from '@/gen/inventory/v1/comment_pb'

import { cn } from '@/lib/style-helpers'

import { CommentCard } from '@/components/timeline/comment-card'

type ChangeCardProps = {
  statement: string
  prevBadge: React.ReactNode
  newBadge: React.ReactNode
  comment?: Comment
}

export const ChangeCard = ({ statement, prevBadge, newBadge, comment }: ChangeCardProps) => {
  return (
    <div>
      <div
        className={cn('flex flex-col gap-3 rounded-t border-x border-t p-5', {
          'rounded border': !comment,
        })}
      >
        <span className='font-semibold'>{statement}</span>
        <div className='flex flex-wrap items-center gap-3'>
          {prevBadge}
          <ArrowRightIcon size={14} />
          {newBadge}
        </div>
      </div>
      {comment && <CommentCard variant={'roundedNoneTopBorder'} comment={comment} />}
    </div>
  )
}
