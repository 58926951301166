import { Loader2Icon } from 'lucide-react'
import { useState } from 'react'

import { useAddComment } from '@/api/add-comment.hook'

import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'

type AddCommentProps = {
  controlId?: string
  findingId?: string
}

export const AddComment = ({ controlId, findingId }: AddCommentProps) => {
  const { mutateAsync, isLoading } = useAddComment(controlId, findingId)
  const [input, setInput] = useState('')
  const handleAddComment = async () => {
    await mutateAsync({ controlId, findingId, body: input })
    setInput('')
  }
  return (
    <div className='m-auto flex max-w-xl flex-col items-end gap-2'>
      <Textarea
        className='h-20 text-base'
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder='Type an internal comment.'
      />
      <Button className='px-10' disabled={isLoading || !input} onClick={handleAddComment}>
        {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />} Submit
      </Button>
    </div>
  )
}
