import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { riskLevelClassNameColor } from '@/lib/color'
import { cn } from '@/lib/style-helpers'

import { Tooltip } from '@/components/ui/tooltip'

import Critical from '@/assets/tier-critical.svg'
import High from '@/assets/tier-high.svg'
import Low from '@/assets/tier-low.svg'
import Medium from '@/assets/tier-medium.svg'

export type RiskFactorIconProps = {
  riskLevel: RiskLevel
  showTooltip?: boolean
}

export const CompanyRiskIcon = ({ riskLevel, showTooltip = false }: RiskFactorIconProps) => {
  const Icon = riskLevelToIcon[riskLevel]
  if (!Icon) return null

  return (
    <Tooltip
      tooltipDisabled={!showTooltip}
      trigger={
        <Icon className={cn('h-5 min-h-5 w-5 min-w-5', riskLevelClassNameColor[riskLevel])} />
      }
    >
      <div className='capitalize'>Inherent Risk: {riskLevelLabel[riskLevel]}</div>
    </Tooltip>
  )
}

const riskLevelToIcon: Record<RiskLevel, typeof Critical | null> = {
  [RiskLevel.CRITICAL]: Critical,
  [RiskLevel.HIGH]: High,
  [RiskLevel.MEDIUM]: Medium,
  [RiskLevel.LOW]: Low,
  [RiskLevel.UNSPECIFIED]: null,
}
