import { useMemo } from 'react'

import { useUrlParams } from '@/lib/url-param.hook'

import { TableViews } from '@/components/table/table.type'

export const useTableViews = <T>(tableViews: TableViews<T> | undefined, dataSourceWithKey: T[]) => {
  const { urlParams } = useUrlParams()

  const selectedView = tableViews && urlParams[tableViews.selectedViewUrlKey]

  const viewFilterFunc = useMemo(() => {
    if (selectedView && tableViews?.viewAccessors[selectedView]) {
      return tableViews?.viewAccessors[selectedView]
    }
    return () => true
  }, [selectedView, tableViews])

  const views = useMemo(
    () =>
      tableViews &&
      Object.entries(tableViews.viewAccessors).map(([name, accessor]) => ({
        name,
        count: dataSourceWithKey.filter(accessor).length,
      })),
    [tableViews, dataSourceWithKey],
  )

  return { selectedView, views, viewFilterFunc }
}
