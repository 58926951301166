import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'

import { Permission } from '@/gen/inventory/v1/company_service_pb'

import { getPermissionLabel } from '@/const/label'

import { PermissionIndicator, PermissionIndicatorList } from '@/components/indicator/permission'
import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { GroupedTable } from '@/components/table/grouped-table/grouped-table'
import { TableProps } from '@/components/table/table'
import type { ColumnType, GroupColumnType } from '@/components/table/table.type'
import { TextOverflow } from '@/components/text-overflow'
import { TextWithIcon } from '@/components/text-with-icon'

const columns: ColumnType<PlainMessage<Permission>>[] = [
  {
    title: 'Permission',
    dataIndex: 'name',
    defaultSortOrder: 'descend',
    search: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <TextOverflow>{description || '-'}</TextOverflow>,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    render: (category: PlainMessage<Permission>['category']) => (
      <PermissionIndicatorList showAllIndicators permissions={category} />
    ),
  },
  {
    title: 'source',
    dataIndex: 'source',
    render: (source) => <SourcesAvatarGroup sources={[source]} />,
  },
]

const expandColumns: GroupColumnType<PlainMessage<Permission>, 'category'>[] = [
  {
    title: 'Category',
    defaultSortOrder: 'ascend',
    accessor: ({ key }) => ({ sortValue: key }),
    render: (key, _, nestedRows) => (
      <TextWithIcon
        text={`${getPermissionLabel(key)} (${nestedRows.length})`}
        icon={<PermissionIndicator permission={key} />}
      />
    ),
  },
  {
    title: 'Source',
    render: (_key, _index, nestedRows) => (
      <SourcesAvatarGroup sources={nestedRows.map(({ source }) => source)} />
    ),
  },
]

type PermissionsTableProps = {
  dataSource: PlainMessage<Permission>[]
  exportEnabled?: boolean
} & Pick<TableProps<PlainMessage<Permission>>, 'loading'>

export const PermissionsTable = ({ dataSource, ...props }: PermissionsTableProps) => {
  return (
    <GroupedTable
      size='middle'
      groupByKey='category'
      tableColumns={expandColumns}
      nestedTableColumns={columns}
      dataSource={dataSource}
      toggleGroupModeLabel='Group by Category'
      {...props}
    />
  )
}
