import { useUpdateFinding } from '@/api/update-finding.hook'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'
import { useUrlParams } from '@/lib/url-param.hook'
import { badgeRiskVariant } from '@/lib/variants'

import { RiskLevelIcon } from '@/components/icons/risk-severity'
import { SelectWithComment } from '@/components/select/select-with-comment'
import { Badge } from '@/components/ui/badge'

type Variant = 'bold' | 'light' | 'borderless'
interface FindingSeverityBadgeProps {
  level: RiskLevel
  variant?: Variant
}

export const FindingSeverityBadge = ({ level, variant = 'bold' }: FindingSeverityBadgeProps) => {
  const label = riskLevelLabel[level]

  return (
    <Badge
      variant='outline'
      className={badgeRiskVariant({
        border: variant === 'borderless' ? 'borderless' : 'normal',
        weight: variant === 'light' ? 'light' : 'normal',
        riskLevel: level,
      })}
    >
      <RiskLevelIcon variant='inherit' level={level} />
      {label}
    </Badge>
  )
}

type SelectFindingSeverityBadgeProps = {
  severity: RiskLevel
  findingId: string
  suggestedEnabled: boolean
  extraTrackingProps?: EventProperties
  disableAction?: boolean
}

export const SelectFindingSeverityBadge = ({
  severity,
  findingId,
  suggestedEnabled,
  extraTrackingProps,
  disableAction = false,
}: SelectFindingSeverityBadgeProps) => {
  const { mutate: updateFinding } = useUpdateFinding(findingId)
  const trackUpdate = useTrackCallback('finding.severity.update')
  const { urlParams } = useUrlParams()
  if (disableAction) {
    return <FindingSeverityBadge level={severity} />
  }

  return (
    <SelectWithComment
      triggerAppearance='headless'
      value={severity}
      title='Customize the Severity for This Item'
      onSave={(value, comment) => {
        trackUpdate({
          tab: urlParams.companyDrawerTab,
          findingId,
          severity: riskLevelLabel[value],
          previousSeverity: riskLevelLabel[severity],
          comment,
          ...extraTrackingProps,
        })
        updateFinding({ id: findingId, level: value, comment: comment || undefined })
      }}
      menuItems={[
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.CRITICAL} />,
          value: RiskLevel.CRITICAL,
          isSuggested: suggestedEnabled,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.HIGH} />,
          value: RiskLevel.HIGH,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.MEDIUM} />,
          value: RiskLevel.MEDIUM,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.LOW} />,
          value: RiskLevel.LOW,
        },
      ]}
    />
  )
}
