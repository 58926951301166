import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'

import { InherentRiskGroup } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { riskLevelToColor } from '@/lib/color'

type InherentRiskCategoriesSummaryProps = {
  inherentRiskGroups: PlainMessage<InherentRiskGroup>[]
}

const categoryDescription: Record<string, string> = {
  'Attack Surface': 'risk permissions to infrastructure',
  'Operational Impact': 'operational impact',
  'Customer Impact': 'impact on customers',
  Data: 'risk data types shared with third-party',
  Spend: 'level spend associated with third-party',
}

export const InherentRiskCategoriesSummary = ({
  inherentRiskGroups,
}: InherentRiskCategoriesSummaryProps) => {
  const getMaxSeverity = (items: PlainMessage<InherentRiskGroup>['inherentRiskItems']) =>
    _.max(items.map(({ severity }) => severity)) || RiskLevel.UNSPECIFIED

  const groupedByCategory = _.groupBy(inherentRiskGroups, 'category')

  const categories = Object.keys(groupedByCategory).map((category) => {
    const groups = groupedByCategory[category]
    const allItems = _.flatten(groups.map((group) => group.inherentRiskItems))
    return { category, inherentRiskItems: allItems }
  })

  return (
    <div className='flex flex-col gap-4'>
      {categories
        .filter(({ inherentRiskItems }) => inherentRiskItems.length > 0)
        .sort((a, b) => {
          const aMaxSeverity = getMaxSeverity(a.inherentRiskItems)
          const bMaxSeverity = getMaxSeverity(b.inherentRiskItems)
          return bMaxSeverity - aMaxSeverity
        })
        .map(({ category, inherentRiskItems }) => {
          const maxSeverity = getMaxSeverity(inherentRiskItems)
          return (
            <div className='flex items-center gap-2 text-nowrap' key={category}>
              <div
                style={{ backgroundColor: riskLevelToColor[maxSeverity] }}
                className='size-2 rounded-full'
              />
              <div>
                {riskLevelLabel[maxSeverity]} {categoryDescription[category] || category}
              </div>
            </div>
          )
        })}
    </div>
  )
}
