import { Table as AntDTable, TableProps as AntDTableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import _ from 'lodash'
import { ReactNode, useContext, useMemo, useState } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterBar } from '@/components/table/filter/bar'
import { FilterProvider, FilterProviderContext } from '@/components/table/filter/context'
import { createGroups, isNestedRowDisplayed } from '@/components/table/grouped-table/group-lib'
import { sortIcon } from '@/components/table/sort-icon'
import { generateAntGroupColumn, generateFilter } from '@/components/table/table-generator'
import {
  ColumnType,
  GroupColumnType,
  GroupTableRow,
  TableViews,
} from '@/components/table/table.type'
import { ViewBar } from '@/components/table/view/bar'
import { useTableViews } from '@/components/table/view/use-table-views.hook'

import { NestedTable } from './nested-table'

type GroupedTableProps<T extends AnyObject, K extends keyof T> = {
  groupByKey: K & string
  tableColumns: GroupColumnType<T, K>[]
  nestedTableColumns: ColumnType<T>[]
  dataSource: T[]
  nestedTableAntDProps?: Omit<AntDTableProps<T>, 'columns' | 'dataSource'>
  toggleGroupModeLabel?: string
  tableViews?: TableViews<T>
  tableHeaderActions?: ReactNode
} & Omit<AntDTableProps<GroupTableRow<T, K>>, 'columns' | 'dataSource'>

// T represents the type of a single row
// K is the selected property to be used for grouping the table
const InternalTable = <T extends AnyObject, K extends keyof T>({
  dataSource,
  groupByKey,
  nestedTableColumns,
  tableColumns,
  nestedTableAntDProps,
  toggleGroupModeLabel,
  tableViews,
  tableHeaderActions,
  ...props
}: GroupedTableProps<T, K>) => {
  const { filteredState, searchState, checkboxFilterState, setCheckboxFilterState } =
    useContext(FilterProviderContext)

  const groups = createGroups(dataSource, groupByKey)

  const hasSearch = useMemo(
    () => nestedTableColumns.some((col) => col.search),
    [nestedTableColumns],
  )
  const antColumns = useMemo(
    () => tableColumns.map((col) => generateAntGroupColumn<T, K>(col, sortIcon, groups)),
    [tableColumns, groups],
  )

  const filters = useMemo(
    () =>
      nestedTableColumns
        .filter((col) => col.filter) // only include columns that have filter enabled
        .map((col) => generateFilter(col, Object.values(groups).flat())),
    [nestedTableColumns, groups],
  )

  const { viewFilterFunc, views } = useTableViews(tableViews, dataSource)

  const filteredDataSourceWithKey: GroupTableRow<T, K>[] = useMemo(() => {
    return _.uniqBy(
      dataSource
        .filter(
          (row) =>
            viewFilterFunc(row) &&
            isNestedRowDisplayed(row, searchState, filteredState, nestedTableColumns),
        )
        .map((row) => row[groupByKey])
        .map((key) => {
          if (_.isArray(key)) {
            return key.map((k: string) => ({ key: k }))
          }
          return { key }
        })
        .flat(),
      'key',
    )
  }, [dataSource, groupByKey, filteredState, nestedTableColumns, searchState, viewFilterFunc])

  return (
    <div className='block'>
      <div className='mb-4 flex items-center justify-between'>
        {tableViews && views && (
          <ViewBar
            onViewChange={(view) => {
              if (setCheckboxFilterState) {
                // We only group by default on the first view
                setCheckboxFilterState(view === views[0].name)
              }
            }}
            selectedViewUrlKey={tableViews.selectedViewUrlKey}
            views={views}
          />
        )}
        <div>{tableHeaderActions}</div>
      </div>
      <div className='mb-2'>
        <FilterBar
          showCheckboxFilter={!!toggleGroupModeLabel}
          checkboxFilterLabel={toggleGroupModeLabel}
          narrow
          showSearch={hasSearch}
          filters={filters}
        />
      </div>

      <div className={cn('on-row-active flex w-full flex-col gap-2')}>
        {checkboxFilterState ? (
          <AntDTable
            pagination={false}
            dataSource={filteredDataSourceWithKey}
            columns={antColumns}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: ({ key }) => {
                return (
                  <NestedTable
                    dataSource={groups[key].filter(viewFilterFunc)}
                    columns={nestedTableColumns}
                    {...nestedTableAntDProps}
                  />
                )
              },
            }}
            className='rounded-sm border border-solid border-gray-200'
            {...props}
          />
        ) : (
          <NestedTable
            dataSource={dataSource.filter(viewFilterFunc)}
            columns={nestedTableColumns}
            className='rounded-sm border border-solid border-gray-200'
            {...{
              ...(props as Omit<AntDTableProps<T>, 'columns' | 'dataSource'>),
              onRow: nestedTableAntDProps?.onRow,
            }}
          />
        )}
      </div>
    </div>
  )
}

export const GroupedTable = <T extends AnyObject, K extends keyof T>(
  props: GroupedTableProps<T, K>,
) => {
  const [checkboxFilterState, setCheckboxFilterState] = useState(true)
  return (
    <FilterProvider
      checkboxFilterState={checkboxFilterState}
      setCheckboxFilterState={setCheckboxFilterState}
    >
      <InternalTable {...props} />
    </FilterProvider>
  )
}
