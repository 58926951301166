import { VariantProps, cva } from 'class-variance-authority'
import Linkify from 'react-linkify'

import { useGetUserById } from '@/api/get-user-by-id.hook'
import { Comment } from '@/gen/inventory/v1/comment_pb'

import { Hyperlink } from '@/components/hyperlink'

const variants = cva('max-w-xl rounded border bg-gray-50', {
  variants: {
    variant: {
      default: '',
      roundedNoneTopBorder: 'rounded-t-none',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type CommentCardProps = {
  comment: Comment
} & VariantProps<typeof variants>

export const CommentCard = ({ comment, variant }: CommentCardProps) => {
  const { data } = useGetUserById(comment.userId)

  return (
    <div className={variants({ variant })}>
      <div className='p-5'>
        <span className='font-semibold'>{data?.name ?? 'User'} Commented</span>
        <div className='whitespace-pre-wrap'>
          <Linkify
            componentDecorator={(href, text, key) => (
              <Hyperlink href={href} key={key}>
                {text}
              </Hyperlink>
            )}
          >
            {comment.body}
          </Linkify>
        </div>
      </div>
    </div>
  )
}
