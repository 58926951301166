import _ from 'lodash'
import { useMemo } from 'react'

import { useListCompanyArtifact } from '@/api/artifact.hook'
import { DocumentSource } from '@/gen/inventory/v1/artifact_service_pb'

export const useHasManualUploadedArtifact = (companyId: string) => {
  const { data: artifacts, isLoading: isArtifactLoading } = useListCompanyArtifact(companyId)
  const hasManualUploadedArtifact = useMemo(() => {
    return artifacts?.some(({ source }) => {
      if (!source) return false
      return [
        DocumentSource.MANUAL_UPLOAD,
        DocumentSource.MANUAL_UPLOAD_WITH_COMPANY,
        DocumentSource.MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY,
      ].includes(source)
    })
  }, [artifacts])

  return { hasManualUploadedArtifact, isArtifactLoading }
}
