import { PartialMessage } from '@bufbuild/protobuf'
import { ReactNode } from 'react'
import Linkify from 'react-linkify'

import { Comment } from '@/gen/inventory/v1/comment_pb'

import { cn } from '@/lib/style-helpers'

import { Hyperlink } from '@/components/hyperlink'
import { Separator } from '@/components/ui/separator'
import { Tooltip } from '@/components/ui/tooltip'
import { UserAvatar } from '@/components/user-avatar'

export type BaseActivityRowLayoutProps = {
  formattedDate: string
  comment?: PartialMessage<Comment>
  userId?: string
}

type ActivityRowLayoutProps = BaseActivityRowLayoutProps & {
  icon: ReactNode
  title: string
  details?: string
  tooltip?: ReactNode
  onClick?: () => void
}

export const ActivityRowLayout = ({
  formattedDate,
  icon,
  title,
  comment,
  details,
  onClick,
  tooltip,
  userId,
}: ActivityRowLayoutProps) => {
  return (
    <Tooltip
      triggerClassName='block w-full'
      className='truncate px-7 py-3.5'
      tooltipDisabled={!tooltip}
      trigger={
        <div
          onClick={onClick}
          className={cn('text-wrap text-left', {
            'cursor-pointer': !!onClick,
          })}
        >
          <div className='my-2 flex justify-between rounded p-2 hover:bg-gray-50'>
            <div className='flex items-start gap-4.5'>
              <div className='mt-0.5 size-4'>{icon}</div>
              <div>
                <div className='line-clamp-1'>
                  <span className='font-semibold'>{title}</span>
                  {details && <span>{details}</span>}
                </div>
                {comment && (
                  <div className='mt-2 text-sm font-light'>
                    <span className='text-gray-500'>Comment:</span>
                    <Linkify
                      componentDecorator={(href, text, key) => (
                        <Hyperlink onClick={(e) => e.stopPropagation()} href={href} key={key}>
                          {text}
                        </Hyperlink>
                      )}
                    >
                      {comment.body}
                    </Linkify>
                  </div>
                )}
              </div>
            </div>
            <div className='flex items-center gap-1.5 whitespace-nowrap text-sm text-gray-400'>
              {userId && <UserAvatar userId={userId} />}
              {formattedDate}
            </div>
          </div>
          <div className='ml-10'>
            <Separator />
          </div>
        </div>
      }
    >
      {tooltip}
    </Tooltip>
  )
}
