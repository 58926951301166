import { useEffect, useState } from 'react'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { Loading } from '@/components/loading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { TabsProps, getTabItemIndex } from './types'

type HorizontalTabsProps = TabsProps<'separator'> & {
  disabled?: boolean
  isLoading?: boolean
  urlParamKey?: UrlParam
}

export const HorizontalTabs = ({
  items,
  className,
  disabled,
  isLoading = false,
  urlParamKey,
}: HorizontalTabsProps) => {
  const { urlParams, updateParam } = useUrlParams()
  const [value, setValue] = useState<string | undefined>(undefined)

  const handleValueChange = (value: string) => {
    setValue(value)
    if (urlParamKey) {
      updateParam(urlParamKey, value)
    }
  }

  const defaultItem = items
    .filter((item) => item !== 'separator')
    .find(({ itemDisabled }) => !itemDisabled)
  const defaultIndex = defaultItem && getTabItemIndex(defaultItem)
  const initialValue = urlParamKey ? urlParams[urlParamKey] || defaultIndex : defaultIndex
  useEffect(() => {
    if (urlParamKey) {
      const validTabValues = items
        .filter((item) => item !== 'separator')
        .map((item) => getTabItemIndex(item))

      if (!validTabValues.includes(urlParams[urlParamKey] || '')) {
        setValue(defaultIndex)
        return
      }
    }
    if (urlParamKey && urlParams[urlParamKey]) {
      setValue(urlParams[urlParamKey])
    }
  }, [urlParamKey, defaultIndex, items, urlParams])

  return (
    <Tabs
      defaultValue={initialValue}
      value={value}
      onValueChange={handleValueChange}
      className={className}
    >
      <TabsList className='mb-6 flex h-fit w-full justify-start gap-4 rounded bg-gray-50 px-9 py-2 text-gray-500'>
        {items.map((tabItem) => {
          if (tabItem === 'separator') {
            return <div key='separator' className='h-8 w-px bg-gray-200' />
          }

          const index = getTabItemIndex(tabItem)
          const { label, itemDisabled } = tabItem
          return (
            <TabsTrigger
              key={index}
              disabled={disabled || itemDisabled}
              className='h-9 rounded text-base hover:bg-gray-100 data-[state=active]:bg-gray-700 data-[state=active]:text-white data-[state=active]:shadow-sm'
              value={index}
            >
              {label}
            </TabsTrigger>
          )
        })}
      </TabsList>
      {isLoading ? (
        <Loading />
      ) : (
        items.map((tabItem) => {
          if (tabItem === 'separator') {
            return null
          }

          const index = getTabItemIndex(tabItem)
          return (
            <TabsContent className='px-8' key={index} value={index}>
              {tabItem.component}
            </TabsContent>
          )
        })
      )}
    </Tabs>
  )
}
