import { VariantProps } from 'class-variance-authority'
import { ChevronDownIcon, TriangleAlertIcon, XIcon } from 'lucide-react'
import { ReactNode, useEffect, useRef, useState } from 'react'

import { useOutsideClick } from '@/lib/outside-click.hook'
import { cn } from '@/lib/style-helpers'

import { MenuItem } from '@/components/select/type'
import { selectVariants } from '@/components/select/variants'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import {
  Select as SelectBase,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'

import { Item } from './item'

type SelectWithCommentProps<T extends number | string> = {
  title?: string
  value?: T
  placeholder?: string
  menuItems: MenuItem<T>[]
  onSave?: (value: T, comment: string) => void
  selectedValueRenderer?: (value: T) => ReactNode
} & VariantProps<typeof selectVariants>

export const SelectWithComment = <T extends number | string>({
  menuItems,
  title,
  value,
  onSave,
  triggerAppearance,
  placeholder,
  selectedValueRenderer,
}: SelectWithCommentProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [intendedValue, setIntendedValue] = useState<T | null>(null)
  const [comment, setComment] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, () => setIsOpen(false))

  useEffect(() => {
    setIntendedValue(null)
    setComment('')
  }, [isOpen])

  return (
    <SelectBase
      onValueChange={(newValue) => {
        // The internal select supports only strings but we want native support for numbers. This is why
        // we convert numbers to strings and here we need to convert strings back to numbers
        // in case the original input was numeric
        setIntendedValue((typeof value === 'number' ? Number(newValue) : newValue.toString()) as T)
        setComment('')
      }}
      open={isOpen}
      value={value && value.toString()}
    >
      <SelectTrigger
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
          // This hack is for making sure a click-outside is registered.
          // For some reason without it other open Select components will not close on a click outside even when the click was in a different select component
          document.dispatchEvent(new Event('mousedown'))
        }}
        className={selectVariants({ triggerAppearance })}
      >
        <div className={cn({ 'w-full': triggerAppearance === 'full' })}>
          {selectedValueRenderer && value ? (
            selectedValueRenderer(value)
          ) : (
            <SelectValue placeholder={<div className='w-full text-left'>{placeholder}</div>} />
          )}
        </div>
        <ChevronDownIcon
          strokeWidth={1}
          className='size-6 rounded p-1 opacity-50 group-hover:bg-gray-200'
        />
      </SelectTrigger>
      <SelectContent onClick={(e) => e.stopPropagation()} ref={ref} className='z-max'>
        <SelectGroup>
          {title && (
            <>
              <SelectLabel className='flex items-center justify-between gap-4 py-3 font-normal text-gray-700'>
                {title}
                <XIcon
                  onClick={() => setIsOpen(false)}
                  className='cursor-pointer rounded p-0.5 text-gray-400 duration-200 ease-in-out hover:bg-gray-200'
                  size={18}
                />
              </SelectLabel>
              <SelectSeparator />
            </>
          )}

          <div className='max-h-112 overflow-y-auto'>
            {menuItems.map((item, index) => {
              if (item.type === 'menuSelectItem') {
                item.isPotentialSelection = item.value === intendedValue
              }

              return (
                <div key={index}>
                  <Item item={item} />
                  <InputComment
                    warningMessage={item.type === 'menuSelectItem' ? item.warningMessage : null}
                    isOpen={
                      !!(
                        intendedValue &&
                        item.type === 'menuSelectItem' &&
                        item.value === intendedValue
                      )
                    }
                    comment={comment}
                    setComment={setComment}
                  />
                </div>
              )
            })}
          </div>
        </SelectGroup>
        <Separator className='my-4' />
        <Button
          disabled={!intendedValue}
          onClick={() => {
            if (onSave && intendedValue) {
              onSave(intendedValue, comment)
              setIsOpen(false)
            }
          }}
          className='float-end mx-4 mb-4 px-8'
        >
          Save
        </Button>
      </SelectContent>
    </SelectBase>
  )
}

type InputCommentProps = {
  isOpen: boolean
  comment: string
  setComment: (comment: string) => void
  warningMessage?: string | null
}

const InputComment = ({ isOpen, comment, warningMessage, setComment }: InputCommentProps) => {
  if (!isOpen) return null

  return (
    <div className='mx-4 my-2'>
      <div className='text-xs font-light'>Add a comment to this action</div>
      {warningMessage && (
        <TextWithIcon
          text={warningMessage}
          icon={<TriangleAlertIcon size={12} />}
          className='text-xs text-yellow-500'
        />
      )}
      <Textarea
        className='mt-2'
        placeholder='Document context on this change in a comment.'
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
    </div>
  )
}
