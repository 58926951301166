import { PlainMessage } from '@bufbuild/protobuf'

import { useGetCompanyCISOProfile } from '@/api/company.hook'
import {
  CompanyProfile as CompanyProfileMessage,
  InherentRiskGroup,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { CompanyProfile } from '@/pages/company-drawer/company-profile'

import { Loading } from '@/components/loading'

import { ActionCenter } from './action-center'
import { InherentRiskProfile } from './inherent-risk-profile'

type CompanyOverviewProps = {
  companyRisk: RiskLevel
  inherentRiskGroups?: PlainMessage<InherentRiskGroup>[]
  isLoading?: boolean
  companyId: string
  companyProfile: PlainMessage<CompanyProfileMessage>
}

export const CompanyOverview = ({
  companyRisk,
  inherentRiskGroups,
  isLoading = false,
  companyId,
  companyProfile,
}: CompanyOverviewProps) => {
  const { data: cisoProfile } = useGetCompanyCISOProfile(companyId)
  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='flex justify-between gap-4 px-1'>
      <div className='flex w-full flex-col gap-4'>
        <ActionCenter companyId={companyId} />
        <InherentRiskProfile companyRisk={companyRisk} inherentRiskGroups={inherentRiskGroups} />
      </div>
      <div className='max-w-96 rounded border'>
        <CompanyProfile info={companyProfile} ciso={cisoProfile} />
      </div>
    </div>
  )
}
