import { useCallback } from 'react'

import { useGetCompanyActivityLog } from '@/api/company.hook'
import { CompanyActivityLogItem } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

export const useIsIrqCompleted = (companyId: string) => {
  const { data } = useGetCompanyActivityLog(companyId)

  const findLastActivityDate = useCallback(
    (predicate: (activityLogItem: CompanyActivityLogItem['activityLogItem']) => boolean) => {
      return data?.reduce((latestDate: Date | null, { activityLogItem, time }) => {
        if (predicate(activityLogItem) && time) {
          const currentDate = time.toDate()
          return latestDate === null || currentDate > latestDate ? currentDate : latestDate
        }
        return latestDate
      }, null)
    },
    [data],
  )

  const lastIrqUpdate = findLastActivityDate(
    (activityLogItem) => activityLogItem.case === 'irqUpdate',
  )
  const lastStatusChange = findLastActivityDate(
    (activityLogItem) =>
      activityLogItem.case === 'statusChange' &&
      activityLogItem.value.newStatus === CompanyStatus.IN_ASSESSMENT,
  )

  if (!lastIrqUpdate) {
    return false
  }

  if (!lastStatusChange) {
    return true
  }

  return lastIrqUpdate > lastStatusChange
}
