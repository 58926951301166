import { cva } from 'class-variance-authority'

import { useGetCompany } from '@/api/company.hook'
import { useUpdateControl } from '@/api/control'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'

import { controlStatusLabel } from '@/const/label'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'
import { useUrlParams } from '@/lib/url-param.hook'

import { ControlStatusIcon } from '@/components/icons/control-status'
import { SelectWithComment } from '@/components/select/select-with-comment'
import { Badge } from '@/components/ui/badge'

type ControlStatusBadgeProps = {
  status: ControlStatus
}

const controlStatusVariant = cva(
  'text-md flex w-fit items-center gap-1 whitespace-nowrap border-none px-1 font-medium rounded',
  {
    variants: {
      status: {
        [ControlStatus.GAP]: 'bg-red-50 text-red-500',
        [ControlStatus.VALIDATED]: 'bg-green-50 text-green-500',
        [ControlStatus.NO_EVIDENCE]: 'bg-yellow-50 text-yellow-500',
        [ControlStatus.UNSPECIFIED]: 'bg-gray-200 text-gray-400',
      },
    },
  },
)

export const ControlStatusBadge = ({ status }: ControlStatusBadgeProps) => (
  <Badge
    variant='outline'
    className={controlStatusVariant({
      status,
    })}
  >
    <ControlStatusIcon status={status} />
    {controlStatusLabel[status]}
  </Badge>
)

type SelectControlStatusBadgeProps = {
  controlId: string
  status: ControlStatus
  extraTrackingProps?: EventProperties
}

export const SelectControlStatusBadge = ({
  controlId,
  status,
  extraTrackingProps,
}: SelectControlStatusBadgeProps) => {
  const { mutate: updateControl } = useUpdateControl(controlId)
  const { urlParams } = useUrlParams()
  const { data } = useGetCompany(urlParams.thirdPartyId || '')

  const trackControlValidationUpdate = useTrackCallback('control.validation.update')

  return (
    <SelectWithComment
      triggerAppearance='headless'
      value={status}
      title='Set the Validation Status for This Item'
      onSave={(value, comment) => {
        trackControlValidationUpdate({
          controlId,
          status: controlStatusLabel[value],
          previousStatus: controlStatusLabel[status],
          comment,
          company: data?.company?.profile?.name,
          tab: urlParams.companyDrawerTab,
          ...extraTrackingProps,
        })
        updateControl({ id: controlId, status: value, comment: comment || undefined })
      }}
      menuItems={[
        {
          type: 'menuSelectItem',
          label: <ControlStatusBadge status={ControlStatus.GAP} />,
          value: ControlStatus.GAP,
        },
        {
          type: 'menuSelectItem',
          label: <ControlStatusBadge status={ControlStatus.VALIDATED} />,
          value: ControlStatus.VALIDATED,
        },
        {
          type: 'menuSelectItem',
          label: <ControlStatusBadge status={ControlStatus.NO_EVIDENCE} />,
          value: ControlStatus.NO_EVIDENCE,
          disabled: status !== ControlStatus.NO_EVIDENCE,
        },
      ]}
    />
  )
}
