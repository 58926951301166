import { CopyCheck, LoaderIcon, PaperclipIcon, SignalIcon } from 'lucide-react'
import { useMemo } from 'react'

import { useListCompanyArtifact } from '@/api/artifact.hook'
import { DocumentStatus } from '@/gen/inventory/v1/artifact_service_pb'

import { ControlsAndFindingsReview } from '@/pages/assessment/controls-and-findings-review'
import { EvidenceCollection } from '@/pages/assessment/evidence-collection'
import { useIsIrqCompleted } from '@/pages/assessment/irq/use-is-irq-completed'
import { useFinishAssessmentModal } from '@/pages/assessment/use-finish-assessment-modal'
import { useHasManualUploadedArtifact } from '@/pages/assessment/use-has-manual-uploaded-artifact'
import { useIsAllControlValidated } from '@/pages/assessment/use-is-all-control-validated'
import { useIsAllFindingsWithoutControlsClosed } from '@/pages/assessment/use-is-all-findings-without-controls-closed'

import { Loading } from '@/components/loading'
import { MultiStepNavigator } from '@/components/multi-step-navigator'
import { Button } from '@/components/ui/button'

import { AssessmentFlowStep } from './assessment-flow-step-enum'
import { IrqAndInherentRiskProfile } from './irq/irq-and-inherent-risk-profile'

type CompanyAssessmentFlowProps = {
  companyId: string
}

export const CompanyAssessmentFlow = ({ companyId }: CompanyAssessmentFlowProps) => {
  const { renderModal, showModal } = useFinishAssessmentModal(companyId)
  const { hasManualUploadedArtifact, isArtifactLoading } = useHasManualUploadedArtifact(companyId)
  const isAllFindingsWithoutControlsClosed = useIsAllFindingsWithoutControlsClosed(companyId)
  const isAllControlsValidated = useIsAllControlValidated(companyId)
  const { data: artifacts } = useListCompanyArtifact(companyId)

  const isIrqCompleted = useIsIrqCompleted(companyId)

  const hasArtifactsInProcess = useMemo(() => {
    return artifacts?.some(
      ({ status, error }) =>
        !error &&
        [DocumentStatus.CLASSIFYING, DocumentStatus.ANALYZING, DocumentStatus.PENDING].includes(
          status,
        ),
    )
  }, [artifacts])

  if (isArtifactLoading) {
    return <Loading />
  }

  return (
    <>
      <Button
        data-dd-action-name='third-party.assessment.mark-as-assessed'
        className='float-right mb-8'
        onClick={() => showModal()}
        variant='outline'
      >
        Mark as Assessed
      </Button>
      <MultiStepNavigator
        steps={[
          {
            urlParamKey: AssessmentFlowStep.IRQ,
            title: 'Inherent Risk Questionnaire',
            component: <IrqAndInherentRiskProfile companyId={companyId} />,
            description: 'Determine third-party risk with an IRQ',
            icon: <SignalIcon strokeWidth={2} />,
            isCompleted: isIrqCompleted,
          },
          {
            urlParamKey: AssessmentFlowStep.EVIDENCE,
            title: 'Evidence Collection',
            component: <EvidenceCollection companyId={companyId} />,
            description: 'Collect artifacts & evidence',
            icon: <PaperclipIcon strokeWidth={2} className='-rotate-45' />,
            isCompleted: hasManualUploadedArtifact,
          },
          {
            urlParamKey: AssessmentFlowStep.CONTROLS,
            title: 'Controls & Findings Review',
            component: <ControlsAndFindingsReview companyId={companyId} />,
            description: 'Review controls & findings for this  third-party',
            icon:
              hasArtifactsInProcess && isIrqCompleted ? (
                <LoaderIcon className='animate-spin-2500' />
              ) : (
                <CopyCheck strokeWidth={2} />
              ),
            isCompleted: isAllFindingsWithoutControlsClosed && isAllControlsValidated,
          },
        ]}
      />
      {renderModal()}
    </>
  )
}
