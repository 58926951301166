import { Steps } from 'antd'
import { useEffect, useState } from 'react'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

type Step = {
  urlParamKey: string
  title: string
  description: React.ReactNode
  icon: React.ReactNode
  component: React.ReactNode
  isCompleted: boolean
}

type MultiStepNavigatorProps = {
  steps: Step[]
}

export const MultiStepNavigator = ({ steps }: MultiStepNavigatorProps) => {
  const { urlParams, updateParam } = useUrlParams()

  const handleStepChange = (step: number) => {
    setCurrentStep(step)
    updateParam(UrlParam.STEP, steps[step].urlParamKey)
  }
  const getDefaultStep = () => {
    const firstIncompleteIndex = steps.findIndex((step) => !step.isCompleted)
    return firstIncompleteIndex === -1 ? steps.length - 1 : firstIncompleteIndex
  }

  const [currentStep, setCurrentStep] = useState(() => {
    const stepFromUrl = urlParams[UrlParam.STEP]
    if (!stepFromUrl) {
      return getDefaultStep()
    }
    const stepIndex = steps.findIndex((step) => step.urlParamKey === stepFromUrl)
    return stepIndex === -1 ? getDefaultStep() : stepIndex
  })

  useEffect(() => {
    const stepFromUrl = urlParams[UrlParam.STEP]
    if (!stepFromUrl) {
      return
    }
    const newStepIndex = steps.findIndex((step) => step.urlParamKey === stepFromUrl)
    if (newStepIndex !== -1 && newStepIndex !== currentStep) {
      setCurrentStep(newStepIndex)
    }
  }, [urlParams, steps, currentStep])

  return (
    <>
      <Steps
        type='navigation'
        current={currentStep}
        onChange={handleStepChange}
        items={steps.map(({ title, description, icon }, index) => {
          const isCurrent = currentStep === index
          return {
            status: steps[index].isCompleted ? 'finish' : isCurrent ? 'process' : 'wait',
            title: <h4 className='font-semibold leading-5'>{title}</h4>,
            description: <div className='w-full text-nowrap text-md'>{description}</div>,
            icon: !steps[index].isCompleted && <div className='flex h-10 items-center'>{icon}</div>, // In case of completed step, we want the default antd icon
          }
        })}
      />
      <div className='mt-10'>{steps[currentStep].component}</div>
    </>
  )
}
