import { TimelineItemProps as AntdTimelineItemProps, Timeline } from 'antd'
import { PropsWithChildren } from 'react'

import { FindingTimelineItem } from '@/gen/inventory/v1/finding_service_pb'

import { formatDate } from '@/lib/date'

import { FindingChangeCard } from '@/pages/finding-details/finding-change-card'

import { TimelineIcon } from '@/components/icons/timeline-icon'
import { AddComment } from '@/components/timeline/add-comment'
import { CommentCard } from '@/components/timeline/comment-card'

type FindingTimelineProps = {
  findingId: string
  findingTimeline: FindingTimelineItem[]
  findingDetectionTime: Date
}

export const FindingTimeline = ({
  findingId,
  findingDetectionTime,
  findingTimeline,
}: FindingTimelineProps) => {
  return (
    <>
      <Timeline
        className='mx-auto w-full max-w-lg'
        items={[
          ...findingTimeline.map(toAntdTimeline),
          {
            children: (
              <DateHeader date={findingDetectionTime}>
                <div className='rounded border p-5 font-semibold'>Finding Opened by Lema</div>
              </DateHeader>
            ),
            dot: <TimelineIcon />,
          },
        ]}
      />
      <div className='sticky bottom-0 border-t bg-white py-8'>
        <AddComment findingId={findingId} />
      </div>
    </>
  )
}

const toAntdTimeline = (item: FindingTimelineItem): AntdTimelineItemProps => {
  return {
    dot: <TimelineIcon timelineCase={item.timelineItem.case} />,
    children: (
      <DateHeader date={item.timelineItem.value?.time?.toDate() || new Date()}>
        <TimelineItem item={item} />
      </DateHeader>
    ),
  }
}

type TimelineItemProps = {
  item: FindingTimelineItem
}

const TimelineItem = ({ item }: TimelineItemProps) => {
  const { timelineItem } = item
  switch (timelineItem.case) {
    case 'comment':
      return <CommentCard comment={timelineItem.value} />
    case 'change':
      return <FindingChangeCard change={timelineItem.value} />
    default:
      return null
  }
}

type DateHeaderProps = {
  date: Date
} & PropsWithChildren

const DateHeader = ({ date, children }: DateHeaderProps) => {
  return (
    <div>
      <div className='mb-2 text-gray-400'>{formatDate(date, 'longDate')}</div>
      {children}
    </div>
  )
}
