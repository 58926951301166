import { CheckIcon, ClipboardIcon, Loader2 } from 'lucide-react'
import { useEffect } from 'react'

import { useControlTimeline, useGetControl } from '@/api/control'
import { useGetFindings } from '@/api/findings.hook'
import { useBulkUpdateFindings } from '@/api/update-finding.hook'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { FindingTable } from '@/pages/company-drawer/finding-table'
import { ControlTimeline } from '@/pages/control/control-timeline'
import { ControlNotFound } from '@/pages/error-pages/control-not-found'

import { CompanySummaryBadge } from '@/components/badge/company-summary'
import { SelectControlStatusBadge } from '@/components/badge/control-status'
import { Documentation } from '@/components/documentation'
import { Evidence } from '@/components/evidence'
import { FindingIcon } from '@/components/icons/finding-status'
import { ImplicationIndicatorList } from '@/components/indicator/implication'
import { Loading } from '@/components/loading'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { HorizontalTabs } from '@/components/tabs/horizontal'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

import GlobeWithEmptySearch from '@/assets/globe-with-empty-search.svg'

type ControlProps = {
  controlId: string
}

export const Control = ({ controlId }: ControlProps) => {
  const { data, isLoading } = useGetControl(controlId)
  const { data: timelineItems } = useControlTimeline(controlId)
  const { urlParams } = useUrlParams()
  const trackViewControl = useTrackCallback('control.view')

  useEffect(() => {
    if (!isLoading) {
      trackViewControl({
        controlId,
        title: data?.control?.title,
        company: data?.company?.profileName,
        companyId: data?.company?.companyId,
        tab: urlParams.companyDrawerTab,
      })
    }
  }, [
    urlParams,
    controlId,
    data?.company?.profileName,
    data?.company?.companyId,
    data?.control?.title,
    isLoading,
    trackViewControl,
  ])

  if (isLoading) return <Loading />

  if (!data?.control) return <ControlNotFound />
  const { control } = data

  return (
    <div>
      <div className='bg-gray-50 px-8 pb-10 pt-5'>
        {data.company && (
          <div className='mb-6 flex items-center'>
            <CompanySummaryBadge companySummary={data.company} />
          </div>
        )}

        <div className='flex flex-col gap-1'>
          <h3 className='text-xs font-bold uppercase tracking-wide'>{control?.categoryName}</h3>
          <h2 className='text-3xl font-bold'>{control?.title}</h2>
          <div>{control?.description}</div>
        </div>
        <div className='mt-6 w-80'>
          <StatisticsRow
            items={[
              {
                title: 'Validation Status',
                children: control?.status && (
                  <SelectControlStatusBadge
                    controlId={controlId}
                    status={control.status}
                    extraTrackingProps={{
                      controlId: control.id,
                      controlTitle: control.title,
                      from: 'control.drawer',
                    }}
                  />
                ),
              },
              {
                title: 'Implications',
                children: <ImplicationIndicatorList activeImplications={data.implications} />,
              },
            ]}
          />
        </div>
      </div>
      <div className='px-9 pt-4.5'>
        <HorizontalTabs
          items={[
            {
              label: 'Control Overview',
              component: <ControlOverview controlId={controlId} />,
            },
            {
              index: 'commentsAndTimeline',
              label: `Comments & Timeline (${timelineItems?.length})`,
              component: <ControlTimeline timelineItems={timelineItems} controlId={controlId} />,
            },
          ]}
        />
      </div>
    </div>
  )
}

type ControlOverviewProps = {
  controlId: string
}

const ControlOverview = ({ controlId }: ControlOverviewProps) => {
  const { replaceParams } = useUrlParams()
  const { data, isLoading } = useGetControl(controlId)
  const { data: findings } = useGetFindings({ ids: data?.findingIds })
  const { mutate: bulkResolveFindings, isLoading: isResolvingFindings } = useBulkUpdateFindings(
    data?.findingIds || [],
    FindingStatus.RESOLVED,
  )
  const { mutate: bulkAcceptFindings, isLoading: isAcceptingFindings } = useBulkUpdateFindings(
    data?.findingIds || [],
    FindingStatus.ACCEPTED,
  )

  if (isLoading) return <Loading />

  if (!data?.control) return <ControlNotFound />
  const {
    documentation,
    factTitle,
    factMarkdown,
    additionalFactMarkdown,
    additionalFactCount,
    control,
  } = data
  return (
    <div className='pb-12'>
      <TextWithIcon
        text={factTitle}
        className={cn('mb-6 text-lg font-bold', {
          'text-md': control.status === ControlStatus.VALIDATED,
        })}
        icon={
          control.status === ControlStatus.VALIDATED && (
            <CheckIcon className='size-3.75 text-green-500' />
          )
        }
      />
      {control.status !== ControlStatus.VALIDATED && documentation && (
        <>
          <Documentation documentation={documentation} />
          {(!!factMarkdown || findings?.length > 0) && <Separator className='my-12' />}
        </>
      )}

      {findings && findings.length > 0 && (
        <>
          <div className='mb-3 flex flex-row-reverse items-center'>
            <div className='flex w-full justify-end gap-2'>
              <Button
                variant='outline'
                onClick={() => bulkResolveFindings()}
                disabled={isResolvingFindings}
              >
                <TextWithIcon
                  text={'Resolve All Findings'}
                  icon={
                    isResolvingFindings ? (
                      <Loader2 className='w-3.75 animate-spin' />
                    ) : (
                      <FindingIcon status={FindingStatus.RESOLVED} />
                    )
                  }
                />
              </Button>
              <Button
                variant='outline'
                onClick={() => bulkAcceptFindings()}
                disabled={isAcceptingFindings}
              >
                <TextWithIcon
                  text={'Accept All Findings'}
                  icon={
                    isAcceptingFindings ? (
                      <Loader2 className='w-3.75 animate-spin' />
                    ) : (
                      <FindingIcon status={FindingStatus.ACCEPTED} />
                    )
                  }
                />
              </Button>
            </div>
            <TextWithIcon
              className='font-medium'
              text='Findings'
              icon={<ClipboardIcon className='size-4' />}
            />
          </div>
          <FindingTable
            disableDefaultFilter={control.status === ControlStatus.VALIDATED}
            onClick={(findingId) => {
              replaceParams({
                remove: [UrlParam.CONTROL_ID],
                update: [[UrlParam.FINDING_ID, findingId]],
              })
            }}
            dataSource={findings}
          />
        </>
      )}

      {control.status === ControlStatus.VALIDATED && findings.length === 0 && !factMarkdown && (
        <div className='flex flex-col items-center'>
          <GlobeWithEmptySearch />
          <div className='text-center'>
            Lema has not found any indication that this control is violated.
            <br />
            We are continuing to monitor it.
          </div>
        </div>
      )}

      {findings && findings.length > 0 && factMarkdown && <Separator className='my-12' />}

      {factMarkdown && (
        <Evidence
          factMarkdown={factMarkdown}
          additionalFactMarkdown={additionalFactMarkdown}
          additionalFactCount={additionalFactCount}
        />
      )}
    </div>
  )
}
