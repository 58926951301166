import { Form, Input, Modal } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { useEffect } from 'react'

import { useReportErrorsCallback } from '@/lib/error-reporting'
import { isAbsoluteUrl } from '@/lib/string-validators.ts'

import { Button } from '@/components/ui/button'

const validateUrl = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()
  let url = value.trim()
  if (!isAbsoluteUrl(url)) {
    url = 'https://' + url
  }
  try {
    const parsedUrl = new URL(url)
    if (!parsedUrl.hostname.includes('.')) {
      return Promise.reject(new Error('Please enter a valid URL'))
    }
    return Promise.resolve()
  } catch (err) {
    return Promise.reject(new Error('Please enter a valid URL'))
  }
}

interface BookmarkFormValues {
  url: string
  name: string
}

interface BookmarkModalProps {
  visible: boolean
  onCancel: () => void
  onSubmit: (values: BookmarkFormValues) => void
  initialValues: BookmarkFormValues
  title: string
  submitButtonText: string
}

export const BookmarkModal = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  title,
  submitButtonText,
}: BookmarkModalProps) => {
  const [form] = Form.useForm<BookmarkFormValues>()
  const reportErrors = useReportErrorsCallback()

  useEffect(() => {
    if (visible && !form.isFieldsTouched()) {
      form.setFieldsValue(initialValues)
    }
  }, [visible, form, initialValues])

  const handleOk = async (values: BookmarkFormValues) => {
    try {
      await onSubmit(values)
      form.resetFields()
    } catch (error) {
      reportErrors(error as Error)
    }
  }

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Form<BookmarkFormValues>
        form={form}
        layout='vertical'
        name='bookmark-form'
        autoComplete='off'
        validateTrigger={['onChange', 'onBlur']}
        onFinish={handleOk}
      >
        <div>
          <h3 className='font-semibold leading-10'>
            Link<span className='text-red-500'>*</span>
          </h3>
          <Form.Item
            name='url'
            rules={[{ required: true, message: 'URL is required' }, { validator: validateUrl }]}
          >
            <Input
              placeholder='website.com'
              size='large'
              addonBefore='https://'
              className='w-full'
            />
          </Form.Item>
        </div>
        <div>
          <h3 className='font-semibold leading-10'>
            Name<span className='text-red-500'>*</span>
          </h3>
          <Form.Item name='name' rules={[{ required: true, message: 'Please enter a name' }]}>
            <Input placeholder='Name for the bookmark' size='large' className='w-full' />
          </Form.Item>
        </div>
        <Form.Item>
          <div className='flex justify-end gap-2'>
            <Button variant='ghost' type='button' onClick={onCancel}>
              Cancel
            </Button>
            <Button type='submit'>{submitButtonText}</Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}
