import { useEffect } from 'react'

import { useGetFinding } from '@/api/findings.hook'
import { useGetFindingTimeline } from '@/api/findings.hook'

import { useTrackCallback } from '@/lib/analytics/events'
import { useReportErrorsCallback } from '@/lib/error-reporting'
import { useUrlParams } from '@/lib/url-param.hook'

import { FindingNotFound } from '@/pages/error-pages/finding-not-found'
import { FindingOverview } from '@/pages/finding-details/finding-overview'
import { FindingTimeline } from '@/pages/finding-details/finding-timeline'

import { Loading } from '@/components/loading'
import { HorizontalTabs } from '@/components/tabs/horizontal'

import { FindingDetailsHeader } from './finding-header'

export type FindingDetailsProps = {
  findingId: string
}

export const FindingDetails = ({ findingId }: FindingDetailsProps) => {
  const tryReportErrors = useReportErrorsCallback()
  const { data: findingResult, error, isLoading, isError } = useGetFinding(findingId)
  const { data: findingTimeline, isLoading: isFindingTimelineLoading } =
    useGetFindingTimeline(findingId)
  tryReportErrors(error)
  const { urlParams } = useUrlParams()

  const trackViewFinding = useTrackCallback('finding.view')

  useEffect(() => {
    if (!isLoading) {
      trackViewFinding({
        findingId,
        title: findingResult?.finding?.title,
        company: findingResult?.company?.profileName,
        companyId: findingResult?.company?.companyId,
        tab: urlParams.companyDrawerTab,
      })
    }
  }, [
    findingResult?.company?.profileName,
    findingResult?.company?.companyId,
    findingResult?.finding?.title,
    findingId,
    trackViewFinding,
    isLoading,
    urlParams.companyDrawerTab,
  ])

  if (isLoading || isFindingTimelineLoading) {
    return <Loading />
  }

  if (isError) {
    return <FindingNotFound />
  }

  if (!findingResult?.finding || !findingResult.company) {
    return <FindingNotFound />
  }

  const finding = findingResult.finding
  const company = findingResult.company

  return (
    <>
      <FindingDetailsHeader company={company} {...finding} />
      <div className='px-9 pt-4.5'>
        <HorizontalTabs
          items={[
            {
              label: 'Finding Overview',
              component: <FindingOverview findingId={findingId} />,
            },
            {
              index: 'commentsAndTimeline',
              label: `Comments & Timeline (${findingTimeline.length})`,
              component: (
                <FindingTimeline
                  findingTimeline={findingTimeline}
                  findingDetectionTime={findingResult.finding.detectionTime?.toDate() || new Date()}
                  findingId={findingId}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  )
}
