import { PlainMessage } from '@bufbuild/protobuf'
import { Avatar } from 'antd'
import _ from 'lodash'
import { MinusIcon } from 'lucide-react'

import { Source } from '@/gen/inventory/v1/company_service_pb'

import { Tooltip } from '@/components/ui/tooltip'

type SourcesAvatarGroupProps = {
  sources: (PlainMessage<Source> | undefined)[]
}

export const SourcesAvatarGroup = ({ sources }: SourcesAvatarGroupProps) => {
  if (sources.length === 0) {
    return <MinusIcon className='text-gray-400' />
  }

  return (
    <Avatar.Group
      className='w-24 gap-0 transition-all duration-200 hover:gap-1.75'
      max={{
        count: 3,
        style: { width: 24, height: 24 },
      }}
    >
      {_.unionBy(sources, (source) => source && source.name).map((source) => {
        if (!source) {
          return null
        }
        const { imgUrl, name } = source
        return (
          <Tooltip
            key={name}
            trigger={
              <Avatar size={'small'} className='bg-white shadow-md'>
                <img width={40} height={40} src={imgUrl} alt={name} />
              </Avatar>
            }
          >
            {name}
          </Tooltip>
        )
      })}
    </Avatar.Group>
  )
}
