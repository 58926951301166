import { HomeIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { Company } from '@/gen/inventory/v1/company_service_pb'

import { companyStatusLabel, riskLevelLabel } from '@/const/label'
import { companyStatusPriority, riskLevelPriority } from '@/const/priority'

import { formatDate } from '@/lib/date'

import { isUrgentCompany } from '@/pages/third-party-inventory/urgent'

import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { CompanyUrgentBadge } from '@/components/badge/urgent'
import { DataTypeExplanation } from '@/components/data-type/data-type-explanation'
import { DataTypeTableCell } from '@/components/data-type/data-types-table-cell'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { RiskLevelSymbol } from '@/components/icons/risk-severity'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

const SMALL_COLUMN_WIDTH = '8%'
const MEDIUM_COLUMN_WIDTH = '12%'
const LARGE_COLUMN_WIDTH = '16%'
const EXTRA_LARGE_COLUMN_WIDTH = '20%'

export const inventoryColumns: ColumnType<Company>[] = [
  {
    width: EXTRA_LARGE_COLUMN_WIDTH,
    title: <TextWithIcon text='Third-Party' icon={<HomeIcon className='size-3' />} />,
    key: 'thirdParty',
    search: true,
    render: (company: Company) => {
      const { profile, solutionCount } = company
      return (
        <div>
          <div className='flex items-center gap-1.5'>
            <span className='max-w-sm truncate font-semibold'>{profile?.name}</span>
            {isUrgentCompany(company) && <CompanyUrgentBadge />}
          </div>
          <div className='truncate text-nowrap text-xs'>
            <span className='block max-w-md truncate text-gray-600'>
              {profile?.shortDescription}
            </span>
            {solutionCount > 1 && pluralize('Solutions', solutionCount, true)}
          </div>
        </div>
      )
    },
    accessor: ({ profile, solutions }) => ({
      sortValue: profile?.name,
      searchValue: profile?.name + ' ' + profile?.shortDescription + ' ' + solutions.join(' '),
    }),
  },
  {
    width: SMALL_COLUMN_WIDTH,
    title: 'Inherent Risk',
    defaultSortOrder: 'ascend',
    render: ({ risk }: Company) => <CompanyRiskIcon showTooltip riskLevel={risk} />,
    filter: true,
    accessor: ({ risk }) => ({
      filterValue: riskLevelLabel[risk],
      sortValue: riskLevelPriority[risk],
    }),
  },
  {
    width: LARGE_COLUMN_WIDTH,
    key: 'Findings',
    title: <TextWithIcon text='Findings' icon={<RiskLevelSymbol />} />,
    render: ({ findingsByLevel }: Company) => (
      <FindingDistribution findingCountByLevel={findingsByLevel} />
    ),
  },
  {
    width: MEDIUM_COLUMN_WIDTH,
    title: 'Status',
    ellipsis: true,
    filter: true,
    render: ({ status }: Company) => <CompanyVerificationBadge status={status} />,
    accessor: ({ status }) => {
      return {
        filterValue: companyStatusLabel[status],
        sortValue: companyStatusPriority[status],
      }
    },
  },
  {
    width: EXTRA_LARGE_COLUMN_WIDTH,
    key: 'dataTypes',
    title: <TextWithIcon text='Datatypes' iconPosition='end' icon={<DataTypeExplanation />} />,
    filterTitle: 'Datatypes',
    filter: true,
    sort: false,
    render: ({ dataTypes }: Company) => <DataTypeTableCell dataTypes={dataTypes} />,
    accessor: ({ dataTypes }) => ({
      filterValue: dataTypes.map(({ dataType }) => dataType),
    }),
  },
  {
    width: SMALL_COLUMN_WIDTH,
    title: 'Status Updated',
    render: ({ statusLastUpdate }: Company) => (
      <span className='text-nowrap text-gray-400'>
        {statusLastUpdate && formatDate(statusLastUpdate?.toDate())}
      </span>
    ),
    accessor: ({ statusLastUpdate }) => {
      return {
        sortValue: statusLastUpdate && statusLastUpdate.toDate(),
      }
    },
  },
  {
    width: SMALL_COLUMN_WIDTH,
    sort: false,
    title: 'Sources',
    dataIndex: 'sources',
    render: (sources) => <SourcesAvatarGroup sources={sources} />,
    filter: true,
    accessor: ({ sources }) => {
      return {
        filterValue: sources.map(({ name }) => name),
      }
    },
  },
  {
    width: SMALL_COLUMN_WIDTH,
    title: 'Date Added',
    render: ({ createdAt }: Company) => (
      <span className='text-nowrap text-gray-400'>
        {createdAt && formatDate(createdAt?.toDate())}
      </span>
    ),
    accessor: ({ createdAt }) => {
      return {
        sortValue: createdAt && createdAt.toDate(),
      }
    },
  },
]
