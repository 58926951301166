import { TimelineItemProps as AntdTimelineItemProps, Timeline } from 'antd'

import { ControlTimelineItem } from '@/gen/inventory/v1/control_service_pb'

import { ControlChangeCard } from '@/pages/control/control-change-card'

import { TimelineIcon } from '@/components/icons/timeline-icon'
import { AddComment } from '@/components/timeline/add-comment'
import { CommentCard } from '@/components/timeline/comment-card'
import { DateHeader } from '@/components/timeline/date-header'

type ControlTimelineProps = {
  timelineItems: ControlTimelineItem[]
  controlId: string
}

export const ControlTimeline = ({ timelineItems, controlId }: ControlTimelineProps) => {
  const controlTimeline = timelineItems || []

  return (
    <>
      <Timeline className='mx-auto w-full max-w-lg' items={controlTimeline.map(toAntdTimeline)} />
      <div className='sticky bottom-0 border-t bg-white py-8'>
        <AddComment controlId={controlId} />
      </div>
    </>
  )
}

const toAntdTimeline = (item: ControlTimelineItem): AntdTimelineItemProps => {
  return {
    dot: <TimelineIcon timelineCase={item.timelineItem.case} />,
    children: (
      <DateHeader date={item.timelineItem.value?.time?.toDate() || new Date()}>
        <TimelineItem item={item} />
      </DateHeader>
    ),
  }
}

type TimelineItemProps = {
  item: ControlTimelineItem
}

const TimelineItem = ({ item }: TimelineItemProps) => {
  const { timelineItem } = item
  switch (timelineItem.case) {
    case 'comment':
      return <CommentCard comment={timelineItem.value} />
    case 'change':
      return <ControlChangeCard change={timelineItem.value} />
    default:
      return null
  }
}
