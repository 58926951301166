import { datadogRum } from '@datadog/browser-rum'
import { useFeatureFlagEnabled as useFFEnabled } from 'posthog-js/react'

export type FeatureFlagKey =
  | 'tpcompany-permissions-table'
  | 'inventory-total-third-parties-change'
  | 'notifications'
  | 'data-type-documented-vs-exchanged'
  | 'tpcompany-solution-select'
  | 'spend'
  | 'soc2-report-assessment'
  | 'finding-assignee'
  | 'finding-suggested-severity'
  | 'finding-suggested-work-status'
  | 'finding-inventory'
  | 'third-party-show-even-if-analyzing'
  | 'finding-show-even-if-invisible'
  | 'questionnaire-module'
  | 'notification-settings'
  | 'third-party-subprocessors'
  | 'third-party-questionnaire-markdown'
  | 'logicmanager-integration'
  | 'wiz-integration'
  | 'notifications-email-preferences'
  | 'sso-settings'
  | 'third-party-usage-list-users'
  | 'dashboard'
  | 'dashboard-findings-over-time-chart'
  | 'irq-fill-suggested'

export const useFeatureFlagEnabled = (featureFlag: FeatureFlagKey) => {
  const isEnabled = useFFEnabled(featureFlag)
  datadogRum.addFeatureFlagEvaluation(featureFlag, isEnabled)
  if (isEnabled === undefined) {
    return { isLoading: true, isEnabled: false }
  } else {
    return { isLoading: false, isEnabled }
  }
}
