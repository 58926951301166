import { useGetFinding } from '@/api/findings.hook'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { FindingNotFound } from '@/pages/error-pages/finding-not-found'

import { Documentation } from '@/components/documentation'
import { Evidence } from '@/components/evidence'
import { Loading } from '@/components/loading'
import { Separator } from '@/components/ui/separator'

type FindingOverviewProps = {
  findingId: string
}

export const FindingOverview = ({ findingId }: FindingOverviewProps) => {
  const tryReportErrors = useReportErrorsCallback()
  const { data: findingResult, error, isLoading, isError } = useGetFinding(findingId)
  tryReportErrors(error)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <FindingNotFound />
  }

  if (!findingResult?.finding) {
    return <FindingNotFound />
  }

  const { documentation, markdown } = findingResult

  return (
    <div className='pb-13'>
      <Documentation documentation={documentation} controlIds={findingResult.controlIds} />
      <br />
      {markdown && (
        <>
          <Separator className='my-12' />
          <Evidence factMarkdown={markdown} />
        </>
      )}
    </div>
  )
}
