import { DataTypeInfo } from '@/gen/inventory/v1/company_service_pb'

import { DataTypes } from './data-type'
import { useDatatypeClickCallback } from './datatype.hook'

export const DataTypeTableCell = ({ dataTypes }: { dataTypes: DataTypeInfo[] }) => {
  const { action } = useDatatypeClickCallback()

  return (
    <div className='flex gap-1 overflow-hidden'>
      <DataTypes
        maxDisplay={2}
        dataTypes={dataTypes.map((dataType) => ({
          dataType,
          action,
        }))}
      />
    </div>
  )
}
