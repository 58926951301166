import { VariantProps, cva } from 'class-variance-authority'

import { isAbsoluteUrl } from '@/lib/string-validators'
import { cn } from '@/lib/style-helpers'

const hyperlinkVariant = cva('font-light', {
  variants: {
    color: {
      blue: 'text-blue-700 hover:text-blue-900',
      purple: 'text-purple-500 hover:text-purple-700',
    },
    defaultVariants: {
      color: 'blue',
    },
  },
})

type HyperlinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof hyperlinkVariant>

export const Hyperlink = ({
  href,
  children,
  className,
  color = 'blue',
  ...props
}: HyperlinkProps) => {
  if (!href) {
    return <span className={hyperlinkVariant({ color })}>{children}</span>
  }

  const isAbsolute = isAbsoluteUrl(href)

  return (
    <a
      className={cn(hyperlinkVariant({ color }), className)}
      href={href}
      target={isAbsolute ? '_blank' : '_self'}
      rel={isAbsolute ? 'noopener noreferrer' : ''}
      {...props}
    >
      {children}
    </a>
  )
}
