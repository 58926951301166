import { Timestamp } from '@bufbuild/protobuf'
import { Timeline } from 'antd'
import { CheckIcon, CircleIcon, DotIcon, LoaderIcon, RotateCcwIcon, XIcon } from 'lucide-react'
import { ReactNode, useEffect, useState } from 'react'

import { useRetryArtifact } from '@/api/document.hook'
import { DocumentError, DocumentStatus } from '@/gen/inventory/v1/artifact_service_pb'

import { LEMA_SUPPORT_EMAIL } from '@/const/contact'
import { documentStatusLabel } from '@/const/label'

import { timeSince } from '@/lib/date'
import { cn } from '@/lib/style-helpers'
import { useIsSupportUser } from '@/lib/support-user'

import { Hyperlink } from '@/components/hyperlink'
import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'

const env = import.meta.env.MODE

interface DocumentStatusBadgeProps {
  status: DocumentStatus
  error?: DocumentError
  lastUpdated?: Timestamp
  id: string
}

export const DocumentStatusBadge = ({
  status,
  error,
  lastUpdated,
  id,
}: DocumentStatusBadgeProps) => {
  const { mutateAsync: retryArtifact } = useRetryArtifact()
  const isSupportUser = useIsSupportUser()

  let icon: ReactNode
  if (error) {
    icon = <XIcon size={14} strokeWidth={1.5} />
  } else if (status === DocumentStatus.COMPLETE) {
    icon = <CheckIcon size={14} strokeWidth={1.5} />
  } else if (status !== DocumentStatus.PENDING) {
    icon = <LoaderIcon size={14} strokeWidth={1.5} className='animate-spin duration-2500' />
  }

  const shouldShowRetryButton =
    env === 'development' || env === 'preview' || env === 'staging' || isSupportUser
  const documentIsRetryable =
    lastUpdated && Date.now() - Number(lastUpdated.toDate()) > 60 * 60 * 1000

  return (
    <div className='flex items-center justify-between gap-2'>
      <Tooltip
        tooltipDisabled={status === DocumentStatus.PENDING || status === DocumentStatus.COMPLETE}
        className='p-0'
        trigger={
          <Badge
            variant='outline'
            className={cn(
              'flex w-fit cursor-default items-center gap-1 whitespace-nowrap border-none p-0 text-md font-normal text-purple-500',
              {
                'text-green-500': status === DocumentStatus.COMPLETE,
                'text-gray-400': status === DocumentStatus.PENDING,
                'text-red-400': !!error,
              },
            )}
          >
            {icon}
            {error ? 'Failed' : documentStatusLabel[status]}
          </Badge>
        }
      >
        {error !== DocumentError.UNSPECIFIED && error && (
          <div className='max-w-72 border-b p-4'>
            <Badge
              variant='outline'
              className='mb-3.5 flex w-fit items-center gap-1 rounded border-red-100 bg-red-50 px-1.5 text-md font-medium text-red-500'
            >
              <XIcon size={14} strokeWidth={1.5} />
              Failed
            </Badge>
            {artifactErrorComponent[error]}
          </div>
        )}
        <div className='p-5 pt-8'>
          <Timeline items={getTimelineItems(status, error, lastUpdated)} />
        </div>
      </Tooltip>
      {shouldShowRetryButton &&
        (error || (status != DocumentStatus.COMPLETE && documentIsRetryable)) && (
          <Button
            onClick={() => retryArtifact({ artifactId: id })}
            variant={'outline'}
            className='h-fit px-2 py-1 text-xs'
            size={'sm'}
          >
            <TextWithIcon text='Retry' icon={<RotateCcwIcon size={12} />} />
          </Button>
        )}
    </div>
  )
}

const getTimelineItems = (
  currentStatus: DocumentStatus,
  error?: DocumentError,
  lastUpdated?: Timestamp,
) => {
  const statuses = [
    DocumentStatus.PENDING,
    DocumentStatus.CLASSIFYING,
    DocumentStatus.ANALYZING,
    DocumentStatus.COMPLETE,
  ]

  return statuses.map((status) => {
    const isCurrent = status === currentStatus
    const isCompleted = statuses.indexOf(status) < statuses.indexOf(currentStatus)

    let dot: React.ReactNode
    if (error && isCurrent) {
      dot = <XIcon className='size-2.5 text-red-500' />
    } else if (isCompleted || (isCurrent && status === DocumentStatus.COMPLETE)) {
      dot = <DotIcon strokeWidth={18} size={7} className='text-green-500' />
    } else if (isCurrent) {
      dot = <CircleIcon strokeWidth={6} size={7} className='text-green-500' />
    } else {
      dot = <DotIcon strokeWidth={18} size={7} className='text-gray-300' />
    }

    return {
      children: (
        <TimelineItem
          label={documentStatusLabel[status]}
          showLastUpdated={isCurrent && !error}
          lastUpdated={lastUpdated}
        />
      ),
      dot,
    }
  })
}

type TimelineItemProps = {
  label: string
  lastUpdated?: Timestamp
  showLastUpdated?: boolean
}

const TimelineItem = ({ label, lastUpdated, showLastUpdated }: TimelineItemProps) => {
  const [{ unit, value }, setTimeSince] = useState(() =>
    timeSince(lastUpdated?.toDate() || new Date()),
  )

  useEffect(() => {
    if (!showLastUpdated) return () => {}
    const interval = setInterval(() => {
      const newTimeSince = timeSince(lastUpdated?.toDate() || new Date())
      if (newTimeSince.unit !== unit || newTimeSince.value !== value) {
        setTimeSince(newTimeSince)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [lastUpdated, unit, value, showLastUpdated])

  return (
    <div className='flex items-center justify-between gap-3'>
      {label}
      {showLastUpdated && (
        <div className='text-sm font-light text-gray-400'>{`${value} ${unit}`}</div>
      )}
    </div>
  )
}

const contactLink = (
  <Hyperlink target='_blank' href={`mailto:${LEMA_SUPPORT_EMAIL}`}>
    Contact us
  </Hyperlink>
)

const artifactErrorComponent: Record<DocumentError, ReactNode> = {
  [DocumentError.UNSPECIFIED]: '',
  [DocumentError.EMPTY]: 'File could not be processed because it contains no textual data.',
  [DocumentError.ENCRYPTED]:
    'File is encrypted and cannot be processed by Lema. Un-encrypt the file and upload it again.',
  [DocumentError.UNKNOWN_CATEGORY]: (
    <div>File could not be classified into a category. {contactLink} for assistance.</div>
  ),
  [DocumentError.UNSUPPORTED_TYPE]: 'File type is not supported.',
  [DocumentError.UNKNOWN_COMPANY]:
    'File could not be associated with a third-party. Try uploading it via the third-party tray.',
  [DocumentError.UNKNOWN_ERROR]: (
    <div>File could not be processed. {contactLink} for assistance.</div>
  ),
}
