import { FillIrqSuggestedButton } from '@/pages/assessment/irq/fill-irq-suggested-button'

import { Irq } from '@/components/irq'

type IrqStepProps = {
  companyId: string
}

export const IrqStep = ({ companyId }: IrqStepProps) => {
  return (
    <div>
      <div className='my-5 flex items-center justify-between'>
        <h2 className='text-xl font-light'>IRQ - Inherent Risk Questionnaire</h2>
        <FillIrqSuggestedButton companyId={companyId} />
      </div>
      <Irq companyId={companyId} />
    </div>
  )
}
