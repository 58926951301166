import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'
import { PencilIcon } from 'lucide-react'
import { useMemo } from 'react'

import {
  InherentRiskCategoryEnum,
  InherentRiskGroup,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { useEditIrqModal } from '@/pages/company-drawer/company-overview/edit-irq-modal/use-edit-irq-modal'

import { CompanyRiskBadge } from '@/components/badge/company-risk'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

import { InherentRiskCategoriesSummary } from './inherent-risk-categories-summary'
import { InherentRiskChart } from './inherent-risk-chart'

const defaultInherentRiskGroups: PlainMessage<InherentRiskGroup>[] = [
  {
    categoryEnum: InherentRiskCategoryEnum.DATA,
    category: 'Data',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.ATTACK_SURFACE,
    category: 'Attack Surface',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.SPEND,
    category: 'Spend',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT,
    category: 'Customer Impact',
    subcategory: 'Impact in case of outage',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.INTEG_METHOD,
    category: 'Customer Impact',
    subcategory: 'Integration Method',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.LOB,
    category: 'Operational Impact',
    subcategory: 'Lines of Business',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT,
    category: 'Operational Impact',
    subcategory: 'Impact in case of outage',
    inherentRiskItems: [] as InherentRiskItem[],
  },
]

type InherentRiskProfileProps = {
  companyRisk: RiskLevel
  inherentRiskGroups?: PlainMessage<InherentRiskGroup>[]
  showEditButton?: boolean
}

export const InherentRiskProfile = ({
  companyRisk,
  inherentRiskGroups = defaultInherentRiskGroups,
  showEditButton = true,
}: InherentRiskProfileProps) => {
  const { renderModal, showModal } = useEditIrqModal()
  const irqItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: _.uniqBy(
          _.filter(group.inherentRiskItems, (item) => item.source === InherentRiskSource.IRQ),
          (item) => item.id,
        ),
      })
    })
  }, [inherentRiskGroups])

  const lemaItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      const items = _.filter(
        group.inherentRiskItems,
        (item) => item.source !== InherentRiskSource.IRQ,
      )
      const groupedItems: Partial<InherentRiskItem>[][] = _.map(
        _.groupBy(items, (item) => item.displayName),
      )
      const filteredItems = _.map(
        _.values(groupedItems),
        (items) => _.maxBy(items, (item) => item.source) || new InherentRiskItem(),
      )

      const uniqItems = _.uniqBy(filteredItems, (item) => item.id)

      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: uniqItems,
      })
    })
  }, [inherentRiskGroups])

  const irqItemsCount = useMemo(() => {
    return irqItems.reduce((acc, { inherentRiskItems }) => acc + inherentRiskItems.length, 0)
  }, [irqItems])

  const selectedInherentRiskGroups = useMemo(
    () => (irqItemsCount > 0 ? irqItems : lemaItems),
    [irqItems, lemaItems, irqItemsCount],
  )

  const chart = useMemo(() => {
    const maxByCategory: Record<string, number> = {}

    for (const { category, inherentRiskItems } of selectedInherentRiskGroups) {
      const max = _.max(inherentRiskItems.map(({ severity }) => severity)) || 0

      // Note: it is possible for a category to have multiple sub categories
      // we need to take the max of all sub categories
      if (maxByCategory[category] === undefined || maxByCategory[category] < max) {
        maxByCategory[category] = max
      }
    }

    return Object.entries(maxByCategory).map(([x, y]) => ({ x, y }))
  }, [selectedInherentRiskGroups])
  return (
    <div className='flex justify-between rounded border'>
      <div className='p-6'>
        <div className='mb-3 flex items-center gap-3'>
          <div className='whitespace-nowrap text-xl font-bold'>Inherent Risk Profile</div>
          {showEditButton && (
            <Button
              className='h-fit px-2 py-1 text-sm'
              variant={'outline'}
              size={'sm'}
              onClick={showModal}
            >
              <TextWithIcon icon={<PencilIcon size={14} />} text='Edit' />
            </Button>
          )}
        </div>
        <CompanyRiskBadge
          riskLevel={companyRisk}
          className='my-4 gap-2 px-4 py-3'
          variant='highlighted'
        />
        <div className='flex flex-col gap-4'>
          <InherentRiskCategoriesSummary inherentRiskGroups={selectedInherentRiskGroups} />
        </div>
      </div>
      <div className='max-w-112'>
        <InherentRiskChart companyRisk={companyRisk} chart={chart} />
      </div>
      {renderModal()}
    </div>
  )
}
