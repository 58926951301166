import { Steps as AntdSteps, Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { ReactElement, useRef, useState } from 'react'

import { useUrlParams } from '@/lib/url-param.hook'

import { IrqStep } from '@/pages/company-drawer/company-overview/edit-irq-modal/irq-step'

import { Button } from '@/components/ui/button'

import { SelectInherentRiskStep, SelectInherentRiskStepRef } from './select-inherent-risk-step'

export type StepProps = {
  setIsNextEnabled: (isNextEnabled: boolean) => void
}

type Step = {
  title: string
  component: ReactElement
}

export const useEditIrqModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const selectInherentRiskStepRef = useRef<SelectInherentRiskStepRef>(null)

  const { urlParams } = useUrlParams()

  const showModal = () => setIsOpen(true)

  const [currentStep, setCurrentStep] = useState(0)

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const steps: Step[] = [
    { title: 'IRQ', component: <IrqStep companyId={urlParams.thirdPartyId || ''} /> },
    {
      title: 'Assign an Inherent Risk',
      component: (
        <SelectInherentRiskStep
          ref={selectInherentRiskStepRef}
          companyId={urlParams.thirdPartyId || ''}
        />
      ),
    },
  ]

  const closeModal = () => {
    setCurrentStep(0)
    setIsOpen(false)
  }
  const isLastStep = currentStep === steps.length - 1
  const isFirstStep = currentStep === 0

  const onFinish = async () => {
    await selectInherentRiskStepRef.current?.handleSetInherentRisk()
    closeModal()
  }

  const next = async () => {
    if (!isLastStep) {
      setCurrentStep(currentStep + 1)
    } else {
      await onFinish()
    }
  }

  const renderModal = () => (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      width={650}
      styles={{
        content: { padding: 0 }, // since we have colored header, we don't need padding
      }}
      open={isOpen}
      onCancel={() => closeModal()}
      footer={
        <div className='flex items-end justify-between px-15 py-6'>
          <Button variant={'ghost'} onClick={() => closeModal()}>
            Cancel
          </Button>
          <div className='flex gap-2'>
            {!isFirstStep && (
              <Button variant={'outline'} onClick={prev}>
                Back
              </Button>
            )}
            <Button disabled={selectInherentRiskStepRef.current?.isLoading} onClick={next}>
              {selectInherentRiskStepRef.current?.isLoading && (
                <Loader2Icon className='animate-spin' />
              )}
              {isLastStep ? 'Save & Close' : 'Next'}
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <div className='w-full rounded-t bg-gray-50 px-20 pt-10'>
          <h1 className='text-3xl font-bold'>Edit Inherent Risk Profile</h1>
          <AntdSteps className='w-2/3 py-8' size='small' current={currentStep} items={steps} />
        </div>
        <div className='px-20'>{steps[currentStep].component}</div>
      </div>
    </Modal>
  )

  return { showModal, renderModal }
}
