import _ from 'lodash'
import { CheckIcon, ClipboardCheckIcon, ClipboardIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'

import { useGetCompany } from '@/api/company.hook'
import { useListFindings } from '@/api/findings.hook'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { AssessmentFlowStep } from '@/pages/assessment/assessment-flow-step-enum'
import { CompanyDrawerTabs } from '@/pages/company-drawer/company-drawer-tabs-enum'
import {
  AssessmentStep,
  useUpcomingStep,
} from '@/pages/company-drawer/company-overview/use-upcoming-step.hook'

import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'

type ActionCenterProps = {
  companyId: string
}

export const ActionCenter = ({ companyId }: ActionCenterProps) => {
  const { updateParam } = useUrlParams()
  const { data: findings, isLoading: isFindingsLoading } = useListFindings({ companyId })
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)

  const upcomingStep = useUpcomingStep(companyId, company?.company?.status)

  const groupedCriticalFindings = useMemo(() => {
    return _.groupBy(
      _.filter(
        findings?.findings,
        ({ riskLevel, status }) =>
          riskLevel === RiskLevel.CRITICAL &&
          (status === FindingStatus.OPEN || status === FindingStatus.ONGOING),
      ),
      'title',
    )
  }, [findings])

  if (isFindingsLoading || isCompanyLoading) {
    return <Loading lineCount={1} />
  }

  const isActionsNeeded = upcomingStep !== null || Object.keys(groupedCriticalFindings).length > 0

  return (
    <div className='rounded border p-6'>
      <h3 className='mb-4 text-xl font-bold'>Action Center</h3>
      <div className='flex flex-col gap-4'>
        {!isActionsNeeded && (
          <TextWithIcon
            className='text-gray-500'
            text="You're all set—no urgent tasks open currently."
            icon={<CheckIcon size={14} className='text-green-500' />}
          />
        )}
        {Object.entries(groupedCriticalFindings).map(([title, findings]) => {
          const isSingleFinding = findings.length === 1
          const actionText = isSingleFinding ? title : `${findings.length} ${title}`
          const actionOnClick = () =>
            updateParam(
              isSingleFinding ? UrlParam.FINDING_ID : UrlParam.COMPANY_DRAWER_TAB,
              isSingleFinding ? findings[0].id : CompanyDrawerTabs.FINDINGS,
            )

          return (
            <ActionItem
              onClick={actionOnClick}
              key={title}
              text={actionText}
              icon={<ClipboardIcon size={14} />}
              urgent
            />
          )
        })}
        {upcomingStep && <NextAssessmentAction upcomingStep={upcomingStep} />}
      </div>
    </div>
  )
}

type ActionItemProps = {
  text: string
  icon: React.ReactNode
  onClick: () => void
  urgent?: boolean
}

const ActionItem = ({ text, icon, onClick, urgent }: ActionItemProps) => {
  return (
    <TextWithIcon
      text={text}
      icon={icon}
      onClick={onClick}
      className={cn(
        'cursor-pointer rounded bg-gray-100 px-1.5 duration-150 ease-in-out hover:bg-gray-200',
        {
          'bg-red-50 text-red-500 hover:bg-red-100': urgent,
        },
      )}
    />
  )
}

type NextAssessmentActionProps = {
  upcomingStep: AssessmentStep
}

const NextAssessmentAction = ({ upcomingStep }: NextAssessmentActionProps) => {
  const { replaceParams } = useUrlParams()
  const navigateToAssessment = useCallback(
    (step: AssessmentFlowStep) => {
      replaceParams({
        update: [
          [UrlParam.COMPANY_DRAWER_TAB, CompanyDrawerTabs.ASSESSMENT],
          [UrlParam.STEP, step],
        ],
      })
    },
    [replaceParams],
  )

  const stepToText: Record<AssessmentStep, string> = {
    start: 'Start assessment',
    irq: 'Assessment next step: Complete the IRQ',
    evidence: 'Assessment next step: Upload artifacts',
    controls: 'Assessment next step: Review controls',
    end: 'Assessment next step: Finish',
  }

  const nextStep = () => {
    switch (upcomingStep) {
      case 'start':
        return AssessmentFlowStep.IRQ
      case 'end':
        return AssessmentFlowStep.CONTROLS
      default:
        return upcomingStep
    }
  }

  return (
    <ActionItem
      text={stepToText[upcomingStep]}
      icon={<ClipboardCheckIcon size={14} />}
      onClick={() => navigateToAssessment(nextStep())}
    />
  )
}
