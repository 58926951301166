import { User } from '@/gen/inventory/v1/company_service_pb'

import { formatDate } from '@/lib/date'

import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'

const columns: ColumnType<User>[] = [
  {
    title: 'User Name',
    dataIndex: 'name',
    search: true,
    accessor: ({ name, email }) => ({
      searchValue: `${name} ${email}`,
      sortValue: name,
    }),
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Last Authorization Date',
    dataIndex: 'lastSeen',
    accessor: ({ lastSeen }) => ({
      sortValue: lastSeen?.toDate(),
    }),
    render: (lastSeen: User['lastSeen']) => lastSeen && formatDate(lastSeen.toDate()),
  },
  {
    title: 'Source',
    dataIndex: 'sources',
    render: (sources: User['sources']) => <SourcesAvatarGroup sources={sources} />,
  },
]

interface UsersTableProps {
  dataSource: User[]
}

export const UsersTable = ({ dataSource }: UsersTableProps) => {
  return <Table narrowFilterBar pagination={{}} columns={columns} dataSource={dataSource} />
}
