// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/company_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddBookmarkRequest, AddBookmarkResponse, DeleteBookmarkRequest, DeleteBookmarkResponse, GetBookmarksRequest, GetBookmarksResponse, GetCompanyActivityLogRequest, GetCompanyActivityLogResponse, GetCompanyCISOProfileRequest, GetCompanyCISOProfileResponse, GetCompanyDrawerMetadataRequest, GetCompanyDrawerMetadataResponse, GetCompanyRequest, GetCompanyResponse, GetInherentRiskRequest, GetInherentRiskResponse, ListCompaniesRequest, ListCompaniesResponse, ListInherentRiskCategoriesRequest, ListInherentRiskCategoriesResponse, SetIRQItemRequest, SetIRQItemResponse, UpdateBookmarkRequest, UpdateBookmarkResponse, UpdateCompanyRequest, UpdateCompanyResponse } from "./company_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.CompanyInventoryService";

/**
 * @generated from service inventory.v1.CompanyInventoryService
 */
export const CompanyInventoryService = {
  typeName: "inventory.v1.CompanyInventoryService",
  methods: {
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.ListCompanies
     */
    listCompanies: {
      name: "ListCompanies",
      I: ListCompaniesRequest,
      O: ListCompaniesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetCompany
     */
    getCompany: {
      name: "GetCompany",
      I: GetCompanyRequest,
      O: GetCompanyResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyDrawerMetadata
     */
    getCompanyDrawerMetadata: {
      name: "GetCompanyDrawerMetadata",
      I: GetCompanyDrawerMetadataRequest,
      O: GetCompanyDrawerMetadataResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.UpdateCompany
     */
    updateCompany: {
      name: "UpdateCompany",
      I: UpdateCompanyRequest,
      O: UpdateCompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetInherentRisk
     */
    getInherentRisk: {
      name: "GetInherentRisk",
      I: GetInherentRiskRequest,
      O: GetInherentRiskResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyCISOProfile
     */
    getCompanyCISOProfile: {
      name: "GetCompanyCISOProfile",
      I: GetCompanyCISOProfileRequest,
      O: GetCompanyCISOProfileResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.ListInherentRiskCategories
     */
    listInherentRiskCategories: {
      name: "ListInherentRiskCategories",
      I: ListInherentRiskCategoriesRequest,
      O: ListInherentRiskCategoriesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.SetIRQItem
     */
    setIRQItem: {
      name: "SetIRQItem",
      I: SetIRQItemRequest,
      O: SetIRQItemResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyActivityLog
     */
    getCompanyActivityLog: {
      name: "GetCompanyActivityLog",
      I: GetCompanyActivityLogRequest,
      O: GetCompanyActivityLogResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.GetBookmarks
     */
    getBookmarks: {
      name: "GetBookmarks",
      I: GetBookmarksRequest,
      O: GetBookmarksResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.AddBookmark
     */
    addBookmark: {
      name: "AddBookmark",
      I: AddBookmarkRequest,
      O: AddBookmarkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.UpdateBookmark
     */
    updateBookmark: {
      name: "UpdateBookmark",
      I: UpdateBookmarkRequest,
      O: UpdateBookmarkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.CompanyInventoryService.DeleteBookmark
     */
    deleteBookmark: {
      name: "DeleteBookmark",
      I: DeleteBookmarkRequest,
      O: DeleteBookmarkResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: CompanyInventoryService,});

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.ListCompanies
 */
export const listCompanies: UnaryFunctionsWithHooks<ListCompaniesRequest, ListCompaniesResponse> = {   ...$queryService.listCompanies,  ...createUnaryHooks($queryService.listCompanies)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetCompany
 */
export const getCompany: UnaryFunctionsWithHooks<GetCompanyRequest, GetCompanyResponse> = {   ...$queryService.getCompany,  ...createUnaryHooks($queryService.getCompany)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyDrawerMetadata
 */
export const getCompanyDrawerMetadata: UnaryFunctionsWithHooks<GetCompanyDrawerMetadataRequest, GetCompanyDrawerMetadataResponse> = {   ...$queryService.getCompanyDrawerMetadata,  ...createUnaryHooks($queryService.getCompanyDrawerMetadata)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.UpdateCompany
 */
export const updateCompany: UnaryFunctionsWithHooks<UpdateCompanyRequest, UpdateCompanyResponse> = {   ...$queryService.updateCompany,  ...createUnaryHooks($queryService.updateCompany)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetInherentRisk
 */
export const getInherentRisk: UnaryFunctionsWithHooks<GetInherentRiskRequest, GetInherentRiskResponse> = {   ...$queryService.getInherentRisk,  ...createUnaryHooks($queryService.getInherentRisk)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyCISOProfile
 */
export const getCompanyCISOProfile: UnaryFunctionsWithHooks<GetCompanyCISOProfileRequest, GetCompanyCISOProfileResponse> = {   ...$queryService.getCompanyCISOProfile,  ...createUnaryHooks($queryService.getCompanyCISOProfile)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.ListInherentRiskCategories
 */
export const listInherentRiskCategories: UnaryFunctionsWithHooks<ListInherentRiskCategoriesRequest, ListInherentRiskCategoriesResponse> = {   ...$queryService.listInherentRiskCategories,  ...createUnaryHooks($queryService.listInherentRiskCategories)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.SetIRQItem
 */
export const setIRQItem: UnaryFunctionsWithHooks<SetIRQItemRequest, SetIRQItemResponse> = {   ...$queryService.setIRQItem,  ...createUnaryHooks($queryService.setIRQItem)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetCompanyActivityLog
 */
export const getCompanyActivityLog: UnaryFunctionsWithHooks<GetCompanyActivityLogRequest, GetCompanyActivityLogResponse> = {   ...$queryService.getCompanyActivityLog,  ...createUnaryHooks($queryService.getCompanyActivityLog)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.GetBookmarks
 */
export const getBookmarks: UnaryFunctionsWithHooks<GetBookmarksRequest, GetBookmarksResponse> = {   ...$queryService.getBookmarks,  ...createUnaryHooks($queryService.getBookmarks)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.AddBookmark
 */
export const addBookmark: UnaryFunctionsWithHooks<AddBookmarkRequest, AddBookmarkResponse> = {   ...$queryService.addBookmark,  ...createUnaryHooks($queryService.addBookmark)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.UpdateBookmark
 */
export const updateBookmark: UnaryFunctionsWithHooks<UpdateBookmarkRequest, UpdateBookmarkResponse> = {   ...$queryService.updateBookmark,  ...createUnaryHooks($queryService.updateBookmark)};

/**
 * @generated from rpc inventory.v1.CompanyInventoryService.DeleteBookmark
 */
export const deleteBookmark: UnaryFunctionsWithHooks<DeleteBookmarkRequest, DeleteBookmarkResponse> = {   ...$queryService.deleteBookmark,  ...createUnaryHooks($queryService.deleteBookmark)};
