import { Empty } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { useMemo } from 'react'

import { useGetCompany, useGetCompanyActivityLog, useUpdateCompany } from '@/api/company.hook'
import { CompanyActivityLogItem } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { formatDate } from '@/lib/date'

import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { Button } from '@/components/ui/button'
import { UserAvatar } from '@/components/user-avatar'

type StartAssessmentProps = {
  companyId: string
}

export const StartAssessment = ({ companyId }: StartAssessmentProps) => {
  const { data } = useGetCompanyActivityLog(companyId)
  const { data: companyData } = useGetCompany(companyId)
  const { mutateAsync: updateCompany, isLoading } = useUpdateCompany(companyId)
  const pastAssessments = useMemo(() => {
    return data?.filter(
      ({ activityLogItem }) =>
        activityLogItem.case === 'statusChange' &&
        activityLogItem.value.newStatus === CompanyStatus.ASSESSED,
    )
  }, [data])
  const companyName = companyData?.company?.profile?.name || ''

  return (
    <>
      <h3 className='mb-3.5 text-xl font-bold'>Assessments Overview</h3>
      <div className='rounded border p-6'>
        <h4 className='font-semibold'>Start an assessment for this third-party:</h4>
        <div>Determine its inherent risk, and review its{"'"} controls and gaps.</div>
        <Button
          onClick={() => updateCompany({ status: CompanyStatus.IN_ASSESSMENT, id: companyId })}
          className='mt-4 flex items-center gap-2'
          disabled={isLoading}
        >
          Start
          {isLoading && <Loader2Icon className='animate-spin' size={16} />}
        </Button>
      </div>
      <h4 className='mt-12 font-semibold'>Past Assessments</h4>
      {pastAssessments.length > 0 ? (
        pastAssessments.map((pastAssessments) => (
          <PastAssessment
            companyInherentRisk={companyData?.company?.risk || RiskLevel.UNSPECIFIED}
            companyName={companyName}
            key={pastAssessments.id}
            logItem={pastAssessments}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Past Assessment'} />
      )}
    </>
  )
}

type PastAssessmentProps = {
  logItem: CompanyActivityLogItem
  companyName: string
  companyInherentRisk: RiskLevel
}

const PastAssessment = ({ logItem, companyName, companyInherentRisk }: PastAssessmentProps) => {
  return (
    <div className='mt-2.5 flex items-center justify-between rounded border px-6 py-5 shadow'>
      <div className='flex gap-1.5'>
        <CompanyRiskIcon riskLevel={companyInherentRisk} />
        <span className='font-semibold'>{companyName} assessment</span>
      </div>
      <div className='flex items-center gap-16'>
        {logItem.userId && <UserAvatar showEmail userId={logItem.userId} />}
        <div>{logItem.time && `Completed ${formatDate(logItem.time?.toDate())}`}</div>
      </div>
    </div>
  )
}
