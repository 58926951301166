import { useMemo } from 'react'

import { InherentRiskItem } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelPriority } from '@/const/priority'

import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

export const useCalculateInherentRisk = (companyId: string) => {
  const { irqItems } = useInherentRiskItems(companyId)

  return useMemo(() => {
    const categoryRisks: Record<string, RiskLevel> = {}

    // Group items by category
    const categories = irqItems.reduce(
      (acc, { category, inherentRiskItems }) => {
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(...inherentRiskItems)
        return acc
      },
      {} as Record<string, InherentRiskItem[]>,
    )

    // Calculate the highest risk for each category
    for (const [category, items] of Object.entries(categories)) {
      if (items.length === 0) {
        // No items in category, consider risk as LOW
        categoryRisks[category] = RiskLevel.LOW
        continue
      }

      // Take the highest risk among all items in the category
      const maxRisk = items.reduce((highestRisk, item) => {
        return riskLevelPriority[item.severity] < riskLevelPriority[highestRisk]
          ? item.severity
          : highestRisk
      }, RiskLevel.LOW)

      categoryRisks[category] = maxRisk
    }

    // Collect category risks and sort them from highest to lowest severity
    const nonEmptyCategoryRisks = Object.values(categoryRisks)
    if (nonEmptyCategoryRisks.length === 0) {
      return RiskLevel.LOW
    }

    const sortedRisks = nonEmptyCategoryRisks.sort(
      (a, b) => riskLevelPriority[a] - riskLevelPriority[b],
    )

    // Determine the final risk level
    let finalRisk: RiskLevel
    if (sortedRisks.length >= 2) {
      const topRisk = sortedRisks[0]
      const secondRisk = sortedRisks[1]

      if (topRisk === secondRisk) {
        finalRisk = topRisk
      } else {
        finalRisk = decrementRiskLevel(topRisk)
      }
    } else {
      finalRisk = sortedRisks[0]
    }

    return finalRisk
  }, [irqItems])
}

function decrementRiskLevel(riskLevel: RiskLevel): RiskLevel {
  if (riskLevel === RiskLevel.LOW) {
    return RiskLevel.LOW
  } else {
    // Define risk levels in order from highest to lowest severity
    const riskLevelsInOrder = [RiskLevel.CRITICAL, RiskLevel.HIGH, RiskLevel.MEDIUM, RiskLevel.LOW]
    const index = riskLevelsInOrder.indexOf(riskLevel)
    return riskLevelsInOrder[index + 1] || RiskLevel.LOW
  }
}
