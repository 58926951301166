import { useUpdateFinding } from '@/api/update-finding.hook'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

import { findingStatusLabel } from '@/const/label'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'
import { useUrlParams } from '@/lib/url-param.hook'
import { badgeSentimentVariant } from '@/lib/variants'

import { FindingIcon } from '@/components/icons/finding-status'
import { SelectWithComment } from '@/components/select/select-with-comment'
import { Badge } from '@/components/ui/badge'
import { Sentiment } from '@/components/variance-percentage/types'

interface FindingStatusBadgeProps {
  status: FindingStatus
}

export const FindingStatusBadge = ({ status }: FindingStatusBadgeProps) => (
  <Badge
    variant='outline'
    className={badgeSentimentVariant({
      sentiment: status === FindingStatus.RESOLVED ? Sentiment.POSITIVE : Sentiment.NEUTRAL,
    })}
  >
    <FindingIcon status={status} />
    {findingStatusLabel[status]}
  </Badge>
)

export type SelectFindingStatusBadgeProps = FindingStatusBadgeProps & {
  suggestedWorkStatusEnabled: boolean
  findingId: string
  extraTrackingProps?: EventProperties
  disableAction?: boolean
}

export const SelectFindingStatusBadge = ({
  findingId,
  status,
  suggestedWorkStatusEnabled,
  extraTrackingProps,
  disableAction = false,
}: SelectFindingStatusBadgeProps) => {
  const { mutate: updateFinding } = useUpdateFinding(findingId)
  const trackUpdate = useTrackCallback('finding.status.update')
  const { urlParams } = useUrlParams()

  // Findings should not be opened after being dealt with because this will cause problems with the control mechanism.
  // https://www.notion.so/lema-ai/Controls-Flow-and-Logic-f93d08f8e23c481db91ae510ccc6b8ea?pvs=4#a69e04c84b81411fad58f3053af59e93
  if (status === FindingStatus.RESOLVED || status === FindingStatus.ACCEPTED || disableAction) {
    return <FindingStatusBadge status={status} />
  }

  return (
    <SelectWithComment
      triggerAppearance='headless'
      value={status}
      title='Set the Work Status for This Item'
      onSave={(value, comment) => {
        trackUpdate({
          tab: urlParams.companyDrawerTab,
          findingId,
          comment,
          status: findingStatusLabel[value],
          previousStatus: findingStatusLabel[status],
          ...extraTrackingProps,
        })
        updateFinding({ id: findingId, status: value, comment: comment || undefined })
      }}
      menuItems={[
        {
          type: 'menuSelectItem',
          label: <FindingStatusBadge status={FindingStatus.OPEN} />,
          value: FindingStatus.OPEN,
          isSuggested: suggestedWorkStatusEnabled,
          disabled: status !== FindingStatus.OPEN,
        },
        {
          type: 'menuSelectItem',
          label: <FindingStatusBadge status={FindingStatus.ONGOING} />,
          value: FindingStatus.ONGOING,
        },
        {
          type: 'menuSelectItem',
          label: <FindingStatusBadge status={FindingStatus.ACCEPTED} />,
          value: FindingStatus.ACCEPTED,
        },
        {
          type: 'menuSelectItem',
          label: <FindingStatusBadge status={FindingStatus.RESOLVED} />,
          value: FindingStatus.RESOLVED,
        },
      ]}
    />
  )
}
