import _ from 'lodash'

import { useListCompanyArtifact } from '@/api/artifact.hook'
import { Artifact } from '@/gen/inventory/v1/artifact_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { WARNING_EXPIRY_DAYS } from '@/const/artifact-expiry'

import { MILLISECONDS_IN_A_DAY, formatDate } from '@/lib/date'

import { useIsIrqCompleted } from '@/pages/assessment/irq/use-is-irq-completed'
import { useChangeAssessmentStatusModal } from '@/pages/assessment/use-change-assessment-status-modal'
import { useIsAllControlValidated } from '@/pages/assessment/use-is-all-control-validated'
import { useIsAllFindingsWithoutControlsClosed } from '@/pages/assessment/use-is-all-findings-without-controls-closed'

import { Alert } from '@/components/ui/alert'

export const useFinishAssessmentModal = (companyId: string) => {
  const warningDate = Date.now() + WARNING_EXPIRY_DAYS * MILLISECONDS_IN_A_DAY
  const isAllFindingsClosed = useIsAllFindingsWithoutControlsClosed(companyId)
  const isAllControlsValidated = useIsAllControlValidated(companyId)
  const isIrqCompleted = useIsIrqCompleted(companyId)

  const { data: artifactsData } = useListCompanyArtifact(companyId)
  const artifactPassWarningTime = _.chain(artifactsData)
    .filter((artifact) => !!artifact.expirationDate)
    .groupBy('artifactSubcategory')
    .map((artifacts) => {
      return _.maxBy(
        artifacts,
        (artifact) => artifact.expirationDate?.toDate().getTime() ?? -Infinity,
      )
    })
    .filter(
      (artifact) =>
        !!artifact &&
        !!artifact.expirationDate &&
        artifact.expirationDate?.toDate().getTime() < warningDate,
    )
    .value() as Artifact[]

  const alerts = [
    {
      condition: !isIrqCompleted,
      message: 'IRQ not completed',
    },
    ...artifactPassWarningTime
      .filter((artifact) => !!artifact.expirationDate)
      .map(({ id, expirationDate, artifactSubcategory }) => {
        if (!expirationDate) return null
        const expirationDateTime = expirationDate.toDate().getTime()
        const currentTime = Date.now()
        const isExpired = expirationDateTime < currentTime
        return {
          condition: true,
          id,
          message: `${artifactSubcategory}: ${
            isExpired
              ? `expired on ${formatDate(expirationDate.toDate())}`
              : `expires on ${formatDate(expirationDate.toDate())}`
          }`,
        }
      }),
    {
      condition: !isAllControlsValidated,
      message: 'Controls are not validated',
    },
    {
      condition: !isAllFindingsClosed,
      message: 'Findings are still open',
    },
  ]

  const { renderModal, showModal: showFinishAssessmentModal } = useChangeAssessmentStatusModal({
    companyId,
    confirmButtonText: 'Finish Assessment 🎉',
    placeholder: '(Optional) Add a closing comment about this assessment.',
    status: CompanyStatus.ASSESSED,
    title: 'Add a Comment & Finish the Assessment',
    showExport: true,
    description: alerts.some((alert) => alert?.condition) && (
      <>
        <h4 className='mb-1 mt-3 font-semibold'>Open Issues</h4>
        {alerts.map(
          (alert, index) =>
            alert?.condition && (
              <Alert key={index} className='mb-2 px-4 py-1 text-yellow-500' variant={'note'}>
                {alert.message}
              </Alert>
            ),
        )}
      </>
    ),
  })

  const showModal = (comment?: string) => showFinishAssessmentModal(comment)

  return { renderModal, showModal }
}
