import { useMemo } from 'react'

import { useListFindings } from '@/api/findings.hook'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

export const useIsAllFindingsWithoutControlsClosed = (companyId: string) => {
  const { data: findingsData } = useListFindings({ companyId, withoutControlsOnly: true })
  return useMemo(() => {
    if (!findingsData) return false
    return findingsData.findings.every(
      ({ status }) => status === FindingStatus.ACCEPTED || status === FindingStatus.RESOLVED,
    )
  }, [findingsData])
}
