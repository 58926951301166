import { PartialMessage } from '@bufbuild/protobuf'
import { createPromiseClient } from '@connectrpc/connect'
import { useTransport } from '@connectrpc/connect-query'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import {
  getFinding,
  getFindings,
} from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'
import { listFindings } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'
import { getFindingTimeline } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'
import { FindingInventoryService } from '@/gen/inventory/v1/finding_service_connect'
import { GetFindingsRequest, ListFindingsRequest } from '@/gen/inventory/v1/finding_service_pb'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

export const useGetFindingTimeline = (findingId?: string) => {
  const queryState = useQuery(getFindingTimeline.useQuery({ findingId }))
  return {
    ...queryState,
    data: queryState.data?.items || [],
  }
}

const CHUNK_SIZE = 100 // Adjust chunk size as needed to avoid 414 error

const splitIds = (ids: string[]) => {
  const chunks = []
  for (let i = 0; i < ids.length; i += CHUNK_SIZE) {
    chunks.push(ids.slice(i, i + CHUNK_SIZE))
  }
  return chunks
}

export const useGetFindings = (req: PartialMessage<GetFindingsRequest>) => {
  const transport = useTransport()
  const client = createPromiseClient(FindingInventoryService, transport)
  const { ids = [] } = req
  const idChunks = useMemo(() => splitIds(ids), [ids])

  const queryResults = useQueries({
    queries: idChunks.map((chunk) => ({
      queryKey: [...getFindings.useQuery().queryKey, chunk],
      queryFn: () => client.getFindings({ ids: chunk }),
      enabled: ids.length > CHUNK_SIZE,
    })),
  })

  const singleQuery = useQuery(
    [...getFindings.useQuery().queryKey, ids],
    () => client.getFindings({ ids }),
    {
      enabled: ids.length <= CHUNK_SIZE,
    },
  )

  if (ids.length <= CHUNK_SIZE) {
    return {
      ...singleQuery,
      data: singleQuery.data?.findings || [],
    }
  }

  return {
    isLoading: queryResults.some((result) => result.isLoading),
    isError: queryResults.some((result) => result.isError),
    data: queryResults.flatMap((result) => result.data?.findings ?? []),
  }
}

export const useListFindings = ({
  companyId,
  solutionId,
  urgentOnly = false,
  withCompany = false,
  withoutControlsOnly = false,
}: PartialMessage<ListFindingsRequest>) => {
  const { isEnabled } = useFeatureFlagEnabled('finding-show-even-if-invisible')
  const res = useQuery(
    listFindings.useQuery({ companyId, solutionId, withCompany, urgentOnly, withoutControlsOnly }),
  )

  const findings = useMemo(() => {
    if (!res.data) {
      return []
    }
    if (isEnabled) {
      return res.data?.findings.map((finding) => ({
        ...finding,
        title: finding.visible ? finding.title : `* ${finding.title}`,
      }))
    }
    return res.data?.findings.filter((finding) => finding.visible)
  }, [res.data, isEnabled])

  return {
    ...res,
    data: {
      ...res.data,
      findings: findings || [],
    } as typeof res.data,
  }
}

export const useGetFinding = (id: string, excludeInternalLinks: boolean = false) => {
  return useQuery(getFinding.useQuery({ id, excludeInternalLinks }))
}
