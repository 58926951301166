// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file inventory/v1/finding_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetFindingRequest, GetFindingResponse, GetFindingsRequest, GetFindingsResponse, GetFindingTimelineRequest, GetFindingTimelineResponse, ListFindingsRequest, ListFindingsResponse, UpdateFindingRequest, UpdateFindingResponse } from "./finding_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service inventory.v1.FindingInventoryService
 */
export const FindingInventoryService = {
  typeName: "inventory.v1.FindingInventoryService",
  methods: {
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.GetFindingTimeline
     */
    getFindingTimeline: {
      name: "GetFindingTimeline",
      I: GetFindingTimelineRequest,
      O: GetFindingTimelineResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.ListFindings
     */
    listFindings: {
      name: "ListFindings",
      I: ListFindingsRequest,
      O: ListFindingsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.GetFinding
     */
    getFinding: {
      name: "GetFinding",
      I: GetFindingRequest,
      O: GetFindingResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.GetFindings
     */
    getFindings: {
      name: "GetFindings",
      I: GetFindingsRequest,
      O: GetFindingsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.UpdateFinding
     */
    updateFinding: {
      name: "UpdateFinding",
      I: UpdateFindingRequest,
      O: UpdateFindingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

