import { cva } from 'class-variance-authority'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { Sentiment } from '@/components/variance-percentage/types'

export const badgeSentimentVariant = cva(
  'text-md flex w-fit items-center gap-1 whitespace-nowrap border-none font-normal',
  {
    variants: {
      sentiment: {
        [Sentiment.NEGATIVE]: 'rounded bg-red-50 px-1 font-medium text-red-500',
        [Sentiment.POSITIVE]: 'rounded bg-green-50 px-1 font-medium text-green-500',
        [Sentiment.NEUTRAL]: 'pl-1 pr-0 text-gray-700',
      },
    },
  },
)

export const badgeRiskVariant = cva(
  'text-md flex w-fit items-center gap-1 rounded px-1.5 font-medium',
  {
    variants: {
      riskLevel: {
        [RiskLevel.CRITICAL]: 'border-red-100 bg-red-50 text-red-500',
        [RiskLevel.HIGH]: 'border-orange-100 bg-orange-50 text-orange-500',
        [RiskLevel.MEDIUM]: 'border-yellow-100 bg-yellow-50 text-yellow-500',
        [RiskLevel.LOW]: 'border-gray-200 bg-white text-gray-400',
        [RiskLevel.UNSPECIFIED]: '',
      },
      border: {
        normal: '',
        borderless: 'border-none bg-transparent',
      },
      weight: {
        normal: '',
        light: 'bg-opacity-50',
        backgroundless: 'bg-transparent',
      },
    },
    defaultVariants: {
      border: 'normal',
      weight: 'normal',
    },
    compoundVariants: [
      {
        riskLevel: [RiskLevel.MEDIUM],
        weight: 'light',
        className: 'text-yellow-300',
      },
    ],
  },
)
