import { useListFindings } from '@/api/findings.hook'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { AssessmentFlowStep } from '@/pages/assessment/assessment-flow-step-enum'
import { useIsIrqCompleted } from '@/pages/assessment/irq/use-is-irq-completed'
import { useFinishAssessmentModal } from '@/pages/assessment/use-finish-assessment-modal'
import { FindingTable } from '@/pages/company-drawer/finding-table'
import { ControlTable } from '@/pages/control/control-table'

import { Button } from '@/components/ui/button'

type ControlsAndFindingsReviewProps = {
  companyId: string
}

export const ControlsAndFindingsReview = ({ companyId }: ControlsAndFindingsReviewProps) => {
  const isIrqCompleted = useIsIrqCompleted(companyId)
  const { showModal, renderModal } = useFinishAssessmentModal(companyId)
  const { data, isLoading } = useListFindings({ companyId, withoutControlsOnly: true })
  const { updateParam } = useUrlParams()

  if (!isLoading && !data?.findings) {
    return null
  }

  return (
    <div className='mb-8'>
      <div className='-mb-7'>
        <h4 className='mb-4 text-lg font-bold'>Controls Review</h4>
        {isIrqCompleted ? (
          <ControlTable companyId={companyId} />
        ) : (
          <div className='my-3 flex flex-col items-center gap-1 rounded border py-13'>
            <Button onClick={() => updateParam(UrlParam.STEP, AssessmentFlowStep.IRQ)}>
              Fill an Inherent Risk Questionnaire
            </Button>
            <h5 className='font-bold'>To proceed with this step, please fill the IRQ.</h5>
            <span>
              A third-party’s inherent risk informs which controls Lema will test that third-party
              for.
            </span>
          </div>
        )}
      </div>
      <h4 className='mb-4 mt-13 text-lg font-bold'>Additional Findings</h4>
      <FindingTable
        showFilterBar={false}
        onClick={(id) => updateParam(UrlParam.FINDING_ID, id.toString())}
        loading={isLoading}
        dataSource={
          data?.findings.filter(
            ({ status }) => status === FindingStatus.OPEN || status === FindingStatus.ONGOING,
          ) || []
        }
      />
      <Button onClick={() => showModal()} className='float-end mt-4'>
        Finish Assessment 🎉
      </Button>
      {renderModal()}
    </div>
  )
}
