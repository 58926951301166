import { forwardRef, useImperativeHandle, useState } from 'react'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { useCalculateInherentRisk } from '@/lib/use-calculate-inherent-risk'

import { useSubmitIRQ } from '@/pages/assessment/irq/use-submit-irq'

import { CompanyRiskBadge, SelectCompanyRisk } from '@/components/badge/company-risk'
import { Separator } from '@/components/ui/separator'

type SelectInherentRiskStepProps = {
  companyId: string
}

export type SelectInherentRiskStepRef = {
  handleSetInherentRisk: () => Promise<void>
  isLoading: boolean
}

export const SelectInherentRiskStep = forwardRef<
  SelectInherentRiskStepRef,
  SelectInherentRiskStepProps
>(({ companyId }, ref) => {
  const [comment, setComment] = useState('')
  const suggestedRiskLevel = useCalculateInherentRisk(companyId)
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevel>(suggestedRiskLevel)
  const { submitIRQ, isLoading } = useSubmitIRQ(companyId)

  useImperativeHandle(ref, () => ({
    handleSetInherentRisk: async () => submitIRQ(selectedRiskLevel, suggestedRiskLevel, comment),
    isLoading,
  }))

  return (
    <div>
      <div className='mt-7 text-center'>Lema suggests this third-party’s inherent risk is</div>
      <div className='mb-8 mt-4 flex justify-center'>
        <CompanyRiskBadge
          className='gap-2 px-7 py-0.5 text-xl'
          variant='highlighted'
          riskLevel={suggestedRiskLevel}
        />
      </div>
      <Separator className='my-4' />
      <h4 className='my-5 font-bold'>Assign an inherent risk to this third-party:</h4>
      <SelectCompanyRisk
        comment={comment}
        setComment={setComment}
        setSelectedRiskLevel={setSelectedRiskLevel}
        selectedRiskLevel={selectedRiskLevel}
      />
    </div>
  )
})

SelectInherentRiskStep.displayName = 'SelectInherentRiskStep'
