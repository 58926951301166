import { CheckIcon } from 'lucide-react'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { RISK_LEVELS } from '@/lib/proto-types'
import { cn } from '@/lib/style-helpers'
import { badgeRiskVariant } from '@/lib/variants'

import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { TextWithIcon } from '@/components/text-with-icon'
import { Textarea } from '@/components/ui/textarea'

type Variant = 'default' | 'highlighted'

export type RiskFactorIconProps = {
  riskLevel: RiskLevel
  variant?: Variant
} & React.HTMLAttributes<HTMLDivElement>

export const CompanyRiskBadge = ({
  riskLevel,
  className,
  variant = 'default',
}: RiskFactorIconProps) => {
  return (
    <TextWithIcon
      className={cn(
        badgeRiskVariant({
          riskLevel,
          weight: variant === 'default' ? 'backgroundless' : 'light',
        }),
        'text-base font-normal',
        className,
      )}
      icon={<CompanyRiskIcon riskLevel={riskLevel} />}
      text={riskLevelLabel[riskLevel]}
    />
  )
}

export type SelectCompanyRiskProps = {
  selectedRiskLevel: RiskLevel
  comment: string
  setSelectedRiskLevel: (riskLevel: RiskLevel) => void
  setComment: (comment: string) => void
}

export const SelectCompanyRisk = ({
  selectedRiskLevel,
  comment,
  setComment,
  setSelectedRiskLevel,
}: SelectCompanyRiskProps) => {
  return (
    <div className='flex flex-col gap-2'>
      {RISK_LEVELS.map(({ no }) => (
        <div key={no} className='rounded px-2 duration-150 ease-in-out hover:bg-gray-100'>
          <div
            onClick={() => setSelectedRiskLevel(no)}
            className='flex cursor-pointer items-center justify-between py-2'
          >
            <CompanyRiskBadge riskLevel={no} />
            {selectedRiskLevel === no && <CheckIcon size={18} />}
          </div>
        </div>
      ))}
      <span>Comment (optional)</span>
      <Textarea
        placeholder='Document context on this change in a comment.'
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
    </div>
  )
}
