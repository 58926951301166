import { useGetControl } from '@/api/control'
import { Documentation as DocumentationType } from '@/gen/inventory/v1/documentation_pb'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { Markdown } from '@/components/markdown'

type DocumentationProps = {
  documentation?: DocumentationType
  controlIds?: string[]
}

export const Documentation = ({ documentation, controlIds }: DocumentationProps) => {
  return (
    <div className='flex gap-7 text-gray-700'>
      <div className='w-1/2 py-3.75 pr-10'>
        {controlIds?.map((id) => (
          <div key={id}>
            <h1 className='font-semibold leading-10'>Control</h1>
            <ControlCard controlId={id} />
            <br />
          </div>
        ))}
        <div>
          <h1 className='font-semibold leading-10'>Description</h1>
          <Markdown>{documentation?.description}</Markdown>
        </div>
        <br />
        <div>
          <h1 className='font-semibold leading-10'>What is the Risk?</h1>
          <Markdown>{documentation?.risk}</Markdown>
        </div>
      </div>
      <div className='w-1/2 bg-secondary px-9 py-3.75'>
        <h1 className='font-semibold leading-10'>Suggested Actions</h1>
        <ol className='list-decimal space-y-4 px-5'>
          {documentation?.actions.map((action, index) => <li key={index}>{action}</li>)}
        </ol>
      </div>
    </div>
  )
}

type ControlCardProps = {
  controlId: string
}

const ControlCard = ({ controlId }: ControlCardProps) => {
  const { data, isLoading } = useGetControl(controlId)
  const { replaceParams } = useUrlParams()

  if (isLoading || !data) {
    return null
  }

  return (
    <div
      className='group cursor-pointer rounded border border-purple-100 p-4 duration-200 ease-in-out hover:bg-gray-100'
      onClick={() =>
        data.control?.id &&
        replaceParams({
          remove: [UrlParam.FINDING_ID],
          update: [[UrlParam.CONTROL_ID, data.control.id]],
        })
      }
    >
      <h3 className='text-md font-light'>{data.control?.title}</h3>
      <span className='text-purple-400 group-hover:text-purple-500'>
        {data.control?.description}
      </span>
    </div>
  )
}
