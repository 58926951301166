import { useGetUserById } from '@/api/get-user-by-id.hook'
import { FindingChange } from '@/gen/inventory/v1/finding_service_pb'

import { findingStatusLabel, riskLevelLabel } from '@/const/label'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'
import { ChangeCard } from '@/components/timeline/change-card'

type FindingChangeCardProps = {
  change: FindingChange
}

export const FindingChangeCard = ({ change }: FindingChangeCardProps) => {
  const { data: user } = useGetUserById(change.userId)
  const { prevRiskLevel, newRiskLevel, prevStatus, newStatus, comment } = change

  let statement = ''
  let prevBadge: React.ReactNode = null
  let newBadge: React.ReactNode = null

  if (prevRiskLevel !== newRiskLevel) {
    statement = `${user?.name || 'User'} Changed Finding Severity to '${riskLevelLabel[newRiskLevel]}'`
    prevBadge = <FindingSeverityBadge level={prevRiskLevel} />
    newBadge = <FindingSeverityBadge level={newRiskLevel} />
  }

  if (prevStatus !== newStatus) {
    statement = `${user?.name || 'User'} Changed Finding Work Status to '${findingStatusLabel[newStatus]}'`
    prevBadge = <FindingStatusBadge status={prevStatus} />
    newBadge = <FindingStatusBadge status={newStatus} />
  }

  return (
    <ChangeCard statement={statement} prevBadge={prevBadge} newBadge={newBadge} comment={comment} />
  )
}
