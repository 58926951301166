import { Drawer } from 'antd'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { ActivityLog } from '@/pages/company-drawer/activity-log-drawer/activity-log'
import { SMALL_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_HIGH } from '@/pages/drawers/z-index'

export const ThirdPartyActivityLogDrawer = () => {
  const {
    urlParams: { thirdPartyActivityLogId },
    removeParam,
  } = useUrlParams()

  return (
    <Drawer
      zIndex={Z_INDEX_HIGH}
      width={SMALL_DRAWER_WIDTH}
      closeIcon={false}
      onClose={() => removeParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID)}
      open={!!thirdPartyActivityLogId}
    >
      {thirdPartyActivityLogId && <ActivityLog thirdPartyId={thirdPartyActivityLogId} />}
    </Drawer>
  )
}
