import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'
import { DollarSignIcon, FileClockIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { useUpdateCompany } from '@/api/company.hook'
import {
  FindingCountStat,
  PermissionCategory,
  CompanyProfile as ProtoCompanyProfile,
  Solution,
  Source,
} from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { INACTIVE_COMPANY_STATUSES } from '@/const/company-statuses'
import { companyStatusLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { useFinishAssessmentModal } from '@/pages/assessment/use-finish-assessment-modal'
import { BookmarksRow } from '@/pages/company-drawer/bookmark-row'

import { SelectAssessmentStatus } from '@/components/badge/third-party-status'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { FindingStatusSymbol } from '@/components/icons/finding-status'
import { PermissionSymbol } from '@/components/icons/permission'
import { thirdPartyStatusToIcon } from '@/components/icons/third-party-status'
import { PermissionIndicatorList } from '@/components/indicator/permission'
import { LastChanged } from '@/components/last-changed'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { TextWithIcon } from '@/components/text-with-icon'
import { Alert } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'

import { SolutionSelect } from './solution-select'

export interface ThirdPartyHeaderDetailsProps {
  companyId: string
  companyProfile: PlainMessage<ProtoCompanyProfile>
  description: string
  status: {
    status: CompanyStatus
    lastChanged?: Date
  }
  permissions: PermissionCategory[]
  spend: string
  solutions: PlainMessage<Solution>[]
  findingCount: FindingCountStat[]
  companyRisk: RiskLevel
  setSelectedSolution: (solution?: string) => void
  selectedSolution?: string
  sources: Source[]
}

export const ThirdPartyDetailsHeader = ({
  companyProfile,
  findingCount,
  spend,
  status,
  permissions,
  solutions,
  companyRisk,
  companyId,
  selectedSolution,
  sources,
  setSelectedSolution,
}: ThirdPartyHeaderDetailsProps) => {
  const { renderModal: renderFinishAssessmentModal, showModal: showFinishAssessmentModal } =
    useFinishAssessmentModal(companyId)
  const { updateParam } = useUrlParams()

  const { isEnabled: spendEnabled } = useFeatureFlagEnabled('spend')

  const isArchived = INACTIVE_COMPANY_STATUSES.indexOf(status.status) !== -1

  const { mutate: updateCompany } = useUpdateCompany(companyId)

  const trackStatusUpdate = useTrackCallback('third-party.status.update')

  const selectSolutionEnabled = solutions.length > 1

  return (
    <>
      <div>
        <div className='mb-1 flex items-center'>
          <TextWithIcon
            className='gap-2'
            icon={<CompanyRiskIcon showTooltip riskLevel={companyRisk} />}
            text={<h2 className='text-3xl font-semibold text-gray-700'>{companyProfile.name}</h2>}
          />
          <div className='flex w-full justify-between'>
            <div className='ml-2.5 flex items-center pt-2'>
              <SelectAssessmentStatus
                onValueChange={(newStatus, comment) => {
                  if (
                    status.status === CompanyStatus.IN_ASSESSMENT &&
                    newStatus === CompanyStatus.ASSESSED
                  ) {
                    showFinishAssessmentModal(comment)
                    return
                  }

                  trackStatusUpdate({
                    companyId,
                    status: companyStatusLabel[newStatus],
                    previousStatus: companyStatusLabel[status.status],
                    comment,
                    company: companyProfile.name,
                  })

                  updateCompany({
                    status: newStatus,
                    id: companyId,
                    comment: comment || undefined,
                  })
                }}
                status={status.status}
              />
              {status.lastChanged && <LastChanged lastChanged={status.lastChanged} />}
            </div>
            <Button
              variant='outline'
              className='bg-transparent'
              onClick={() => updateParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID, companyId)}
            >
              <TextWithIcon icon={<FileClockIcon className='size-3.5' />} text='Activity' />
            </Button>
          </div>
        </div>
        {isArchived && (
          <Alert className='my-3' variant={'default'}>
            <TextWithIcon
              icon={thirdPartyStatusToIcon[status.status]}
              text={`Third-Party has been ${companyStatusLabel[status.status]}.`}
            />
          </Alert>
        )}
      </div>
      <div className='mb-4 mt-1'>
        <span className='mr-2'>{companyProfile.longDescription}</span>
      </div>
      <div className='flex max-w-5xl items-center gap-x-10 2xl:gap-x-20'>
        {selectSolutionEnabled && (
          <SolutionSelect
            setSelectedSolution={setSelectedSolution}
            selectedSolution={selectedSolution}
            solutions={solutions}
          />
        )}
        <StatisticsRow
          items={[
            {
              title: 'Solution',
              hide: selectSolutionEnabled,
              children: (
                <div className='max-w-60 truncate'>
                  {solutions.length ? solutions[0].name : companyProfile.name}
                </div>
              ),
            },
            {
              title: pluralize('Source', sources.length),
              children: <SourcesAvatarGroup sources={sources} />,
            },
            {
              title: 'Findings',
              icon: <FindingStatusSymbol />,
              children: <FindingDistribution findingCountByLevel={findingCount} />,
            },
            {
              hide: !spendEnabled,
              title: 'Spend',
              icon: <DollarSignIcon className='w-2.5' />,
              children: <span className='text-lg'>{spend}</span>,
            },
            {
              title: 'Permissions',
              icon: <PermissionSymbol />,
              children: <PermissionIndicatorList permissions={permissions} />,
            },
          ]}
        />
      </div>
      <BookmarksRow companyId={companyId} />
      {renderFinishAssessmentModal()}
    </>
  )
}
