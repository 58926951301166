import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  addComment,
  getControlTimeline,
} from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'
import { getFindingTimeline } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

export const useAddComment = (controlId?: string, findingId?: string) => {
  const queryClient = useQueryClient()
  const { queryKey: findingTimelineKey } = getFindingTimeline.useQuery({ findingId })
  const { queryKey: controlTimelineKey } = getControlTimeline.useQuery({ controlId })

  return useMutation(addComment.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(findingTimelineKey)
      queryClient.invalidateQueries(controlTimelineKey)
    },
  })
}
