import { datadogRum } from '@datadog/browser-rum'
import { usePostHog } from 'posthog-js/react'
import { useCallback } from 'react'

export type EventName =
  | 'third-party.add'
  | 'third-party.view'
  | 'third-party.solution.view'
  | 'third-party.profile.view'
  | 'third-party.risk.update'
  | 'third-party.status.update'
  | 'third-party.export'
  | 'third-party.control.settings.view'
  | 'finding.view'
  | 'finding.export'
  | 'finding.severity.update'
  | 'finding.status.update'
  | 'control.view'
  | 'control.validation.update'
  | 'artifact.upload'
  | 'artifact.view'
  | 'third-party.add.manual'
  | 'third-party.add.finder'
  | 'third-party.domain.click'
  | 'third-party.irq.submit'
  | 'third-party.irq.set'
  | 'questionnaire.new'
  | 'questionnaire.finalize'
  | 'third-party.assessment.mark-as-assessed'

export const useTrackCallback = (eventName: EventName) => {
  const posthog = usePostHog()
  const callback = useCallback(
    async (properties: Record<string, unknown>) => {
      if (!posthog) {
        return
      }
      posthog.capture(eventName, properties)
      datadogRum.addAction(eventName, properties)
    },
    [posthog, eventName],
  )

  return callback
}

export type EventProperties = Record<string, string | boolean | number | undefined | null>
