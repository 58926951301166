import { useGetUserById } from '@/api/get-user-by-id.hook'
import { ControlChange } from '@/gen/inventory/v1/control_service_pb'

import { controlStatusLabel } from '@/const/label'

import { ControlStatusBadge } from '@/components/badge/control-status'
import { ChangeCard } from '@/components/timeline/change-card'

type ControlChangeCardProps = {
  change: ControlChange
}

export const ControlChangeCard = ({ change }: ControlChangeCardProps) => {
  const { data: user } = useGetUserById(change.userId)
  const { prevStatus, newStatus, comment } = change

  const statement = `${user?.name || 'User'} Changed Control Validation Status to '${controlStatusLabel[newStatus]}'`
  const prevBadge = <ControlStatusBadge status={prevStatus} />
  const newBadge = <ControlStatusBadge status={newStatus} />

  return (
    <ChangeCard statement={statement} prevBadge={prevBadge} newBadge={newBadge} comment={comment} />
  )
}
