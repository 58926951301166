import { Upload as AntUpload, UploadProps } from 'antd'
import { InboxIcon, UploadIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'

import './ant-upload.less'

const { Dragger } = AntUpload
export const Upload = (props: UploadProps) => {
  return (
    <Dragger {...props}>
      <p className='ant-upload-drag-icon flex justify-around'>
        <InboxIcon size={40} strokeWidth={1} className='text-purple-600' />
      </p>
      <Button className='mb-3'>
        <UploadIcon size={14} className='mr-2' />
        Select Files
      </Button>
      <p>Drag file(s) to this area to upload</p>
    </Dragger>
  )
}
