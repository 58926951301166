import { useUpdateBookmark } from '@/api/bookmark.hook'
import { Bookmark } from '@/gen/inventory/v1/company_service_pb'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { BookmarkModal } from '@/components/bookmark/bookmark-modal.hook'
import { useToast } from '@/components/ui/use-toast'

interface EditBookmarkModalProps {
  bookmark: Bookmark
  companyId: string
  visible: boolean
  onClose: () => void
}

interface BookmarkFormValues {
  url: string
  name: string
}

export const EditBookmarkModal = ({
  bookmark,
  companyId,
  visible,
  onClose,
}: EditBookmarkModalProps) => {
  const updateBookmarkMutation = useUpdateBookmark(companyId)
  const { toast } = useToast()
  const reportErrors = useReportErrorsCallback()

  const handleSubmit = async (values: BookmarkFormValues) => {
    const url = values.url.trim()
    const name = values.name.trim()
    const updatedBookmarkData = {
      id: bookmark.id,
      name,
      url,
      companyId,
    }

    try {
      await updateBookmarkMutation.mutateAsync(updatedBookmarkData)

      toast({
        status: 'success',
        title: 'Bookmark updated successfully',
      })
      onClose()
    } catch (error) {
      reportErrors(error as Error)
      toast({
        status: 'error',
        title: 'Failed to update bookmark',
      })
    }
  }

  const initialValues = {
    name: bookmark.name,
    url: bookmark.url.replace(/^https?:\/\//i, ''),
  }

  return (
    <BookmarkModal
      visible={visible}
      onCancel={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      title='Edit Bookmark'
      submitButtonText='Update'
    />
  )
}
