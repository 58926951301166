import { useEffect, useState } from 'react'
import { Element } from 'react-scroll'

import { Company } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { UrlParam } from '@/lib/url-param.hook'

import { EmptyInventoryTable } from '@/pages/third-party-inventory/empty-inventory-table'
import { inventoryColumns } from '@/pages/third-party-inventory/inventory-columns'
import { isUrgentCompany } from '@/pages/third-party-inventory/urgent'

import { Table } from '@/components/table/table'

const INVENTORY_PAGE_HEADER_SIZE = 460

export type InventoryTableProps = {
  inventory: Company[]
  onClick?: (thirdPartyId: string) => void
}

export const InventoryTable = ({ inventory, onClick }: InventoryTableProps) => {
  const onRow = ({ id }: Company) => ({
    onClick: () => onClick && id && onClick(id),
  })

  const [tableHight, setTableHight] = useState(window.innerHeight - INVENTORY_PAGE_HEADER_SIZE)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setTableHight(window.innerHeight - INVENTORY_PAGE_HEADER_SIZE)
    })
    return () => window.removeEventListener('resize', () => {})
  }, [setTableHight])

  return (
    <Element name='third-party-inventory-table'>
      <Table
        scroll={{
          y: tableHight,
        }}
        virtual
        narrowFilterBar
        tableViews={{
          selectedViewUrlKey: UrlParam.VIEW,
          viewAccessors: {
            All: ({ status }) =>
              ![CompanyStatus.OFFBOARDED, CompanyStatus.ARCHIVED, CompanyStatus.REJECTED].includes(
                status,
              ),
            'In Assessment': ({ status }) => CompanyStatus.IN_ASSESSMENT === status,
            'Assessment Required': ({ status }) =>
              [CompanyStatus.RE_ASSESSMENT_REQUIRED, CompanyStatus.ASSESSMENT_REQUIRED].includes(
                status,
              ),
            Assessed: ({ status }) => status === CompanyStatus.ASSESSED,
            'Attention Required': isUrgentCompany,
            Unsanctioned: ({ status }) => status === CompanyStatus.UNSANCTIONED,
            Archived: ({ status }) =>
              [CompanyStatus.OFFBOARDED, CompanyStatus.ARCHIVED, CompanyStatus.REJECTED].includes(
                status,
              ),
          },
        }}
        locale={{
          emptyText: <EmptyInventoryTable />,
        }}
        columns={inventoryColumns}
        dataSource={inventory}
        onRow={onRow}
      />
    </Element>
  )
}
