import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'
import { DotIcon, SettingsIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useListControls } from '@/api/control'
import { Control, ControlStatus } from '@/gen/inventory/v1/control_service_pb'

import { calculatePercentage } from '@/lib/math-utils'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { ExportCompany } from '@/pages/company-drawer/export-company'

import { SelectControlStatusBadge } from '@/components/badge/control-status'
import { GroupedTable } from '@/components/table/grouped-table/grouped-table'
import { ColumnType, GroupColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import { Progress } from '@/components/ui/progress'
import { Sentiment } from '@/components/variance-percentage/types'

type ControlCategoryTableProps = {
  companyId: string
}

const controlColumns: ColumnType<PlainMessage<Control>>[] = [
  {
    width: '70%',
    title: 'Control',
    ellipsis: true,
    render: ({ description, title }: PlainMessage<Control>) => (
      <div className='truncate'>
        <span>{title}</span>
        <div className='text-xs'>
          <span className='block truncate'>{description}</span>
        </div>
      </div>
    ),
    accessor: ({ title, description }) => ({
      searchValue: `${title} ${description}`,
      sortValue: title,
    }),
    search: true,
  },
  {
    width: '30%',
    title: 'Validation Status',
    dataIndex: 'status',
    render: (status, { id, title }) => {
      return (
        <SelectControlStatusBadge
          controlId={id}
          status={status}
          extraTrackingProps={{ controlId: id, controlTitle: title, from: 'control.table' }}
        />
      )
    },
    accessor: ({ status }) => ({
      sortValue: status,
    }),
  },
]

export const ControlTable = ({ companyId }: ControlCategoryTableProps) => {
  const { data, isLoading } = useListControls(companyId)
  const navigate = useNavigate()

  const { updateParam } = useUrlParams()

  const activeControls = useMemo(() => data?.filter(({ isEnabled }) => isEnabled), [data])

  const categoryColumns: GroupColumnType<PlainMessage<Control>, 'categoryName'>[] = [
    {
      width: '40%',
      title: 'Category',
      render: (category) => <div className='truncate'>{category}</div>,
      accessor: ({ key }) => ({
        sortValue: key,
      }),
    },
    {
      width: '40%',
      title: 'Third-Party Adherence to Control Category',
      render: (_key, _index, nestedDataSource) => {
        if (!nestedDataSource) return null
        const [totalValidated, total] = nestedDataSource.reduce(
          ([validated, totalCount], { status }) => {
            const newValidated = status === ControlStatus.VALIDATED ? validated + 1 : validated
            return [newValidated, totalCount + 1]
          },
          [0, 0],
        )

        return (
          <div className='flex w-5/6 gap-6'>
            <Progress
              value={calculatePercentage(totalValidated, total)}
              className='h-1.75 self-center'
              sentiment={Sentiment.POSITIVE}
            />
            <span className='text-nowrap'>
              {totalValidated} / {total}
            </span>
          </div>
        )
      },
      sort: false,
    },
    {
      width: '20%',
      title: 'Gaps',
      render: (_key, _index, nestedDataSource) => {
        if (!nestedDataSource) return null
        const failedControlsCount = _.countBy(nestedDataSource, 'status')[ControlStatus.GAP]
        return failedControlsCount ? (
          <div className='flex items-center'>
            <DotIcon className='text-red-500' strokeWidth={'3'} /> {failedControlsCount}
          </div>
        ) : (
          '–'
        )
      },
    },
  ]

  const inactiveControlsCount = data?.length - activeControls.length

  return (
    <div className='flex flex-col'>
      <GroupedTable<PlainMessage<Control>, 'categoryName'>
        tableHeaderActions={
          <div className='flex items-center gap-2'>
            <Button
              className='h-fit px-2 py-1 text-sm shadow-none'
              variant='ghost'
              size='sm'
              data-dd-action-name='third-party.control.settings.view'
              onClick={() => navigate(`settings?${UrlParam.SETTING_SECTION}=controls`)}
            >
              <TextWithIcon
                icon={<SettingsIcon size={14} />}
                text={`${inactiveControlsCount} Inactive ${pluralize('Control', inactiveControlsCount)}`}
              />
            </Button>
            <ExportCompany
              size='sm'
              className='h-fit px-2 py-1 text-sm shadow-none'
              companyId={companyId}
            />
          </div>
        }
        tableViews={{
          selectedViewUrlKey: UrlParam.CONTROL_VIEW,
          viewAccessors: {
            All: () => true,
            Gaps: ({ status }) => status === ControlStatus.GAP,
            Validated: ({ status }) => status === ControlStatus.VALIDATED,
            'No Evidence': ({ status }) => status === ControlStatus.NO_EVIDENCE,
          },
        }}
        dataSource={activeControls}
        toggleGroupModeLabel='Group by Category'
        nestedTableColumns={controlColumns}
        tableColumns={categoryColumns}
        groupByKey='categoryName'
        size='middle'
        scroll={{ y: 'max(calc(100vh - 540px), 250px)' }}
        loading={isLoading}
        nestedTableAntDProps={{
          showHeader: false,
          onRow: ({ id }) => {
            return {
              onClick: () => {
                updateParam(UrlParam.CONTROL_ID, id)
              },
            }
          },
        }}
      />
    </div>
  )
}
