// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file artifacts/store/v1/store_service.proto (package artifacts.store.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { DocumentSource } from "../../../inventory/v1/artifact_service_pb.js";

/**
 * @generated from message artifacts.store.v1.DeleteOrgArtifactRequest
 */
export class DeleteOrgArtifactRequest extends Message<DeleteOrgArtifactRequest> {
  /**
   * @generated from field: string artifact_id = 1;
   */
  artifactId = "";

  constructor(data?: PartialMessage<DeleteOrgArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.DeleteOrgArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrgArtifactRequest {
    return new DeleteOrgArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrgArtifactRequest {
    return new DeleteOrgArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrgArtifactRequest {
    return new DeleteOrgArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrgArtifactRequest | PlainMessage<DeleteOrgArtifactRequest> | undefined, b: DeleteOrgArtifactRequest | PlainMessage<DeleteOrgArtifactRequest> | undefined): boolean {
    return proto3.util.equals(DeleteOrgArtifactRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.DeleteOrgArtifactResponse
 */
export class DeleteOrgArtifactResponse extends Message<DeleteOrgArtifactResponse> {
  constructor(data?: PartialMessage<DeleteOrgArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.DeleteOrgArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrgArtifactResponse {
    return new DeleteOrgArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrgArtifactResponse {
    return new DeleteOrgArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrgArtifactResponse {
    return new DeleteOrgArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrgArtifactResponse | PlainMessage<DeleteOrgArtifactResponse> | undefined, b: DeleteOrgArtifactResponse | PlainMessage<DeleteOrgArtifactResponse> | undefined): boolean {
    return proto3.util.equals(DeleteOrgArtifactResponse, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.GetArtifactPreviewUrlRequest
 */
export class GetArtifactPreviewUrlRequest extends Message<GetArtifactPreviewUrlRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetArtifactPreviewUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.GetArtifactPreviewUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetArtifactPreviewUrlRequest {
    return new GetArtifactPreviewUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetArtifactPreviewUrlRequest {
    return new GetArtifactPreviewUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetArtifactPreviewUrlRequest {
    return new GetArtifactPreviewUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetArtifactPreviewUrlRequest | PlainMessage<GetArtifactPreviewUrlRequest> | undefined, b: GetArtifactPreviewUrlRequest | PlainMessage<GetArtifactPreviewUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetArtifactPreviewUrlRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.GetArtifactPreviewUrlResponse
 */
export class GetArtifactPreviewUrlResponse extends Message<GetArtifactPreviewUrlResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetArtifactPreviewUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.GetArtifactPreviewUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetArtifactPreviewUrlResponse {
    return new GetArtifactPreviewUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetArtifactPreviewUrlResponse {
    return new GetArtifactPreviewUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetArtifactPreviewUrlResponse {
    return new GetArtifactPreviewUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetArtifactPreviewUrlResponse | PlainMessage<GetArtifactPreviewUrlResponse> | undefined, b: GetArtifactPreviewUrlResponse | PlainMessage<GetArtifactPreviewUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetArtifactPreviewUrlResponse, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.GetArtifactRevisionRequest
 */
export class GetArtifactRevisionRequest extends Message<GetArtifactRevisionRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetArtifactRevisionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.GetArtifactRevisionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetArtifactRevisionRequest {
    return new GetArtifactRevisionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetArtifactRevisionRequest {
    return new GetArtifactRevisionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetArtifactRevisionRequest {
    return new GetArtifactRevisionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetArtifactRevisionRequest | PlainMessage<GetArtifactRevisionRequest> | undefined, b: GetArtifactRevisionRequest | PlainMessage<GetArtifactRevisionRequest> | undefined): boolean {
    return proto3.util.equals(GetArtifactRevisionRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.GetArtifactRevisionResponse
 */
export class GetArtifactRevisionResponse extends Message<GetArtifactRevisionResponse> {
  /**
   * @generated from field: bytes file_content = 1;
   */
  fileContent = new Uint8Array(0);

  constructor(data?: PartialMessage<GetArtifactRevisionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.GetArtifactRevisionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetArtifactRevisionResponse {
    return new GetArtifactRevisionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetArtifactRevisionResponse {
    return new GetArtifactRevisionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetArtifactRevisionResponse {
    return new GetArtifactRevisionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetArtifactRevisionResponse | PlainMessage<GetArtifactRevisionResponse> | undefined, b: GetArtifactRevisionResponse | PlainMessage<GetArtifactRevisionResponse> | undefined): boolean {
    return proto3.util.equals(GetArtifactRevisionResponse, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.UploadArtifactRequest
 */
export class UploadArtifactRequest extends Message<UploadArtifactRequest> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = "";

  /**
   * @generated from field: bytes file_content = 2;
   */
  fileContent = new Uint8Array(0);

  /**
   * @generated from field: optional string company_id = 3;
   */
  companyId?: string;

  /**
   * @generated from field: inventory.v1.DocumentSource source = 4;
   */
  source = DocumentSource.UNSPECIFIED;

  /**
   * @generated from field: optional artifacts.store.v1.CategoryMetadata category_metadata = 5;
   */
  categoryMetadata?: CategoryMetadata;

  /**
   * @generated from field: optional bool is_encrypted = 6;
   */
  isEncrypted?: boolean;

  /**
   * @generated from field: optional string password = 7;
   */
  password?: string;

  constructor(data?: PartialMessage<UploadArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.UploadArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "source", kind: "enum", T: proto3.getEnumType(DocumentSource) },
    { no: 5, name: "category_metadata", kind: "message", T: CategoryMetadata, opt: true },
    { no: 6, name: "is_encrypted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadArtifactRequest {
    return new UploadArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadArtifactRequest {
    return new UploadArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadArtifactRequest {
    return new UploadArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadArtifactRequest | PlainMessage<UploadArtifactRequest> | undefined, b: UploadArtifactRequest | PlainMessage<UploadArtifactRequest> | undefined): boolean {
    return proto3.util.equals(UploadArtifactRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.UploadArtifactResponse
 */
export class UploadArtifactResponse extends Message<UploadArtifactResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<UploadArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.UploadArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadArtifactResponse {
    return new UploadArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadArtifactResponse {
    return new UploadArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadArtifactResponse {
    return new UploadArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadArtifactResponse | PlainMessage<UploadArtifactResponse> | undefined, b: UploadArtifactResponse | PlainMessage<UploadArtifactResponse> | undefined): boolean {
    return proto3.util.equals(UploadArtifactResponse, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.UploadArtifactRevisionRequest
 */
export class UploadArtifactRevisionRequest extends Message<UploadArtifactRevisionRequest> {
  /**
   * @generated from field: string artifact_id = 1;
   */
  artifactId = "";

  /**
   * @generated from field: bytes file_content = 2;
   */
  fileContent = new Uint8Array(0);

  constructor(data?: PartialMessage<UploadArtifactRevisionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.UploadArtifactRevisionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadArtifactRevisionRequest {
    return new UploadArtifactRevisionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadArtifactRevisionRequest {
    return new UploadArtifactRevisionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadArtifactRevisionRequest {
    return new UploadArtifactRevisionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadArtifactRevisionRequest | PlainMessage<UploadArtifactRevisionRequest> | undefined, b: UploadArtifactRevisionRequest | PlainMessage<UploadArtifactRevisionRequest> | undefined): boolean {
    return proto3.util.equals(UploadArtifactRevisionRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.UploadArtifactRevisionResponse
 */
export class UploadArtifactRevisionResponse extends Message<UploadArtifactRevisionResponse> {
  constructor(data?: PartialMessage<UploadArtifactRevisionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.UploadArtifactRevisionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadArtifactRevisionResponse {
    return new UploadArtifactRevisionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadArtifactRevisionResponse {
    return new UploadArtifactRevisionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadArtifactRevisionResponse {
    return new UploadArtifactRevisionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadArtifactRevisionResponse | PlainMessage<UploadArtifactRevisionResponse> | undefined, b: UploadArtifactRevisionResponse | PlainMessage<UploadArtifactRevisionResponse> | undefined): boolean {
    return proto3.util.equals(UploadArtifactRevisionResponse, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.CategoryMetadata
 */
export class CategoryMetadata extends Message<CategoryMetadata> {
  /**
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: string subcategory = 2;
   */
  subcategory = "";

  constructor(data?: PartialMessage<CategoryMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.CategoryMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryMetadata | PlainMessage<CategoryMetadata> | undefined, b: CategoryMetadata | PlainMessage<CategoryMetadata> | undefined): boolean {
    return proto3.util.equals(CategoryMetadata, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.RetryArtifactRequest
 */
export class RetryArtifactRequest extends Message<RetryArtifactRequest> {
  /**
   * @generated from field: string artifact_id = 1;
   */
  artifactId = "";

  constructor(data?: PartialMessage<RetryArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.RetryArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetryArtifactRequest {
    return new RetryArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetryArtifactRequest {
    return new RetryArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetryArtifactRequest {
    return new RetryArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RetryArtifactRequest | PlainMessage<RetryArtifactRequest> | undefined, b: RetryArtifactRequest | PlainMessage<RetryArtifactRequest> | undefined): boolean {
    return proto3.util.equals(RetryArtifactRequest, a, b);
  }
}

/**
 * @generated from message artifacts.store.v1.RetryArtifactResponse
 */
export class RetryArtifactResponse extends Message<RetryArtifactResponse> {
  constructor(data?: PartialMessage<RetryArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.store.v1.RetryArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetryArtifactResponse {
    return new RetryArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetryArtifactResponse {
    return new RetryArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetryArtifactResponse {
    return new RetryArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RetryArtifactResponse | PlainMessage<RetryArtifactResponse> | undefined, b: RetryArtifactResponse | PlainMessage<RetryArtifactResponse> | undefined): boolean {
    return proto3.util.equals(RetryArtifactResponse, a, b);
  }
}

