import { SparklesIcon } from 'lucide-react'

import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'

export const Suggested = () => (
  <Badge variant='outline' className='h-3.5 rounded bg-purple-50 p-2.5 text-purple-500'>
    <TextWithIcon iconPosition='end' text='Suggested' icon={<SparklesIcon size={14} />} />
  </Badge>
)
