export enum CompanyDrawerTabs {
  OVERVIEW = 'overview',
  ASSESSMENT = 'assessment',
  FINDINGS = 'findings',
  CONTROLS = 'controls',
  ARTIFACTS = 'artifacts',
  USAGE = 'usage',
  PERMISSIONS = 'permissions',
  ASSETS = 'assets',
}
