import { PlainMessage } from '@bufbuild/protobuf'
import { Divider } from 'antd'
import _ from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { useGetCompany, useGetCompanyDrawerMetadata } from '@/api/company.hook'
import { ControlWithFindings, useFetchCompanyData } from '@/api/export-company.hook'
import { InherentRiskGroup } from '@/gen/inventory/v1/company_service_pb'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'
import { Finding } from '@/gen/inventory/v1/finding_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelPriority } from '@/const/priority'

import { riskLevelToBgClassName } from '@/lib/color'
import { formatDate } from '@/lib/date'
import { cn } from '@/lib/style-helpers'

import { CompanyArtifactTable } from '@/pages/company-drawer/artifact/company-artifact-table'
import { InherentRiskProfile } from '@/pages/company-drawer/company-overview/inherent-risk-profile'
import { CompanyProfile } from '@/pages/company-drawer/company-profile'

import { CompanyRiskBadge } from '@/components/badge/company-risk'
import { ControlStatusBadge } from '@/components/badge/control-status'
import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'
import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { Evidence } from '@/components/evidence'
import { ExportButton } from '@/components/export-button'
import { FindingStatusSymbol } from '@/components/icons/finding-status'
import { PermissionSymbol } from '@/components/icons/permission'
import { RiskLevelIcon, RiskLevelSymbol } from '@/components/icons/risk-severity'
import { PermissionIndicatorList } from '@/components/indicator/permission'
import { LastChanged } from '@/components/last-changed'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'
import { ButtonProps } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

type ExportCompanyProps = {
  companyId: string
} & ButtonProps

export const ExportCompany = ({ companyId, ...props }: ExportCompanyProps) => {
  const { data } = useGetCompany(companyId)
  const getExportContent = useExportCompanyContent(companyId)
  return (
    <ExportButton
      data-dd-action-name='third-party.export'
      documentTitle={data?.company?.profile?.name}
      getExportContent={getExportContent}
      {...props}
    />
  )
}

const useExportCompanyContent = (companyId: string) => {
  let content: ReactNode | null = null
  const [isEnabled, setIsEnabled] = useState(false)

  const {
    loading: companyLoading,
    data: { controlsData, cisoProfile, company, inherentRiskGroups },
  } = useFetchCompanyData(companyId, isEnabled)
  const { data: companyDrawerData, isLoading: companyDrawerLoading } =
    useGetCompanyDrawerMetadata(companyId)

  const loading = companyLoading || companyDrawerLoading

  const controlsByCategory = useMemo(() => {
    return _.groupBy(controlsData, (control) => control.control?.categoryName)
  }, [controlsData])

  if (!loading && company && company.company?.profile) {
    const { profile, status, statusLastUpdate, risk, findingsByLevel } = company.company

    content = (
      <>
        <div className='mb-10 bg-gray-50 py-10'>
          <div className='mx-8'>
            <div className='grid w-fit grid-cols-[min-content,auto] items-center gap-x-20 gap-y-4.5'>
              <h2 className='text-nowrap text-3xl font-bold'>{profile.name}</h2>
              <div className='flex items-center gap-2'>
                <CompanyVerificationBadge className='pl-0' status={status} />
                {statusLastUpdate && (
                  <LastChanged withTimeCount={false} lastChanged={statusLastUpdate.toDate()} />
                )}
              </div>
              <span className='text-sm font-bold'>INHERENT RISK</span>
              <CompanyRiskBadge riskLevel={risk} />
              <span className='text-sm font-bold'>DESCRIPTION</span>
              <p>{profile.longDescription}</p>
              <TextWithIcon
                className='text-sm font-bold uppercase'
                text='Findings'
                icon={<FindingStatusSymbol />}
              />
              <FindingDistribution findingCountByLevel={findingsByLevel} />
              <TextWithIcon
                className='text-sm font-bold uppercase'
                text='Permissions'
                icon={<PermissionSymbol />}
              />
              <PermissionIndicatorList
                permissions={
                  companyDrawerData?.permissions.flatMap(({ category }) => category) ?? []
                }
              />
            </div>
          </div>
          <Separator className='my-12' />
          <div className='mx-8'>
            <CompanyProfile info={profile} ciso={cisoProfile} />
          </div>
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <div className='mx-8 flex max-h-72 items-center'>
          <InherentRiskProfile
            companyRisk={risk}
            showEditButton={false}
            inherentRiskGroups={inherentRiskGroups}
          />
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <Divider orientation='left'>
          <div className='text-2xl'>Third-Party Artifacts</div>
        </Divider>
        <div className='mx-8'>
          <CompanyArtifactTable isExport companyId={companyId} />
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <Divider orientation='left'>
          <div className='text-2xl'>Third Party Controls & Findings</div>
        </Divider>
        <div className='mx-8'>
          {Object.entries(controlsByCategory).map(([category, controls]) => (
            <div key={category} className='mb-28 break-after-page last:break-after-avoid'>
              <div className='mt-4 rounded bg-gray-50 px-10 py-5 font-bold uppercase'>
                <span className='text-xs'>CONTROL CATEGORY</span>
                <h3 className='text-xl'>{category}</h3>
              </div>
              <div className='mt-6 flex flex-col gap-6'>
                {controls.map((controlWithFinding) => (
                  <div key={controlWithFinding.control?.id}>
                    <div className='mb-2 flex items-center space-x-1'>
                      <span className='shrink-0 text-lg font-bold'>
                        {controlWithFinding.control?.title}
                      </span>
                      <span className='shrink-0'>-</span>
                      <span className='truncate text-gray-700'>
                        {controlWithFinding.control?.description}
                      </span>
                    </div>

                    <div className='flex rounded border'>
                      <div className='flex w-fit flex-col gap-2 border-r p-4'>
                        <span className='text-nowrap'>Validation Status</span>
                        <ControlStatusBadge
                          status={controlWithFinding.control?.status || ControlStatus.VALIDATED}
                        />
                      </div>
                      <div className='w-full p-6'>
                        <ControlEvidence controlWithFinding={controlWithFinding} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  return () => ({
    exportContent: content,
    isLoaded: !loading,
    renderContent: () => setIsEnabled(true),
  })
}

const ControlEvidence = ({ controlWithFinding }: { controlWithFinding: ControlWithFindings }) => {
  return (
    <div>
      {controlWithFinding.control?.status === ControlStatus.GAP &&
        controlWithFinding.findings.length > 0 && (
          <div key={controlWithFinding.findings[0].finding?.id}>
            <span className='text-xs'>Finding</span>
            <div className='mb-8 text-lg font-bold'>
              {controlWithFinding.findings[0].finding?.title}
            </div>
            <StatisticsRow
              items={[
                {
                  title: 'Severity',
                  icon: <RiskLevelSymbol />,
                  children: controlWithFinding.findings[0].finding?.riskLevel && (
                    <FindingSeverityBadge
                      level={controlWithFinding.findings.reduce((acc, { finding }) => {
                        if (!finding?.riskLevel) {
                          return acc
                        }

                        if (acc === RiskLevel.UNSPECIFIED) {
                          return finding.riskLevel
                        }

                        return riskLevelPriority[finding.riskLevel] < riskLevelPriority[acc]
                          ? finding.riskLevel
                          : acc
                      }, RiskLevel.UNSPECIFIED as RiskLevel)}
                    />
                  ),
                },
              ]}
            />
            <div className='mt-7'>
              <div className='my-3'>
                <span className='font-bold'>Description:</span>
                <span>{controlWithFinding.findings[0].documentation?.description}</span>
              </div>
              <div className='my-3'>
                <span className='font-bold'>What is the Risk?</span>
                <span>{controlWithFinding.findings[0].documentation?.risk}</span>
              </div>
            </div>
            <InstanceFindingTable
              findings={
                controlWithFinding.findings.map((f) => f.finding).filter(Boolean) as Finding[]
              }
            />
          </div>
        )}
      {controlWithFinding.findings.length === 0 && !controlWithFinding.factMarkdown && (
        <div className='flex h-full items-center leading-loose'>
          Lema has not found any indication that this control is violated. Lema is continuing to
          monitor it.
        </div>
      )}
      <Evidence factMarkdown={controlWithFinding.factMarkdown} defaultOpen />
    </div>
  )
}

export const InherentRiskExport = ({
  inherentRiskGroups,
}: {
  inherentRiskGroups: PlainMessage<InherentRiskGroup>[]
}) => {
  return (
    <div className='block'>
      <div className='grid grid-cols-2 gap-2'>
        {inherentRiskGroups.map((group) => (
          <div key={group.categoryEnum} className='rounded border'>
            <div
              key={group.categoryEnum}
              className='flex w-fit px-4 py-2 text-center text-lg font-medium'
            >
              {group.category}
              {group.subcategory && ` - ${group.subcategory}`}
            </div>
            <div className='flex flex-wrap gap-2 p-2'>
              {group.inherentRiskItems.map((item) => (
                <div
                  key={item.id}
                  className={cn(
                    'flex items-center rounded px-2 py-1',
                    riskLevelToBgClassName[item.severity],
                  )}
                >
                  <span className='mr-1'>
                    {item.severity && <RiskLevelIcon variant='inherit' level={item.severity} />}
                  </span>

                  {item.displayName}
                </div>
              ))}
              {group.inherentRiskItems.length === 0 && <div className='px-4 py-2'>No items</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

type InstanceFindingTableProps = {
  findings: Finding[]
}

const InstanceFindingTable = ({ findings }: InstanceFindingTableProps) => {
  const columns: ColumnType<Finding>[] = [
    {
      title: 'Instance',
      dataIndex: 'subtitle',
    },
    {
      title: 'Work Status',
      dataIndex: 'status',
      render: (status) => <FindingStatusBadge status={status} />,
    },
    {
      title: 'Detection Time',
      dataIndex: 'detectionTime',
      render: (detectionTime) => detectionTime && formatDate(detectionTime.toDate(), 'dateTime'),
    },
  ]
  return <Table showFilterBar={false} columns={columns} dataSource={findings} />
}
