import { Loader2Icon } from 'lucide-react'
import { useRef, useState } from 'react'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { AssessmentFlowStep } from '@/pages/assessment/assessment-flow-step-enum'
import { CompanyArtifactTable } from '@/pages/company-drawer/artifact/company-artifact-table'
import { UploadFiles, UploadFilesRef } from '@/pages/settings-page/artifacts-section/upload-files'

import { Button } from '@/components/ui/button'

type EvidenceCollectionProps = {
  companyId: string
}

export const EvidenceCollection = ({ companyId }: EvidenceCollectionProps) => {
  const uploadRef = useRef<UploadFilesRef>(null)
  const { updateParam } = useUrlParams()
  const [isUploadEnabled, setIsUploadEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleUpload = async () => {
    setIsLoading(true)
    try {
      await uploadRef.current?.handleUpload(companyId)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mb-8'>
      <h4 className='mb-3 text-lg font-semibold'>Evidence Collection</h4>
      <UploadFiles
        setIsUploadEnabled={setIsUploadEnabled}
        hideTitle
        ref={uploadRef}
        metadata={{
          company: {
            id: companyId,
          },
        }}
      />
      <Button
        className='float-end mt-4'
        disabled={!isUploadEnabled || isLoading}
        onClick={handleUpload}
      >
        {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
        Upload
      </Button>
      <h4 className='mt-10 text-lg font-semibold'>Third-Party Artifacts</h4>
      <CompanyArtifactTable companyId={companyId} showFilterBar={false} />
      <Button
        className='float-end mt-4'
        onClick={() => {
          updateParam(UrlParam.STEP, AssessmentFlowStep.CONTROLS)
        }}
      >
        Continue
      </Button>
    </div>
  )
}
