import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  ExternalLinkIcon,
  InfoIcon,
  LinkIcon,
  PlusIcon,
  Trash2Icon,
} from 'lucide-react'
import React, { useState } from 'react'

import { useDeleteBookmark, useGetBookmarks } from '@/api/bookmark.hook'
import { Bookmark } from '@/gen/inventory/v1/company_service_pb'

import { AddBookmarkModal } from '@/components/bookmark/add-bookmark-modal'
import { EditBookmarkModal } from '@/components/bookmark/edit-bookmark-modal'
import { Dropdown } from '@/components/dropdown/dropdown'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

type BookmarksRowProps = {
  companyId: string
}

export const BookmarksRow = ({ companyId }: BookmarksRowProps) => {
  const { data: bookmarks = [] } = useGetBookmarks(companyId)
  const { mutateAsync: deleteBookmark } = useDeleteBookmark(companyId)
  const { toast } = useToast()

  const [bookmarksExpanded, setBookmarksExpanded] = useState(false)

  const [isAddModalVisible, setAddModalVisible] = useState(false)
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [selectedBookmark, setSelectedBookmark] = useState<Bookmark | null>(null)

  const openAddModal = () => setAddModalVisible(true)
  const closeAddModal = () => setAddModalVisible(false)

  const openEditModal = (bookmark: Bookmark) => {
    setSelectedBookmark(bookmark)
    setEditModalVisible(true)
  }
  const closeEditModal = () => {
    setSelectedBookmark(null)
    setEditModalVisible(false)
  }

  const COLLAPSED_BOOKMARKS_COUNT = 6

  const displayedBookmarks = bookmarksExpanded
    ? bookmarks
    : bookmarks.slice(0, COLLAPSED_BOOKMARKS_COUNT)

  const handleEditBookmark = (bookmark: Bookmark) => {
    openEditModal(bookmark)
  }

  const handleDeleteBookmark = async (bookmark: Bookmark) => {
    try {
      await deleteBookmark({ bookmarkId: bookmark.id })
      toast({
        status: 'success',
        title: 'Bookmark deleted',
      })
    } catch (error) {
      console.error('Error deleting bookmark:', error)
      toast({
        status: 'error',
        title: 'Failed to delete bookmark',
      })
    }
  }

  return (
    <div className='flex flex-wrap items-center gap-1.5'>
      {displayedBookmarks.map((bookmark) => (
        <React.Fragment key={bookmark.id}>
          <Dropdown
            triggerContent={
              <Tooltip
                trigger={
                  <Button
                    onClick={() => window.open(bookmark.url, '_blank')}
                    className='h-6 gap-1.5 rounded-tl bg-gray-100 px-2.5 py-0 text-sm font-light leading-5 transition duration-100 ease-out hover:bg-gray-200'
                    variant='ghost'
                    size='sm'
                  >
                    {bookmark.faviconUrl ? (
                      <img
                        src={bookmark.faviconUrl}
                        alt={bookmark.name}
                        className='size-3.5 object-contain opacity-75'
                      />
                    ) : (
                      <LinkIcon size={14} className='opacity-75' />
                    )}
                    <span className='max-w-32 truncate text-left text-sm font-light leading-5'>
                      {bookmark.name}
                    </span>
                  </Button>
                }
              >
                <div className='px-3 py-1'>
                  <div className='mb-2 flex items-center'>
                    <ExternalLinkIcon className='mr-2 size-4' />
                    <span className='max-w-48 truncate text-sm'>{bookmark.url}</span>
                  </div>
                  <div className='text-center'>
                    <span className='font-bold'>Right click</span> for options
                  </div>
                </div>
              </Tooltip>
            }
            menuItems={[
              {
                content: 'Edit Bookmark',
                onClick: () => handleEditBookmark(bookmark),
                icon: <EditIcon size={14} />,
              },
              {
                content: <span className='text-red-500'>Delete Bookmark</span>,
                onClick: () => handleDeleteBookmark(bookmark),
                icon: <Trash2Icon size={14} className='text-red-500' />,
              },
            ]}
            variant='secondary'
            triggerAppearance='headless'
            openOnRightClick={true}
          />
        </React.Fragment>
      ))}

      {bookmarks.length > COLLAPSED_BOOKMARKS_COUNT && (
        <Button
          onClick={() => setBookmarksExpanded(!bookmarksExpanded)}
          className='flex items-center gap-1.5 px-3 text-sm font-semibold text-gray-500 no-underline transition-opacity duration-100 ease-out hover:no-underline hover:opacity-50'
          variant='link'
        >
          {bookmarksExpanded ? (
            <>
              <ChevronUpIcon size={12} />
              <span className='text-sm'>Collapse Bookmarks</span>
            </>
          ) : (
            <>
              <ChevronDownIcon size={12} />
              <span className='text-sm'>Show All Bookmarks</span>
            </>
          )}
        </Button>
      )}

      {(bookmarks.length <= COLLAPSED_BOOKMARKS_COUNT || bookmarksExpanded) && (
        <Button
          onClick={openAddModal}
          variant='link'
          className='group flex items-center justify-start gap-1.5 p-0 text-sm font-medium text-gray-500 no-underline transition-opacity duration-100 ease-out hover:no-underline'
        >
          <PlusIcon className='group-hover:opacity-50' size={12} />
          <span className='group-hover:opacity-50'>Add a Bookmark</span>
          <Tooltip trigger={<InfoIcon size={12} />}>
            <div className='max-w-xs px-3 py-1 text-center'>
              Bookmarks let you quickly access your favorite tools and take you directly to the
              specific details you need.
            </div>
          </Tooltip>
        </Button>
      )}

      <AddBookmarkModal companyId={companyId} visible={isAddModalVisible} onClose={closeAddModal} />

      {selectedBookmark && (
        <EditBookmarkModal
          companyId={companyId}
          bookmark={selectedBookmark}
          visible={isEditModalVisible}
          onClose={closeEditModal}
        />
      )}
    </div>
  )
}
