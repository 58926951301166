import { PlainMessage } from '@bufbuild/protobuf'
import { BarChart4Icon, FlagIcon, LinkIcon, UsersIcon } from 'lucide-react'
import ShowMoreText from 'react-show-more-text'

import {
  CompanyProfile as ProtoCompanyProfile,
  PersonProfile as ProtoPersonProfile,
} from '@/gen/inventory/v1/company_service_pb'

import { cn } from '@/lib/style-helpers'

import { PersonResumeCard } from '@/pages/company-drawer/person-resume-card'

import { Hyperlink } from '@/components/hyperlink'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'

export type CompanyProfileProps = {
  info: PlainMessage<ProtoCompanyProfile>
  ciso?: PlainMessage<ProtoPersonProfile>
}

export const CompanyProfile = ({ info, ciso }: CompanyProfileProps) => {
  return (
    <div className='min-w-96 p-6'>
      <h3 className='text-xl font-bold'>Company Profile</h3>
      <ShowMoreText
        anchorClass='cursor-pointer hover:underline'
        className='mb-8 mt-4 max-w-4xl'
        lines={4}
      >
        {info.paragraphDescription}
      </ShowMoreText>
      <div className='flex max-w-4xl flex-wrap justify-items-stretch gap-2'>
        <CompanyStatisticItem title='Industry' disabled={!info.industrySector}>
          <span className='line-clamp-1 capitalize'>{info.industrySector}</span>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Website'
          icon={<LinkIcon size={10} />}
          disabled={!info.website}
        >
          <Hyperlink href={`https://${info.website}`}>{info.website}</Hyperlink>
        </CompanyStatisticItem>
        <CompanyStatisticItem title='CISO' disabled={!ciso}>
          <HoverCard openDelay={300}>
            <HoverCardTrigger className='truncate capitalize'>{ciso?.name}</HoverCardTrigger>
            <HoverCardContent className='min-w-96'>
              {ciso && <PersonResumeCard person={ciso} workExperiencesLimit={4} />}
            </HoverCardContent>
          </HoverCard>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Linkedin'
          icon={<LinkIcon size={10} />}
          disabled={!info.linkedinUrl}
        >
          <Hyperlink className='line-clamp-1' href={`https://${info.linkedinUrl}`}>
            {info.name}
          </Hyperlink>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Size'
          icon={<UsersIcon size={10} />}
          disabled={!info.companySize}
        >
          {info.companySize}
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Founded'
          icon={<FlagIcon size={10} />}
          disabled={!info.foundingYear}
        >
          {info.foundingYear}
        </CompanyStatisticItem>

        <CompanyStatisticItem
          title='Stocks'
          icon={<BarChart4Icon size={10} />}
          disabled={!info.ticker}
        >
          <Hyperlink href={`https://finance.yahoo.com/quote/${info.ticker}`}>
            {info.ticker}
          </Hyperlink>
        </CompanyStatisticItem>
      </div>
    </div>
  )
}

const CompanyStatisticItem = ({
  title,
  icon,
  children,
  disabled,
}: {
  title: string
  icon?: React.ReactNode
  children?: React.ReactNode
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(
        'flex w-36 flex-col items-start gap-1 p-2',
        disabled && 'select-none opacity-60',
      )}
    >
      <div className='flex flex-row items-center'>
        {icon && <span className='mr-1'>{icon}</span>}
        <h3 className='text-xs font-bold uppercase tracking-widest text-gray-500'>{title}</h3>
      </div>
      <div className='line-clamp-1 inline-flex overflow-hidden'>
        {!disabled && children}
        {disabled && <>N/A</>}
      </div>
    </div>
  )
}
