import { CheckIcon, SparklesIcon } from 'lucide-react'
import { useMemo } from 'react'

import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import { IRQChangeType } from '@/gen/inventory/v1/company_service_pb'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

type FillIrqSuggestedButtonProps = {
  companyId: string
}

export const FillIrqSuggestedButton = ({ companyId }: FillIrqSuggestedButtonProps) => {
  const { isEnabled: isFillSuggestedEnabled } = useFeatureFlagEnabled('irq-fill-suggested')
  const { mutateAsync: setIRQItem } = useOptimisticSetIRQItem(companyId)
  const { irqItems, lemaItems } = useInherentRiskItems(companyId)
  const hasSuggestedItems = useMemo(() => {
    const flatIrqItems = irqItems.flatMap(({ inherentRiskItems }) => inherentRiskItems)
    const flatLemaItems = lemaItems.flatMap(({ inherentRiskItems }) => inherentRiskItems)
    return flatLemaItems.some((item) => !flatIrqItems.some((lemaItem) => lemaItem.id === item.id))
  }, [irqItems, lemaItems])

  if (!isFillSuggestedEnabled) {
    return null
  }
  return (
    <Button
      className='h-fit px-2 py-1 text-sm'
      size={'sm'}
      disabled={!hasSuggestedItems}
      onClick={() => {
        lemaItems.forEach(({ inherentRiskItems, categoryEnum }) => {
          inherentRiskItems.forEach(({ id }) => {
            setIRQItem({
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
              companyId,
              riskCategoryId: id,
              category: categoryEnum,
            })
          })
        })
      }}
      variant={'outline'}
    >
      <TextWithIcon
        icon={hasSuggestedItems ? <SparklesIcon size={14} /> : <CheckIcon size={14} />}
        text='Fill Suggested'
      />
    </Button>
  )
}
