import { createPromiseClient } from '@connectrpc/connect'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useGetCompany, useGetCompanyCISOProfile, useGetInherentRisk } from '@/api/company.hook'
import { useListControls } from '@/api/control'
import { ControlInventoryService } from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'
import { Control } from '@/gen/inventory/v1/control_service_pb'
import { GetControlResponse } from '@/gen/inventory/v1/control_service_pb'
import { FindingInventoryService } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'
import { GetFindingResponse } from '@/gen/inventory/v1/finding_service_pb'

import { sessionTokenInterceptor } from '@/lib/auth/session-token-interceptor'
import { getConnectTransport } from '@/lib/connect-transport'

const buildMode = import.meta.env.MODE

const controlInventoryClient = createPromiseClient(
  ControlInventoryService,
  getConnectTransport({
    interceptors: buildMode === 'production' ? [] : [sessionTokenInterceptor],
  }),
)

const findingInventoryClient = createPromiseClient(
  FindingInventoryService,
  getConnectTransport({
    interceptors: buildMode === 'production' ? [] : [sessionTokenInterceptor],
  }),
)

export const ControlDataQueryKey = 'controlsData'

export type ControlWithFindings = GetControlResponse & { findings: GetFindingResponse[] }

export const useFetchAllControls = (companyId: string, controls: Control[], enabled: boolean) => {
  const query = useQuery(
    [ControlDataQueryKey, companyId],
    async () => {
      if (controls.length === 0) {
        return []
      }

      const controlsResponses = await Promise.allSettled(
        controls.map((control) =>
          controlInventoryClient.getControl({
            controlId: control.id,
          }),
        ),
      )

      const successfulControls = controlsResponses
        .filter(
          (result): result is PromiseFulfilledResult<GetControlResponse> =>
            result.status === 'fulfilled',
        )
        .map((result) => result.value)

      const controlWithFindingResponses = await Promise.allSettled(
        successfulControls.map(async (control) => {
          const findingResponses = await Promise.allSettled(
            (control.findingIds || []).map((id) =>
              findingInventoryClient.getFinding({
                id,
                excludeInternalLinks: true,
              }),
            ),
          )

          const successfulFindings = findingResponses
            .filter(
              (result): result is PromiseFulfilledResult<GetFindingResponse> =>
                result.status === 'fulfilled',
            )
            .map((result) => result.value)

          return { ...control, findings: successfulFindings }
        }),
      )

      return controlWithFindingResponses
        .filter(
          (result): result is PromiseFulfilledResult<ControlWithFindings> =>
            result.status === 'fulfilled',
        )
        .map((result) => result.value)
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export const useFetchCompanyData = (companyId: string, enabled: boolean) => {
  const {
    data: company,
    isFetching: companyLoading,
    isError: companyError,
  } = useGetCompany(companyId, enabled)
  const { data: cisoProfile, isFetching: cisoProfileLoading } = useGetCompanyCISOProfile(
    companyId,
    enabled,
  )
  const { data: inherentRiskGroups, isFetching: inherentRiskGroupsLoading } = useGetInherentRisk(
    { companyId },
    enabled,
  )
  const { data: controls, isFetching: controlsLoading } = useListControls(companyId, enabled)

  const activeControls = useMemo(
    () => controls?.filter(({ isEnabled }) => isEnabled) || [],
    [controls],
  )
  const { data: controlsData, isFetching: controlsDataLoading } = useFetchAllControls(
    companyId,
    activeControls,
    enabled,
  )

  const loading =
    companyLoading ||
    cisoProfileLoading ||
    inherentRiskGroupsLoading ||
    controlsLoading ||
    controlsDataLoading
  const isError = companyError

  return {
    loading,
    isError,
    data: {
      company,
      cisoProfile,
      inherentRiskGroups,
      controlsData,
    },
  }
}
