import { ClipboardIcon, Loader2Icon } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

import { useGetCompany, useGetCompanyDrawerMetadata, useGetInherentRisk } from '@/api/company.hook'
import { useListControls } from '@/api/control'
import { useListFindings } from '@/api/findings.hook'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { useReportErrorsCallback } from '@/lib/error-reporting'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { CompanyAssessmentFlow } from '@/pages/assessment/assessment-flow'
import { StartAssessment } from '@/pages/assessment/start-assessment'
import { ArtifactSection } from '@/pages/company-drawer/artifact/artifact-section'
import { AssetsByCountryTable } from '@/pages/company-drawer/assets-by-country-table'
import { CompanyDrawerTabs } from '@/pages/company-drawer/company-drawer-tabs-enum'
import { CompanyOverview } from '@/pages/company-drawer/company-overview/company-overview'
import { ControlTable } from '@/pages/control/control-table'
import { CompanyNotFound } from '@/pages/error-pages/company-not-found'

import { Loading } from '@/components/loading'
import { HorizontalTabs } from '@/components/tabs/horizontal'
import { TextWithIcon } from '@/components/text-with-icon'
import { useToast } from '@/components/ui/use-toast'

import { FindingTable } from './finding-table'
import { ThirdPartyDetailsHeader } from './header'
import { PermissionsTab } from './permissions-tab'
import { ThirdPartyUsage } from './third-party-usage'

import './antd-drawer.less'

type ThirdPartyDetailsFragmentProps = {
  companyId: string
}

export const CompanyDrawer = ({ companyId }: ThirdPartyDetailsFragmentProps) => {
  const [selectedSolutionId, setSelectedSolutionId] = useState<string>()

  const {
    data: companyResponse,
    isLoading: companyLoading,
    error: tpCompanyError,
  } = useGetCompany(companyId)
  const {
    data: findingsData,
    isLoading: findingsLoading,
    error: findingsError,
  } = useListFindings({ companyId, solutionId: selectedSolutionId })
  const findings = useMemo(() => findingsData?.findings || [], [findingsData])
  const {
    data: solutionMetadata,
    isLoading: solutionMetadataLoading,
    error: solutionMetadataError,
  } = useGetCompanyDrawerMetadata(companyId, selectedSolutionId)
  // by using another query we avoid flickering when switching between solutions
  const { data: companyMetadata, error: companyMetadataError } = useGetCompanyDrawerMetadata(
    companyId,
    undefined,
    true,
  )
  const {
    data: inherentRiskGroups,
    isLoading: inherentRiskLoading,
    error: inherentRiskError,
  } = useGetInherentRisk({ companyId })

  const { data: controlData, isLoading: controlLoading } = useListControls(companyId)

  const { updateParam } = useUrlParams()

  const { isEnabled: permissionsEnabled } = useFeatureFlagEnabled('tpcompany-permissions-table')
  const permissionsTableEnabled =
    permissionsEnabled && (solutionMetadata?.permissions?.length ?? 0) > 0

  const tryReportErrors = useReportErrorsCallback()
  tryReportErrors(tpCompanyError, findingsError, solutionMetadataError, inherentRiskError)

  useErrorToast(tpCompanyError !== null, 'Error loading company')
  useErrorToast(findingsError !== null, 'Error loading findings')
  useErrorToast(solutionMetadataError !== null, 'Error loading company assets and usage')
  useErrorToast(inherentRiskError !== null, 'Error loading inherent risk')
  useErrorToast(companyMetadataError !== null, 'Error loading company metadata')

  const trackViewThirdParty = useTrackCallback('third-party.view')

  useEffect(() => {
    if (companyLoading) {
      return
    }
    trackViewThirdParty({
      companyId,
      company: companyResponse?.company?.profile?.name,
    })
  }, [companyId, companyResponse?.company?.profile?.name, trackViewThirdParty, companyLoading])

  const totalUnresolvedFindings = useMemo(() => {
    return findings.filter(
      ({ status }) => status === FindingStatus.ONGOING || status === FindingStatus.OPEN,
    ).length
  }, [findings])

  const totalUnresolvedControls = useMemo(() => {
    return (
      controlData?.filter(
        ({ status, isEnabled }) => isEnabled && status !== ControlStatus.VALIDATED,
      ).length ?? 0
    )
  }, [controlData])

  if (companyLoading) {
    return <Loading />
  }

  const company = companyResponse?.company
  if (tpCompanyError || !company || !company?.profile) {
    return <CompanyNotFound />
  }

  const selectedSolutionName = solutionMetadata?.solutions.find(
    ({ id }) => selectedSolutionId === id,
  )?.name
  const titleSuffix = selectedSolutionName && ` - ${selectedSolutionName}`

  return (
    <div className='pb-13'>
      <div className='bg-gray-50 px-9 pb-4 pt-8'>
        <ThirdPartyDetailsHeader
          sources={company.sources}
          companyProfile={company.profile}
          description={company.profile.longDescription}
          selectedSolution={selectedSolutionId}
          setSelectedSolution={setSelectedSolutionId}
          companyId={companyId}
          companyRisk={company.risk}
          status={{
            status: company.status,
            lastChanged: company.statusLastUpdate?.toDate(),
          }}
          permissions={companyMetadata?.permissions.flatMap(({ category }) => category) ?? []}
          solutions={companyMetadata?.solutions ?? []}
          findingCount={company.findingsByLevel}
          spend={company.spend}
        />
      </div>
      <HorizontalTabs
        urlParamKey={UrlParam.COMPANY_DRAWER_TAB}
        isLoading={findingsLoading || inherentRiskLoading || solutionMetadataLoading}
        className='border-t'
        items={[
          {
            index: CompanyDrawerTabs.OVERVIEW,
            label: 'Overview',
            component: (
              <CompanyOverview
                companyId={companyId}
                companyProfile={company.profile}
                inherentRiskGroups={inherentRiskGroups}
                isLoading={inherentRiskLoading}
                companyRisk={company.risk}
              />
            ),
          },
          {
            index: CompanyDrawerTabs.ASSESSMENT,
            label: 'Assessment',
            component:
              company.status === CompanyStatus.IN_ASSESSMENT ? (
                <CompanyAssessmentFlow companyId={companyId} />
              ) : (
                <StartAssessment companyId={companyId} />
              ),
          },
          {
            index: CompanyDrawerTabs.FINDINGS,
            label: (
              <div className='flex gap-1.5'>
                <TextWithIcon text='Findings' icon={<ClipboardIcon size={15} />} />
                <span>{titleSuffix}</span>
                <div className='rounded border border-gray-200 bg-gray-100 px-1.5 text-gray-500'>
                  {findingsLoading ? (
                    <Loader2Icon className='size-4 animate-spin' />
                  ) : (
                    totalUnresolvedFindings
                  )}
                </div>
              </div>
            ),
            component: (
              <FindingTable
                loading={findingsLoading}
                dataSource={findings}
                onClick={(id) => updateParam(UrlParam.FINDING_ID, id.toString())}
              />
            ),
          },
          {
            index: CompanyDrawerTabs.CONTROLS,
            label: (
              <div className='flex gap-1.5'>
                <TextWithIcon text='Controls' icon={<ClipboardIcon size={15} />} />
                <div className='rounded border border-gray-200 bg-gray-100 px-1.5 text-gray-500'>
                  {controlLoading ? (
                    <Loader2Icon className='size-4 animate-spin' />
                  ) : (
                    totalUnresolvedControls
                  )}
                </div>
              </div>
            ),
            component: <ControlTable companyId={companyId} />,
          },
          'separator',
          {
            index: CompanyDrawerTabs.ARTIFACTS,
            label: 'Artifacts',
            component: <ArtifactSection companyName={company.profile.name} companyId={companyId} />,
          },
          {
            index: CompanyDrawerTabs.USAGE,
            label: 'Usage',
            component: (
              <ThirdPartyUsage
                titleSuffix={titleSuffix}
                numUsers={solutionMetadata?.numUsers}
                numUsersByOrganizationUnit={solutionMetadata?.numUsersByOrganizationUnit}
                dataTypes={solutionMetadata?.dataTypes}
                users={solutionMetadata?.users}
              />
            ),
          },
          {
            index: CompanyDrawerTabs.PERMISSIONS,
            label: 'Permissions',
            itemDisabled: !permissionsTableEnabled,
            component: permissionsTableEnabled && (
              <PermissionsTab
                titleSuffix={titleSuffix}
                permissions={solutionMetadata?.permissions || []}
                loading={solutionMetadataLoading}
              />
            ),
          },
          {
            index: CompanyDrawerTabs.ASSETS,
            label: 'Assets',
            component: solutionMetadata?.assets ? (
              <div>
                <h2 className='mb-6 text-xl font-bold'>Assets {titleSuffix}</h2>{' '}
                <AssetsByCountryTable dataSource={solutionMetadata.assets} />
              </div>
            ) : (
              <></>
            ),
          },
        ]}
      />
    </div>
  )
}

const useErrorToast = (shouldToast: boolean, message: string) => {
  const { toast } = useToast()

  useEffect(() => {
    if (shouldToast) {
      toast({
        status: 'error',
        title: message,
      })
    }
  }, [message, shouldToast, toast])
}
