import _ from 'lodash'
import { useMemo } from 'react'

import { useGetCompany, useListInherentRiskCategories } from '@/api/company.hook'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { InherentRiskCategoryEnumName } from '@/const/label'

import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

import { FillIrqSuggestedButton } from '@/pages/assessment/irq/fill-irq-suggested-button'
import { useSelectInherentRiskModal } from '@/pages/assessment/irq/use-select-inherent-risk-modal.hook'
import { InherentRiskCategoriesSummary } from '@/pages/company-drawer/company-overview/inherent-risk-categories-summary'
import { InherentRiskChart } from '@/pages/company-drawer/company-overview/inherent-risk-chart'

import { Suggested } from '@/components/badge/suggested'
import { Irq } from '@/components/irq'
import { Loading } from '@/components/loading'
import { Button } from '@/components/ui/button'

type IrqAndInherentRiskProfileProps = {
  companyId: string
}

export const IrqAndInherentRiskProfile = ({ companyId }: IrqAndInherentRiskProfileProps) => {
  const { irqItems } = useInherentRiskItems(companyId)
  const { renderModal, showModal } = useSelectInherentRiskModal(companyId)
  const { data: company } = useGetCompany(companyId)
  const {
    data: inherentRiskCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useListInherentRiskCategories(companyId)

  const chart = useMemo(() => {
    const maxByCategory: Record<string, number> = {}

    if (!inherentRiskCategories) return []
    for (const { categoryEnum, inherentRiskItems } of irqItems) {
      const categoryLabel = InherentRiskCategoryEnumName[categoryEnum]
      const max = _.max(inherentRiskItems.map(({ severity }) => severity)) || 0

      // Note: it is possible for a category to have multiple sub categories
      // we need to take the max of all sub categories
      if (maxByCategory[categoryLabel] === undefined || maxByCategory[categoryLabel] < max) {
        maxByCategory[categoryLabel] = max
      }
    }

    return Object.entries(maxByCategory).map(([x, y]) => ({ x, y }))
  }, [inherentRiskCategories, irqItems])

  if (categoriesLoading) return <Loading />
  if (categoriesError) return <div>Error loading categories</div>

  return (
    <div className='mb-10'>
      <h3 className='mb-5 text-xl font-bold'>IRQ - Inherent Risk Questionnaire</h3>
      <div className='flex justify-between gap-12 rounded'>
        <div className='w-2/3 rounded border p-5'>
          <div className='leading-8'>
            <div className='flex items-center justify-between'>
              <h4 className='truncate font-bold'>
                Fill an Inherent Risk Questionnaire (IRQ) for this Third-Party
              </h4>
              <FillIrqSuggestedButton companyId={companyId} />
            </div>
            <div className='flex items-center gap-2'>
              <Suggested />
              <span className='truncate text-xs'>
                Lema suggests items based on data on this third-party from open-source feeds
              </span>
            </div>
          </div>
          <div>
            <Irq companyId={companyId} />
          </div>
        </div>
        <div className='sticky top-8 h-fit rounded border p-5'>
          <h4 className='text-center text-xs font-bold uppercase leading-10 tracking-widest text-gray-500'>
            Inherent risk profile
          </h4>
          <div className='flex flex-col items-center'>
            <InherentRiskChart
              chart={chart}
              companyRisk={company?.company?.risk || RiskLevel.LOW}
            />

            <div className='mt-6'>
              <InherentRiskCategoriesSummary inherentRiskGroups={irqItems} />
            </div>
          </div>
        </div>
      </div>
      <Button onClick={showModal} className='sticky bottom-8 float-end m-4'>
        Save & Assign Inherent Risk
      </Button>
      {renderModal()}
    </div>
  )
}
